@media
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    /* Branding */
    .navbar {
	    .navbar-brand {
			background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo@2x.png') no-repeat;
			background-size: 160px 28px;
		}
	}

	.mobile-nav {
	  .navbar-brand {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray@2x.png") no-repeat;
		background-size: 160px 28px;
		&:hover {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white@2x.png") no-repeat;
			background-size: 160px 28px;
	}
		}
	}

	.footer {
		.newsletter {
		    .container {
		      .newsletter-icon {
		       	 background:url('https://cumulus-fs.s3.amazonaws.com/images/newsletter-signup@2x.png') no-repeat;
		       	 background-size:34px 23px;
	        	}
		    }
		  }
		.footer-branding {
		  .container {
		    .footer-logo {
			      background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-footer@2x.png') no-repeat;
			      background-size:105px 86px;
		    }
		    .social-links {
		        .facebook {
		          background:url('https://cumulus-fs.s3.amazonaws.com/images/social-facebook@2x.png') no-repeat;
		          background-size:14px 30px;
		        }
		        .twitter {
		          background:url('https://cumulus-fs.s3.amazonaws.com/images/social-twitter@2x.png') no-repeat;
		          background-size:30px 30px;
		        }
		        .mail {
		          background:url('https://cumulus-fs.s3.amazonaws.com/images/contact-mail@2x.png') no-repeat;
		          background-size:30px 30px;
		        }
		      }
		  }
		}
	}


	/* Banking Offerings */
	.checking {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-checking@2x.png");
		background-size:85px 85px;
	}
	.savings {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-savings@2x.png");
		background-size:85px 85px;
	}
	.credit-cards {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-credit-cards@2x.png");
		background-size:85px 85px;
	}
	.loans {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-loans@2x.png");
		background-size:85px 85px;
	}
	.investment {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/Wealth-retirement-planning-icon.png");
		background-size:85px 85px;
	}
	.retirement {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-retirement@2x.png");
		background-size:85px 85px;
	}
	.business-funding{
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-business-funding@2x.png");
		background-size:85px 85px;
	}
	.industry-solutions{
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-industry-solutions@2x.png");
		background-size:85px 85px;
	}
	.cash-management {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-cash-management@2x.png");
		background-size:85px 85px;
	}
	.growth-management {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-growth-management@2x.png");
		background-size:85px 85px;
	}
	.global-solutions {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-global-solutions@2x.png");
		background-size:85px 85px;
	}
	.research {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-research@2x.png");
		background-size:85px 85px;
	}

	.banking-categories {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond@2x.png");
		background-size:154px 64px;
	}

	.insurance {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/Corporate-insurance-overview-icon.png");
		background-size:85px 85px;
	}


	/* Forms */
	.form-intro {
		.form-cloud {
			background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-cloud@2x.png");
			background-size: 82px 81px;
		}
	}


}
