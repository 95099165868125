.form-template {
	background:$extra-light-gray;
}

.form-intro {
	background:$light-gray;
	height:125px;
	position:relative;
	&:after {
		background: linear-gradient(-45deg, $extra-light-gray 10px, transparent 0), linear-gradient(45deg, $extra-light-gray 10px, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 20px 20px;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 20px;
	}
	.form-cloud {
		width:82px;
		height:81px;
		background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-cloud.png') no-repeat;
		margin: 0 auto;
		position: relative;
		top: 75px;
		z-index: 1;
	}
}
.form-control {
	border: 1px solid $extra-light-gray;
	border-radius: 25px;
	height: 38px;
	padding: 0 16px;
	-webkit-appearance: none;
}
.form-control.contact {
	border-radius: 12px;
	margin-bottom: 20px;
	padding: 16px;
}
.form-content {
	background:$extra-light-gray;
	text-align:center;
	padding:50px 0 75px 0;
	h2 {
		margin-bottom:30px;
	}
	h4 {
		margin-bottom:15px;
	}
	.new-form-section {
		margin-top:25px;
	}
	p {
		margin-bottom:30px;
	}
	input, select {
		margin-bottom: 12px;
	}
	select {
		background:white url('https://cumulus-fs.s3.amazonaws.com/images/drop-down.png') no-repeat;
		background-position:right center;
		-moz-appearance: none;
  		-moz-user-select: none;
	}
}
.forgot {
	margin-top:10px;
}
.ty-white-wrapper {
	background: $white;
	padding: 30px;
	margin-top:25px;
	text-align: center;
}

.disclosure{
	width:100%;
	text-align: center;
	opacity:60%;
}



/* rob form additions */
/*form styles*/
#msform {
	width: 60%;
	margin: 50px auto;
	text-align: center;
	position: relative;
}
.moveOver {
	margin-left: 100px;
}
#msform fieldset {
	background: white;
	border: 0 none;
	border-radius: 3px;
	/*box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);*/
	padding: 20px 50px;

	box-sizing: border-box;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	/*stacking fieldsets above each other*/
	/* position: absolute;*/
}
/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
	display: none;

}
/*inputs*/
#msform input, #msform textarea, .form input {

	margin-bottom: 15px;

}
#msform input[type="text"], #msform input[type="password"], #msform select  {

	width: 50%;
	margin-left: auto;
	margin-right: auto;

}
/*buttons*/
/*
#msform .action-button {
	width: 100px;
	background: $green;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 1px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}
#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px $light-gray;
} */
/*headings*/

/*progressbar*/
#progressbar {
	/*margin-bottom: 50px;*/
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	/*CSS counters to number the steps*/
	counter-reset: step;
	-webkit-padding-start: 0px;
	-moz-padding-start: 0px;
	width: 60%;
}
#progressbar li {
	list-style-type: none;
	color: $dark-gray;
	font-size: 14px;
	width: 25%;
	float: left;
	position: relative;
}
#progressbar li:before {
	content: counter(step);
	counter-increment: step;
	width: 42px;
	line-height: 42px;
	display: block;
	font-size: 20px;
	color: white;
	background: $light-gray;
	border-radius: 42px;
	margin: 0 auto 5px auto;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,  #progressbar li.active:after{
	background: $green;
	color: white;
}

/* END rob form additions */