/* Mixins */
.category-menu .navbar-left, .navbar .navContainer, .footer .newsletter .container, .footer .footer-branding .container, .footer .footer-branding .container .social-links, .footer .footer-branding .container .text-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

/* General Site Branding */
/* ======================= COLORS ===================== */
:root {
  --primary-blue: #2a94d6; }

.lt-gray-bg {
  background-color: #efefef; }

.white-bg {
  background-color: #fff; }

.text-white {
  color: #ffffff; }

.text-blue {
  color: #2a94d6; }

.text-green {
  color: #39ab61; }

.dmCustomerBranding {
  color: #2a94d6; }

.fa-facebook {
  color: #3b5998; }

.fa-twitter-square {
  color: #55acee; }

.fa-linkedin {
  color: #007bb5; }

.fa-facebook,
.fa-twitter-square,
.fa-linkedin {
  font-size: 30px;
  display: inline-block;
  padding-right: 10px; }

/* ======================= MODAL ===================== */
/* ======================= BASE CLASS DEFINITION ===================== */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

/* ======================= JUMBROTRONS ===================== */
.jumbotron-content {
  background: rgba(102, 102, 102, 0.35);
  border: 6px solid #fff;
  text-align: center;
  padding: 25px;
  margin-top: 110px; }
  .jumbotron-content h1 {
    font-size: 35pt;
    padding: 0 15px 15px 15px; }
  .jumbotron-content h1.white {
    color: #fff; }
  .jumbotron-content p {
    font-size: 14pt; }

.hero img {
  margin: 0 auto -40px auto;
  display: block;
  max-width: 100%;
  height: auto; }

.hero.container {
  padding-left: 0px;
  padding-right: 0px; }

/* ======================= FONTS ===================== */
body {
  font-family: 'Raleway', sans-serif;
  color: #4f4f4f;
  line-height: 1.9rem; }

/* ======================= BUTTONS ===================== */
.btn {
  text-transform: uppercase;
  padding: 10px 35px;
  border-radius: 25px;
  border: none;
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.green-btn {
  color: #fff;
  background-color: #39ab61 !important; }
  .green-btn:hover, .green-btn:active {
    background-color: #55c97b;
    color: #fff; }

.blue-btn {
  color: #fff;
  background-color: #2a94d6; }
  .blue-btn:hover, .blue-btn:active {
    background-color: #47b4ea;
    color: #fff; }

.btn-xs {
  padding: 5px 15px;
  font-size: 11px; }

/* ======================= TYPOGRAPHY ===================== */
h1 {
  padding: 25px 0; }

h3 {
  line-height: 1.3;
  font-weight: 300; }

h4,
h5 {
  font-weight: 600; }

h5 {
  font-size: 16px; }

a {
  font-weight: 600;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  a:hover, a:active {
    text-decoration: none; }
  a.gray-link {
    color: #4f4f4f; }
    a.gray-link:hover, a.gray-link:active {
      color: #39ab61; }
  a.green-link {
    color: #39ab61; }
    a.green-link:hover, a.green-link:active {
      color: #55c97b; }

.bold-text {
  font-weight: 700; }

/* ====================== IMAGES ========================== */
.img-responsive {
  margin: 0 auto 0px auto; }

.pdp-img-responsive {
  margin: 0 auto 0px auto;
  width: 360px; }

/* ======================= STRUCTURE ===================== */
.advertisements {
  padding: 50px 0 50px 0; }
  .advertisements img {
    padding: 0 0 25px 0; }

.extraColPadding {
  padding: 0 20px 0 0; }

.box {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #efefef;
  padding: 25px; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.contact-advisor::before {
  background: #d8d8d8;
  content: ''; }

.nav-fill .tab-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  /*text-align: center;*/ }

/* ======================= TABLE ===================== */
.tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-bottom: 1px solid #eee; }

.tab-item {
  display: block;
  padding: 0.5rem 25px;
  text-align: left;
  color: #4f4f4f;
  opacity: 0.5; }

.tab-item.active {
  font-weight: bold;
  border-bottom: 3px solid #2a94d6;
  color: #4f4f4f;
  opacity: 1; }

.faux-thead {
  padding: 10px 25px;
  font-size: 11px; }

.faux-row .name {
  font-weight: bold; }

.faux-row .number {
  /*color: $primary-blue;*/ }

.faux-row .row {
  padding: 25px; }

.faux-row .input {
  border: #4f4f4f; }

.faux-row label {
  display: none;
  color: #4f4f4f;
  font-size: 11px; }

.faux-row .cta-mobile {
  float: right; }

.faux-row:nth-of-type(odd) {
  background: #f7f7f7; }

.cta-mobile {
  display: none; }

.mobile-only-hr {
  display: none;
  width: 100%; }

.faux-box {
  background: #f7f7f7;
  height: 180px;
  padding: 40px 10px 10px 10px; }

a .faux-box h4,
a .faux-box p {
  color: #4f4f4f; }

a .faux-box p {
  font-weight: 400; }

a .faux-box:hover {
  background: #e5f5fd; }

.img-icon {
  width: 40px;
  padding-bottom: 10px; }

.padded-row {
  padding-top: 20px; }

@media (max-width: 768px) {
  .faux-row .checkbox,
  .faux-row .cta {
    display: none; }
  .faux-row label {
    display: block; }
  .faux-row .col-1 {
    width: 100%; }
  .faux-row .col-1:nth-of-type(even) {
    width: 100%;
    padding-bottom: 10px; }
  .faux-row .col-4 {
    width: 25%;
    float: left;
    text-align: center; }
  .faux-row .number {
    padding: 10px 5px 0; }
  .faux-thead,
  .tabs {
    display: none; }
  .cta-mobile {
    display: block; }
  .mobile-only-hr {
    display: block; }
  .strategies .col-1 {
    width: 100%; } }

/* ======================= PADDING ===================== */
.p-15 {
  padding: 15px; }

.p-25 {
  padding: 25px; }

.p-50 {
  padding: 50px; }

.p-75 {
  padding: 75px; }

.p-100 {
  padding: 100px; }

.p-200 {
  padding: 200px; }

.p-top-15 {
  padding-top: 15px; }

.p-top-25 {
  padding-top: 25px; }

.p-top-50 {
  padding-top: 50px; }

.p-top-75 {
  padding-top: 75px; }

.p-top-100 {
  padding-top: 100px; }

.p-top-200 {
  padding-top: 200px; }

.p-right-15 {
  padding-right: 15px; }

.p-right-25 {
  padding-right: 25px; }

.p-right-50 {
  padding-right: 50px; }

.p-right-75 {
  padding-right: 75px; }

.p-right-100 {
  padding-right: 100px; }

.p-right-200 {
  padding-right: 200px; }

.p-left-15 {
  padding-left: 15px; }

.p-left-25 {
  padding-left: 25px; }

.p-left-50 {
  padding-left: 50px; }

.p-left-75 {
  padding-left: 75px; }

.p-left-100 {
  padding-left: 100px; }

.p-left-200 {
  padding-left: 200px; }

.p-bottom-15 {
  padding-bottom: 15px; }

.p-bottom-25 {
  padding-bottom: 25px; }

.p-bottom-50 {
  padding-bottom: 50px; }

.p-bottom-75 {
  padding-bottom: 75px; }

.p-bottom-100 {
  padding-bottom: 100px; }

.p-bottom-200 {
  padding-bottom: 200px; }

.p-vertical-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.p-vertical-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.p-vertical-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.p-vertical-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.p-vertical-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.p-vertical-200 {
  padding-top: 200px;
  padding-bottom: 200px; }

.p-horizontal-15 {
  padding-left: 15px;
  padding-right: 15px; }

.p-horizontal-25 {
  padding-left: 25px;
  padding-right: 25px; }

.p-horizontal-50 {
  padding-left: 50px;
  padding-right: 50px; }

.p-horizontal-75 {
  padding-left: 75px;
  padding-right: 75px; }

.p-horizontal-100 {
  padding-left: 100px;
  padding-right: 100px; }

.p-horizontal-200 {
  padding-left: 200px;
  padding-right: 200px; }

.m-vertical-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.m-vertical-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.m-vertical-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.m-vertical-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.m-vertical-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.m-vertical-200 {
  margin-top: 200px;
  margin-bottom: 200px; }

.m-horizontal-15 {
  margin-left: 15px;
  margin-right: 15px; }

.m-horizontal-25 {
  margin-left: 25px;
  margin-right: 25px; }

.m-horizontal-50 {
  margin-left: 50px;
  margin-right: 50px; }

.m-horizontal-75 {
  margin-left: 75px;
  margin-right: 75px; }

.m-horizontal-100 {
  margin-left: 100px;
  margin-right: 100px; }

.m-horizontal-200 {
  margin-left: 200px;
  margin-right: 200px; }

.m-bottom-50 {
  margin-bottom: 50px; }

/* ======================= BOOTSTRAP 5 UPDATES ===================== */
.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #efefef;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden; }
  .card img {
    width: 100%; }

.card-header {
  padding: 20px 15px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center; }
  .card-header .card-title {
    display: inline-block;
    font-size: 24px; }
  .card-header .card-action {
    display: inline-block;
    margin-left: auto;
    padding: 0 20px; }
  .card-header img {
    vertical-align: middle;
    width: 51px;
    margin-right: 20px; }

.card-body {
  flex: 1 1 auto;
  padding: 20px 15px;
  color: #4f4f4f; }

.modal-backdrop {
  background-color: #c5c5c5; }

.modal-backdrop.in {
  opacity: 0.8; }

.modal-content {
  background-clip: padding-box;
  background-color: #f7f7f7;
  border: 0 solid transparent;
  border-radius: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%; }

.modal-header {
  align-items: flex-start;
  border-bottom: 0 solid #e6e6e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-align: start;
  -webkit-box-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2rem 2rem; }
  .modal-header .close {
    color: #000;
    float: right;
    font-size: 3rem;
    font-weight: 100;
    line-height: 1;
    margin: -1rem -1rem -1rem auto;
    opacity: 0.5;
    padding: 1rem 1rem;
    text-shadow: 0 1px 0 #fff; }
  .modal-header .close:hover {
    opacity: 1; }
  .modal-header button.close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0; }

.modal-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 200px;
  padding: 1rem; }
  .modal-body .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    .modal-body .row .col-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 15px;
      padding-right: 0px;
      position: relative;
      width: 100%; }
      .modal-body .row .col-3 .flex-column {
        -webkit-box-direction: normal !important;
        -webkit-box-orient: vertical !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important; }
      .modal-body .row .col-3 .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0; }
        .modal-body .row .col-3 .nav .fad {
          font-family: 'Font Awesome 5 Duotone';
          font-weight: 900;
          position: relative; }
    .modal-body .row .col-9 {
      background-color: #fff;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 73%;
      flex: 0 0 73%;
      max-width: 73%;
      padding: 25px 25px 60px;
      position: relative;
      width: 100%; }
      .modal-body .row .col-9 .tab-pane h4 {
        margin-top: 0 !important; }
      .modal-body .row .col-9 p {
        font-size: 16px;
        margin: 10px 0; }

.nav-pills .nav-link.active {
  background-color: #2a94d6;
  color: #fff;
  text-decoration: none; }

.nav-pills .nav-link {
  border-radius: 0;
  color: #2a94d6; }

.nav-link {
  display: block;
  padding: 1rem 1rem; }

.align-items-center {
  align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.custom-control {
  min-height: 1.5rem; }

.custom-switch {
  padding-left: 2.25rem; }

.custom-control {
  display: block;
  min-height: 1.225rem;
  padding-left: 1.5rem;
  position: relative; }

label {
  font-weight: 100; }

.custom-control-input {
  opacity: 0;
  position: absolute; }

button,
input {
  overflow: visible; }

input,
button,
select,
optgroup,
textarea {
  margin: 0; }

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top; }

.custom-switch .custom-control-label::before {
  background: gray;
  border: none;
  pointer-events: all; }

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: #39ab61; }

.custom-switch .custom-control-label::before {
  border-radius: 1.5rem;
  height: 1.5rem;
  left: -3.25rem;
  pointer-events: all;
  width: 2.5rem; }

.custom-control-label::before {
  border: 2px solid #39ab61; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }

.custom-control-label::before {
  background-color: transparent;
  border: #adb5bd solid 1px;
  content: '';
  display: block;
  height: 1rem;
  left: -1.5rem;
  pointer-events: none;
  position: absolute;
  top: 0.1125rem;
  width: 2.5rem; }

.custom-switch .custom-control-input:checked ~ .custom-control-label .inactive {
  display: none; }

.custom-switch .custom-control-input:checked ~ .custom-control-label .active {
  display: inline; }

.custom-switch .custom-control-label .active {
  display: none; }

.custom-switch .custom-control-label .inactive {
  display: inline; }

.custom-switch .custom-control-label::after {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3); }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-2.25rem + 2px); }

.custom-switch .custom-control-label::after {
  background-color: #fff; }

.custom-switch .custom-control-label::after {
  background-color: #fff;
  border-radius: 0.5rem;
  height: 1.1rem;
  left: calc(-3.25rem + 2px);
  top: calc(0.1125rem + 2px);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  width: 1.1rem; }

.custom-control-label::after {
  background: no-repeat 50% / 50% 50%;
  content: '';
  display: block;
  height: 1rem;
  left: -1.5rem;
  position: absolute;
  top: 0.1125rem;
  width: 1rem; }

.modal-footer {
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 0 solid #e6e6e6;
  -webkit-box-align: center;
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem 2rem 2rem; }
  .modal-footer button:not(:disabled),
  .modal-footer [type='button']:not(:disabled),
  .modal-footer [type='reset']:not(:disabled),
  .modal-footer [type='submit']:not(:disabled) {
    cursor: pointer; }
  .modal-footer .btn {
    text-transform: uppercase; }
  .modal-footer .btn-primary {
    background-color: #39ab61;
    border-color: #39ab61;
    color: #fff; }

.privacy-consent-title {
  display: block; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center; }

#advisor-popup .modal-content {
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 360px;
  border: 1px solid #000000;
  margin: 10% auto; }
  #advisor-popup .modal-content .modal-body {
    background-color: rgba(0, 0, 0, 0.9); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d8d8;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.2); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 1; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

#advisor-popup .close {
  float: none;
  font-size: 11px;
  text-shadow: 0px 0px 0px black;
  color: #efefef;
  font-weight: 400; }

#advisor-popup .modal-content:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  width: 0;
  height: 0;
  border-top: solid 20px rgba(0, 0, 0, 0.9);
  border-left: solid 20px transparent;
  border-right: solid 20px transparent; }

/* ======================= SVG WEB CUSTOMIZATION ===================== */
.svg-hero-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle; }

.svg-viewBox {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0; }

.svg-hero-buttoncolor {
  fill: #00AC5B; }

.svg-hero-freedomcard-bottomcolor {
  fill: #00AC5B; }

.svg-hero-freedomcard-topcolor {
  fill: #028945; }

.svg-hero-travelcard-color {
  fill: #0C84CA; }

.svg-hero-mobileapp-headercolor {
  fill: #2A94D6; }

.svg-hero-mobileapp-circlecolor {
  fill: #00AC5B; }

.svg-is-image-primary-color {
  fill: #2A94D6; }

/** NEED TO ADD TO VIRAL'S SITE **/
.svg-hero-studentcard-dotcolor {
  fill: #D36126; }

.svg-hero-studentcard-gradientcolor {
  background-image: linear-gradient(to #D77400, #C0542E); }

.svg-hero-pluscard-gradientcolor {
  background-image: linear-gradient(to #2A94D6, #1AB070); }

/* Site Structure */
/* TOP BLUE NAVIGATION BAR */
.category-menu {
  background-color: #2a94d6;
  color: #fff;
  height: 50px; }
  .category-menu .navbar-left {
    text-transform: uppercase;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    justify-content: flex-start; }
  .category-menu .navbar-right {
    margin-right: 0;
    font-size: 12px; }
  .category-menu a {
    color: #fff; }
  .category-menu .nav > li.current {
    background-color: #47b4ea; }
  .category-menu .nav > li > a {
    -webkit-transition: background-color 0.2s ease-in-out;
    -moz-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    -o-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out; }
    .category-menu .nav > li > a:hover, .category-menu .nav > li > a:focus {
      text-decoration: none;
      background-color: #47b4ea;
      color: #fff; }

/* MAIN NAV WITH LOGO */
.navbar-inverse {
  background-color: rgba(255, 255, 255, 0.9); }

.dropdown:hover .dropdown-menu {
  display: block; }

.navbar {
  border: none;
  margin: 0;
  height: 65px; }
  .navbar .container {
    height: 65px; }
  .navbar .navContainer {
    min-height: 100%;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    width: 100%; }
  .navbar .navbar-brand {
    height: 28px;
    width: 160px;
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo.png") no-repeat;
    margin: -5px 30px 0 0; }
  .navbar .navbar-nav > li > a {
    color: #2a94d6;
    text-transform: uppercase;
    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out; }
    .navbar .navbar-nav > li > a:hover, .navbar .navbar-nav > li > a:focus {
      color: #47b4ea; }
    .navbar .navbar-nav > li > a.current {
      color: #47b4ea; }
  .navbar .login {
    margin-left: auto; }

/* MOBILE FLY OUT MENU HOLLA ! */
/* Navigation Button */
a.nav-expander {
  background: none repeat scroll 0 0 #2a94d6;
  color: #FFFFFF;
  display: block;
  font-size: 20px;
  font-weight: 400;
  height: 65px;
  margin: 0;
  padding: 1em 1em 2em 2em;
  position: absolute;
  right: 0;
  text-decoration: none;
  text-transform: uppercase;
  top: 0;
  transition: right 0.3s ease-in-out 0s;
  width: 80px;
  z-index: 12;
  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s; }

a.nav-expander:hover {
  cursor: pointer; }

a.nav-expander.fixed {
  position: fixed; }

.nav-expanded a.nav-expander.fixed {
  right: 15em; }

/*Off Canvas Menu Styling */
.mobile-nav {
  background: #2d2f33;
  display: block;
  height: 100%;
  overflow: auto;
  position: fixed;
  right: -20em;
  font-size: 15px;
  top: 0;
  width: 20em;
  z-index: 2000;
  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s; }
  .mobile-nav .navbar-brand {
    height: 28px;
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray.png") no-repeat;
    width: 160px;
    margin: 20px; }
    .mobile-nav .navbar-brand:hover {
      background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white.png") no-repeat; }

.nav-expanded .mobile-nav {
  right: 0; }

body.nav-expanded {
  margin-left: 0em;
  transition: right 0.4s ease-in-out 0s;
  -webkit-transition: right 0.4s ease-in-out 0s;
  -moz-transition: right 0.4s ease-in-out 0s;
  -o-transition: right 0.4s ease-in-out 0s; }

#nav-close {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding-right: 10px; }
  #nav-close .glyphicon {
    opacity: 1;
    margin: 0 0.5em 0 0; }
    #nav-close .glyphicon:hover {
      margin: 0 0.5em 0 0; }

/* Mobile Menu and animations on hover */
.main-menu {
  padding-top: 12px; }

.main-menu li {
  border-bottom: 1px solid #323949;
  margin-left: 20px;
  font-size: 18px;
  padding: 12px; }

.main-menu li a {
  color: #6F7D8C;
  text-decoration: none; }

.main-menu li a:hover {
  color: #fff;
  text-decoration: none; }

.main-menu li .sub-nav {
  border-bottom: 0px;
  padding: 4px;
  font-size: 14px;
  margin-left: 10px; }

.main-menu a .caret {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: top;
  border-top: 4px solid #4f5963;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  margin-top: 8px; }

.main-menu a:hover .caret {
  border-top-color: #4f5963; }

.main-menu li.open > a > .caret {
  border-top: none;
  border-bottom: 4px solid #4f5963;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.main-menu li.open > a:hover > .caret {
  border-bottom-color: #4f5963; }

.icon:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  content: '\f105'; }

.main-menu li > a > span.glyphicon {
  float: right;
  margin: 0.1em 1.7em -0.1em 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.main-menu li > a:hover > span.glyphicon {
  float: right;
  margin: 0.1em 0.8em -0.1em 0;
  opacity: 1; }

.dropdown-wide {
  width: 100%; }

.dropdown-menu a,
.dropdown-menu span {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: #333;
  white-space: nowrap; }

.dropdown-menu a.parent,
.dropdown-menu span.parent {
  font-weight: 600; }

/* Sticky footer styles -----
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%; }

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px; }

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px; }
  .footer .newsletter {
    background-color: #d8d8d8; }
    .footer .newsletter .container {
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      -moz-align-items: center;
      align-items: center;
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center;
      height: 60px; }
      .footer .newsletter .container .newsletter-icon {
        width: 34px;
        height: 23px;
        margin-right: 15px;
        background: url("https://cumulus-fs.s3.amazonaws.com/images/newsletter-signup.png") no-repeat; }
      .footer .newsletter .container p {
        margin: 0 15px 0 0; }
      .footer .newsletter .container .form-group {
        margin-bottom: 0px;
        margin-right: 10px; }
  .footer .footer-branding .container {
    min-height: 100%;
    width: 100%;
    background-color: #2a94d6;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    .footer .footer-branding .container .footer-logo {
      height: 86px;
      width: 105px;
      background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-footer.png") no-repeat;
      margin: 50px 0 30px 0; }
    .footer .footer-branding .container .social-links {
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
      .footer .footer-branding .container .social-links a {
        opacity: 0.7;
        padding: 0;
        -webkit-transition: opacity 0.2s ease-in-out;
        -moz-transition: opacity 0.2s ease-in-out;
        -ms-transition: opacity 0.2s ease-in-out;
        -o-transition: opacity 0.2s ease-in-out;
        transition: opacity 0.2s ease-in-out; }
        .footer .footer-branding .container .social-links a:hover, .footer .footer-branding .container .social-links a:focus {
          opacity: 1;
          background-color: transparent; }
      .footer .footer-branding .container .social-links li {
        padding: 0 10px; }
      .footer .footer-branding .container .social-links .facebook {
        background: url("https://cumulus-fs.s3.amazonaws.com/images/social-facebook.png") no-repeat;
        width: 14px;
        height: 30px; }
      .footer .footer-branding .container .social-links .twitter {
        background: url("https://cumulus-fs.s3.amazonaws.com/images/social-twitter.png") no-repeat;
        width: 30px;
        height: 30px; }
      .footer .footer-branding .container .social-links .mail {
        background: url("https://cumulus-fs.s3.amazonaws.com/images/contact-mail.png") no-repeat;
        width: 30px;
        height: 30px; }
    .footer .footer-branding .container .text-links {
      padding: 25px 0 25px 0;
      -webkit-box-direction: normal;
      -webkit-box-orient: horizontal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      -moz-justify-content: center;
      justify-content: center; }
      .footer .footer-branding .container .text-links a {
        color: #fff;
        border-radius: 25px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 5px;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }
        .footer .footer-branding .container .text-links a:hover, .footer .footer-branding .container .text-links a:focus {
          text-decoration: none;
          background-color: #47b4ea;
          color: #fff; }
  .footer .disclaimer {
    min-height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    width: 100%;
    background-color: #47b4ea;
    padding: 25px 20px;
    text-align: center; }
    .footer .disclaimer .row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      width: 100%; }
    .footer .disclaimer .demo-site {
      padding-bottom: 5px;
      color: #ffffff; }
      .footer .disclaimer .demo-site a {
        color: #ffffff; }
  .footer .list-inline {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none; }
    .footer .list-inline .list-inline-item:not(:last-child) {
      margin-right: 0.5rem; }
    .footer .list-inline .list-inline-item {
      display: inline-block;
      color: #fff;
      text-decoration: none;
      font-weight: 100; }
    .footer .list-inline .list-inline-item > a {
      color: #ffffff;
      text-decoration: none;
      font-weight: 100;
      font-size: 11px; }
      .footer .list-inline .list-inline-item > a:hover {
        color: #eeeeee; }

.consent-tracking {
  background-color: #000000;
  bottom: 20px;
  left: 20px;
  color: #fff;
  font-size: 1.15rem;
  position: fixed;
  width: 300px;
  z-index: 2000;
  padding: 1rem;
  display: flex !important;
  opacity: .8; }
  .consent-tracking .consent-tracking_close {
    color: #fff;
    margin-left: 3rem; }
  .consent-tracking .privacy-consent-title {
    font-size: 1.4rem;
    text-transform: uppercase; }
  .consent-tracking .btn {
    padding: 0px; }

#onetrust-pc-sdk {
  max-width: 960px !important;
  width: 100% !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc {
  background-color: #fff; }

.ot-sdk-container a,
.ot-sdk-container .ot-link-btn,
.ot-sdk-container .ot-always-active {
  color: #2a94d6 !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header {
  color: #4f4f4f !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header {
  color: #000 !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: #2a94d6 !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler,
div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler,
div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent #onetrust-accept-btn-handler {
  background-color: #2a94d6 !important;
  border-color: #2a94d6 !important; }

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler.optanon-ghost-button,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  color: #2a94d6 !important; }

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  border-color: #2a94d6 !important; }

.newsletter-message {
  margin: 0 auto;
  width: 100%;
  padding: 10px 0;
  color: #ffffff; }
  .newsletter-message.success {
    background-color: #70C28D; }
  .newsletter-message.error {
    background-color: #D8A0A0; }

#newsletterform {
  display: flex;
  margin-block-end: 0; }

.icon-cpra {
  width: 30px;
  height: 15px; }

@media (min-width: 768px) {
  .site-footer__menu .collapse {
    display: block;
    height: auto !important;
    visibility: visible; }
  .site-footer__menu-item {
    line-height: 1.25rem; } }

@media (max-width: 991.98px) {
  .site-footer__menu-header {
    cursor: pointer; }
  .site-footer__menu-header,
  .site-footer__menu-item {
    color: #4f4f4f;
    padding: 0.25rem 0; }
  .consent-tracking {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%; } }

@media (max-width: 767.98px) {
  .disclaimer .justify-content-between {
    justify-content: none; }
    .disclaimer .justify-content-between .demo-site {
      width: 100%; }
    .disclaimer .justify-content-between .disclaimer-spacing {
      display: none; }
    .disclaimer .justify-content-between .disclaimer-nav {
      width: 100%; } }

/* Pages */
/* This is the homepage*/
.homepage-corporate-jumbotron {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/banners/hero-01.jpg");
  height: 500px;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
  margin-top: -65px; }

.homepage-retail-jumbotron {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/banners/hero-02.jpg");
  height: 500px;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
  margin-top: -65px; }

.intro-content {
  background: #efefef;
  text-align: center;
  padding: 70px 0 75px 0;
  width: 100%;
  margin: 0; }
  .intro-content h3 {
    margin-bottom: 20px; }

.banking-categories {
  text-align: center;
  padding: 50px 0;
  background: url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond.png");
  background-size: 154px 64px; }
  .banking-categories p {
    min-height: 80px; }

.trending-topics {
  background: #efefef;
  padding: 50px 0 75px 0; }
  .trending-topics h2 {
    text-align: center;
    margin-bottom: 35px; }
  .trending-topics p {
    min-height: 65px; }

/* PI Content Recs */
.hero-inner {
  overflow: visible;
  padding: 15px;
  text-align: center; }

/* Offerings Backgrounds */
.offerings {
  width: 85px;
  height: 85px;
  margin: 0 auto 15px auto; }

.checking {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-checking.png"); }

.savings {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-savings.png"); }

.credit-cards {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-credit-cards.png"); }

.loans {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-loans.png"); }

.retirement {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-retirement.png"); }

.insurance {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-insurance.png"); }

.business-funding {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-business-funding.png"); }

.industry-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-industry-solutions.png"); }

.cash-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-cash-management.png"); }

.growth-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-growth-management.png"); }

.global-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-global-solutions.png"); }

.research {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-research.png"); }

/* Inside Pages Template
-------------------------------------------------- */
/* ======================= JUMBOTRONs ===================== */
.retail-banking-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/retail-banking-overview-hero.jpg);
  background-size: cover;
  background-position: center center;
  margin-bottom: 0px;
  margin-top: -65px; }

.retail-borrowing-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/retail-borrowing-overview-hero.jpg);
  background-size: cover;
  background-position: center center;
  margin-bottom: 0px;
  margin-top: -65px; }

.retail-investing-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/retail-investing-overview-hero.jpg);
  background-size: cover;
  background-position: top center;
  margin-bottom: 0px;
  margin-top: -65px; }

.corporate-banking-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/corporate-banking-overview-hero.jpg);
  background-size: cover;
  background-position: right center;
  margin-bottom: 0px;
  margin-top: -65px; }

.corporate-borrowing-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/corporate-borrowing-overview-hero.jpg);
  background-size: cover;
  background-position: right center;
  margin-bottom: 0px;
  margin-top: -65px; }

.featured-article-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/corporate-research-and-insights-hero.jpg);
  background-size: cover;
  background-position: 50% 60%;
  margin-bottom: 0px;
  margin-top: -65px; }

.retail-news-jumbotron {
  height: 500px;
  background-image: url(https://cumulus-fs.s3.amazonaws.com/images/retail-news-hero.jpg);
  background-size: cover;
  background-position: 50% 60%;
  margin-bottom: 0px;
  margin-top: -65px; }

/* ======================= STRUCTURE ===================== */
.inside-intro-content {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond.png");
  text-align: center;
  padding: 50px 0; }
  .inside-intro-content .offerings {
    width: 85px;
    height: 85px;
    margin: 40px auto 0px auto; }
  .inside-intro-content h1 {
    padding: 10px 0; }
  .inside-intro-content h3 {
    margin-top: 0px; }
  .inside-intro-content h4 {
    margin: 20px 0; }

.product-intro {
  margin: 50px auto 25px auto; }
  .product-intro h1 {
    margin-top: 0;
    padding: 10px 0 0 0; }
  .product-intro h3 {
    margin-top: 0px;
    margin-bottom: 20px; }
  .product-intro h4 {
    margin: 20px 0; }

.product-details li {
  padding-bottom: 10px; }

.sidebar {
  background-color: #efefef;
  padding: 15px 25px 25px 25px;
  border: 1px solid #d8d8d8;
  margin-top: 25px; }

.overview-products {
  text-align: center; }
  .overview-products img {
    max-width: 82px; }

.gray-background-content {
  background: #efefef;
  padding: 50px 0; }

.white-background-content {
  background: #fff;
  padding: 50px 0; }

.research-insights-article {
  padding: 75px 25px; }
  .research-insights-article .row {
    margin-bottom: 25px; }

.coverage {
  border: 1px solid #DBDBDB;
  padding: 10px 20px; }

.form-template {
  background: #efefef; }

.form-intro {
  background: #d8d8d8;
  height: 125px;
  position: relative; }
  .form-intro:after {
    background: linear-gradient(-45deg, #efefef 10px, transparent 0), linear-gradient(45deg, #efefef 10px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 20px 20px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px; }
  .form-intro .form-cloud {
    width: 82px;
    height: 81px;
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-cloud.png") no-repeat;
    margin: 0 auto;
    position: relative;
    top: 75px;
    z-index: 1; }

.form-control {
  border: 1px solid #efefef;
  border-radius: 25px;
  height: 38px;
  padding: 0 16px;
  -webkit-appearance: none; }

.form-control.contact {
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 16px; }

.form-content {
  background: #efefef;
  text-align: center;
  padding: 50px 0 75px 0; }
  .form-content h2 {
    margin-bottom: 30px; }
  .form-content h4 {
    margin-bottom: 15px; }
  .form-content .new-form-section {
    margin-top: 25px; }
  .form-content p {
    margin-bottom: 30px; }
  .form-content input, .form-content select {
    margin-bottom: 12px; }
  .form-content select {
    background: white url("https://cumulus-fs.s3.amazonaws.com/images/drop-down.png") no-repeat;
    background-position: right center;
    -moz-appearance: none;
    -moz-user-select: none; }

.forgot {
  margin-top: 10px; }

.ty-white-wrapper {
  background: #fff;
  padding: 30px;
  margin-top: 25px;
  text-align: center; }

.disclosure {
  width: 100%;
  text-align: center;
  opacity: 60%; }

/* rob form additions */
/*form styles*/
#msform {
  width: 60%;
  margin: 50px auto;
  text-align: center;
  position: relative; }

.moveOver {
  margin-left: 100px; }

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 3px;
  /*box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);*/
  padding: 20px 50px;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /*stacking fieldsets above each other*/
  /* position: absolute;*/ }

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none; }

/*inputs*/
#msform input, #msform textarea, .form input {
  margin-bottom: 15px; }

#msform input[type="text"], #msform input[type="password"], #msform select {
  width: 50%;
  margin-left: auto;
  margin-right: auto; }

/*buttons*/
/*
#msform .action-button {
	width: 100px;
	background: $green;
	font-weight: bold;
	color: white;
	border: 0 none;
	border-radius: 1px;
	cursor: pointer;
	padding: 10px 5px;
	margin: 10px 5px;
}
#msform .action-button:hover, #msform .action-button:focus {
	box-shadow: 0 0 0 2px white, 0 0 0 3px $light-gray;
} */
/*headings*/
/*progressbar*/
#progressbar {
  /*margin-bottom: 50px;*/
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  -webkit-padding-start: 0px;
  -moz-padding-start: 0px;
  width: 60%; }

#progressbar li {
  list-style-type: none;
  color: #4f4f4f;
  font-size: 14px;
  width: 25%;
  float: left;
  position: relative; }

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 42px;
  line-height: 42px;
  display: block;
  font-size: 20px;
  color: white;
  background: #d8d8d8;
  border-radius: 42px;
  margin: 0 auto 5px auto; }

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before, #progressbar li.active:after {
  background: #39ab61;
  color: white; }

/* END rob form additions */
.application-template {
  background: #efefef; }

.application-wrapper {
  margin: 75px 0 50px 0;
  padding: 0 30px;
  background: #fff; }

.application-content {
  padding: 30px; }
  .application-content h2, .application-content p.complete {
    padding: 15px 0;
    text-align: center; }

.gray-background-app {
  background: #efefef;
  padding: 18px;
  height: 70px; }

.white-background-app {
  background: #fff;
  padding: 18px;
  height: 70px; }

.app-btns {
  padding: 35px 18px; }

.control-label {
  text-align: right; }

.form-app {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .form-app label {
    margin-bottom: 0px; }

.company {
  background: #efefef; }

.company .body-content {
  padding-bottom: 75px; }

#map-canvas {
  width: 100%;
  height: 600px;
  margin-top: 25px; }

.privacy-form {
  margin: 10px 0; }

.privacy-button {
  margin: 20px 0; }

.privacy-form-title {
  margin-bottom: 20px; }

.accounts {
  padding-bottom: 36px; }
  .accounts .card.checking-acct .card-header {
    background-color: #DAF0FB;
    color: #2a94d6; }
    .accounts .card.checking-acct .card-header a {
      color: #2a94d6;
      padding: 0 20px; }
  .accounts .card.savings-acct .card-header {
    background-color: #E5F7EF;
    color: #39ab61; }
    .accounts .card.savings-acct .card-header img {
      width: 51px; }
    .accounts .card.savings-acct .card-header a {
      color: #39ab61; }
  .accounts .card .card-body {
    display: flex;
    padding: 20px; }
    .accounts .card .card-body h3 {
      margin-top: 8px; }
    .accounts .card .card-body .visa img, .accounts .card .card-body .mc img {
      width: 60px; }
  .accounts .banking, .accounts .credit {
    background-color: #3973b6;
    color: #fff; }
  .accounts .side-info {
    background-color: #d8d8d8; }
  .accounts table tr th {
    padding: 8px 8px 8px 20px;
    vertical-align: top;
    height: 40px; }
  .accounts table tr td.table-no-border {
    border-top: none;
    padding: 0px 15px 20px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .accounts table tr td.table-no-border-middle {
    border-top: none;
    padding: 0px 15px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    height: 30px; }
  .accounts table tr .small-account-text {
    font-size: 10px; }
  .accounts table tr td {
    padding: 8px 8px 8px 20px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #dddddd;
    height: 50px; }

.account-subnav {
  background: #efefef;
  position: relative; }
  .account-subnav:after {
    background: linear-gradient(-45deg, #fff 10px, transparent 0), linear-gradient(45deg, #fff 10px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 20px 20px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 20px; }
  .account-subnav .container {
    padding: 20px 0 30px; }
  .account-subnav .nav-item {
    background: #fff;
    border: 1px solid #d8d8d8;
    padding: 20px 35px;
    display: block;
    border-radius: 10px;
    color: #2a94d6;
    text-align: center;
    margin: 10px 18px;
    text-transform: uppercase; }
    .account-subnav .nav-item.active {
      color: #39ab61;
      font-weight: 800; }
    .account-subnav .nav-item:hover {
      color: #47b4ea; }

.rewards .activity {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 96px; }
  .rewards .activity thead > tr > th {
    vertical-align: middle;
    color: #8d8d8d;
    padding: 16px 30px;
    font-weight: 400; }
  .rewards .activity tbody > tr {
    border-bottom: 1px solid #efefef; }
    .rewards .activity tbody > tr:nth-child(odd) {
      background-color: #efefef; }
    .rewards .activity tbody > tr td {
      padding: 30px;
      font-size: 16px; }
      .rewards .activity tbody > tr td:last-child {
        color: #39ab61;
        font-weight: bold;
        width: 170px; }

.rewards #loyalty-cmp {
  box-shadow: 4px 0 12px 0 rgba(0, 0, 0, 0.1);
  margin: 0 0 96px; }
  .rewards #loyalty-cmp h1 {
    padding: 0;
    margin: 0; }
  .rewards #loyalty-cmp .gold h1 {
    color: #fab50f;
    font-weight: bold; }
  .rewards #loyalty-cmp .col-md-4 {
    padding-right: 0; }
  .rewards #loyalty-cmp .col-md-8 {
    padding: 0; }
  .rewards #loyalty-cmp .profile-points__regular {
    background: #EDF7FD;
    padding: 0 30px; }
    .rewards #loyalty-cmp .profile-points__regular h1 {
      color: #2a94d6; }
    .rewards #loyalty-cmp .profile-points__regular .titles {
      margin: 0; }

.slds-p-around_large {
  padding: 30px; }

.tier-progress__bar {
  width: 100%;
  padding: 10px 0 0; }
  .tier-progress__bar .tier-progress__bar .slds-progress-bar {
    background: #ffffff; }
  .tier-progress__bar .slds-progress-bar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 15px;
    background: var(--slds-g-color-neutral-base-80, var(--lwc-progressBarColorBackground, #c9c9c9));
    border: 0;
    position: relative;
    border-radius: 25px; }
  .tier-progress__bar .slds-progress-bar__value {
    display: inline-block;
    background: #2a94d6;
    border-radius: 25px;
    height: 100%; }
  .tier-progress__bar .dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 5px solid #2a94d6;
    border-radius: 25px;
    position: absolute;
    top: -2px;
    margin-left: -9px; }
  .tier-progress__bar .slds-assistive-text {
    display: none; }

.tier-progress__steps {
  margin: 0; }
  .tier-progress__steps .points {
    padding: 8px; }
    .tier-progress__steps .points:nth-last-of-type {
      text-align: right; }

/* Retina Content */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  /* Branding */
  .navbar .navbar-brand {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo@2x.png") no-repeat;
    background-size: 160px 28px; }
  .mobile-nav .navbar-brand {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray@2x.png") no-repeat;
    background-size: 160px 28px; }
    .mobile-nav .navbar-brand:hover {
      background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white@2x.png") no-repeat;
      background-size: 160px 28px; }
  .footer .newsletter .container .newsletter-icon {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/newsletter-signup@2x.png") no-repeat;
    background-size: 34px 23px; }
  .footer .footer-branding .container .footer-logo {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-footer@2x.png") no-repeat;
    background-size: 105px 86px; }
  .footer .footer-branding .container .social-links .facebook {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/social-facebook@2x.png") no-repeat;
    background-size: 14px 30px; }
  .footer .footer-branding .container .social-links .twitter {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/social-twitter@2x.png") no-repeat;
    background-size: 30px 30px; }
  .footer .footer-branding .container .social-links .mail {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/contact-mail@2x.png") no-repeat;
    background-size: 30px 30px; }
  /* Banking Offerings */
  .checking {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-checking@2x.png");
    background-size: 85px 85px; }
  .savings {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-savings@2x.png");
    background-size: 85px 85px; }
  .credit-cards {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-credit-cards@2x.png");
    background-size: 85px 85px; }
  .loans {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-loans@2x.png");
    background-size: 85px 85px; }
  .investment {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/Wealth-retirement-planning-icon.png");
    background-size: 85px 85px; }
  .retirement {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-retirement@2x.png");
    background-size: 85px 85px; }
  .business-funding {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-business-funding@2x.png");
    background-size: 85px 85px; }
  .industry-solutions {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-industry-solutions@2x.png");
    background-size: 85px 85px; }
  .cash-management {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-cash-management@2x.png");
    background-size: 85px 85px; }
  .growth-management {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-growth-management@2x.png");
    background-size: 85px 85px; }
  .global-solutions {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-global-solutions@2x.png");
    background-size: 85px 85px; }
  .research {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-research@2x.png");
    background-size: 85px 85px; }
  .banking-categories {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond@2x.png");
    background-size: 154px 64px; }
  .insurance {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/Corporate-insurance-overview-icon.png");
    background-size: 85px 85px; }
  /* Forms */
  .form-intro .form-cloud {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-cloud@2x.png");
    background-size: 82px 81px; } }

/* Media Queries */
/* ======================= MEDIA QUERIES ===================== */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-header .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 6px;
    font-size: 95%; }
  .navbar .navbar-brand {
    margin: -5px 20px 0 0; } }

@media (min-width: 768px) {
  div.col-sm-7.five-three {
    width: 60% !important; }
  div.col-sm-5.five-two {
    width: 40% !important; }
  #nav-expander {
    display: none; }
  .navbar-brand-mobile {
    display: none; }
  .navbar {
    border-radius: 0; }
  .dropdown-wide {
    width: 420px;
    /***this makes it float in the middle of the page***/
    /*transform: translate(-50%);
    left: 50%;*/ }
  #advisor-popup .modal-content {
    float: right;
    margin-right: 65px; }
  .text-align_right {
    text-align: right; }
  .gold {
    background-image: url("https://image.s4.sfmc-content.com/lib/fe9615737265077870/m/1/19f63a72-d2f0-4bea-9a04-5a49cd637139.png"), url("https://image.s4.sfmc-content.com/lib/fe9615737265077870/m/1/8f0dead1-f56b-47e8-9de1-b4910c4d4586.png");
    background-position: top left, top right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #ffffff; } }

@media (max-width: 1100px) {
  #msform {
    width: 80%; } }

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important; }
  .category-menu {
    display: none; }
  .navbar .navbar-brand {
    display: none; }
  .navbar .navbar-brand-mobile {
    height: 28px;
    width: 160px;
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white.png") no-repeat;
    margin: 0 0 0 20px;
    display: block; }
  .navbar .login {
    display: none; }
  .navbar-inverse {
    background-color: #2a94d6;
    opacity: 1; }
    .navbar-inverse .navbar-left {
      display: none; }
  .moveOver {
    margin-left: 0px; }
  #msform {
    width: 95%; }
  #msform input[type="text"], #msform input[type="password"], #msform select {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
  #progressbar {
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    -webkit-padding-start: 0px;
    width: 90%; }
  #progressbar li {
    list-style-type: none;
    color: #4f4f4f;
    font-size: 9px;
    width: 25%;
    float: left;
    position: relative; }
  .rewards .col-md-4, .rewards .col-md-8 {
    width: 100%; }
  .profile-points__regular {
    padding: 0px 0px 0px 15px !important; }
    .profile-points__regular .titles .col {
      width: 100%;
      flex-basis: 100% !important; }
  .gold {
    background-image: url("https://cumulus-fs.s3.amazonaws.com/images/CumulusWebsite/Loyalty/loyaltyBG_bottom_gold.png");
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #ffffff;
    padding-bottom: 50px; } }

@media (max-width: 767px), (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .navbar .navbar-brand-mobile {
    background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white@2x.png") no-repeat;
    background-size: 160px 28px; } }

@media (max-width: 980px) {
  .banking-categories, .overview-products {
    padding: 50px 0 0 0; }
    .banking-categories .col-sm-4, .banking-categories .col-sm-6, .banking-categories .col-md-4, .overview-products .col-sm-4, .overview-products .col-sm-6, .overview-products .col-md-4 {
      margin-bottom: 45px; }
    .banking-categories p, .overview-products p {
      min-height: 0; }
  .col-md-6, .col-md-4, .col-md-3 {
    width: 100%; }
  .trending-topics {
    padding: 50px 0 25px 0; }
    .trending-topics .col-md-4 {
      margin-bottom: 45px; }
    .trending-topics p {
      min-height: 0; }
  .inside-intro-content p {
    min-height: 0; }
  .inside-intro-content .col-md-4, .inside-intro-content .col-md-3, .inside-intro-content .col-md-6, .inside-intro-content .p-bottom-50, .overview-products .col-md-4, .overview-products .col-md-3, .overview-products .col-md-6, .overview-products .p-bottom-50 {
    margin: 0 auto 45px; }
  .inside-intro-content h4, .overview-products h4 {
    min-height: 0; }
  .jumbotron-content {
    margin-top: 95px; }
    .jumbotron-content h1 {
      font-size: 27pt; }
  .app-btns .col-md-4 {
    padding-top: 15px; }
  .footer .newsletter .container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: auto;
    padding: 25px; }
    .footer .newsletter .container .newsletter-icon {
      display: none; }
    .footer .newsletter .container p {
      margin-bottom: 20px;
      text-align: center; } }

@media (max-width: 620px) {
  /* PI Content Recs */
  .igo_boxbody {
    overflow: hidden;
    padding: 15px;
    text-align: center; }
    .igo_boxbody .igo_content {
      float: none;
      width: 100%; }
      .igo_boxbody .igo_content img {
        width: 75%; }
    .igo_boxbody .igo_content_content_title_label,
    .igo_boxbody .igo_content_summary_label {
      display: none; }
    .igo_boxbody .igo_content_content_title_value {
      display: block;
      font-size: 18px;
      font-weight: 600;
      padding: 15px 0 5px 0; } }

/*Font Awesome*/
/*!
 * Font Awesome Pro 5.10.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.fa-xs {
  font-size: .75em; }

.fa-sm {
  font-size: .875em; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit; }

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.fa-pull-left {
  float: left; }

.fa-pull-right {
  float: right; }

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em; }

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em; }

.fa-spin {
  animation: fa-spin 2s infinite linear; }

.fa-pulse {
  animation: fa-spin 1s infinite steps(8); }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg); }

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg); }

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg); }

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1); }

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1); }

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1); }

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none; }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff; }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e"; }

.fa-abacus:before {
  content: "\f640"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acorn:before {
  content: "\f6ae"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-ad:before {
  content: "\f641"; }

.fa-address-book:before {
  content: "\f2b9"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-adjust:before {
  content: "\f042"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adobe:before {
  content: "\f778"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-air-freshener:before {
  content: "\f5d0"; }

.fa-airbnb:before {
  content: "\f834"; }

.fa-alarm-clock:before {
  content: "\f34e"; }

.fa-alarm-exclamation:before {
  content: "\f843"; }

.fa-alarm-plus:before {
  content: "\f844"; }

.fa-alarm-snooze:before {
  content: "\f845"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alicorn:before {
  content: "\f6b0"; }

.fa-align-center:before {
  content: "\f037"; }

.fa-align-justify:before {
  content: "\f039"; }

.fa-align-left:before {
  content: "\f036"; }

.fa-align-right:before {
  content: "\f038"; }

.fa-align-slash:before {
  content: "\f846"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-allergies:before {
  content: "\f461"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-ambulance:before {
  content: "\f0f9"; }

.fa-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-analytics:before {
  content: "\f643"; }

.fa-anchor:before {
  content: "\f13d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angel:before {
  content: "\f779"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angry:before {
  content: "\f556"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-ankh:before {
  content: "\f644"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-alt:before {
  content: "\f5d1"; }

.fa-apple-crate:before {
  content: "\f6b1"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-archive:before {
  content: "\f187"; }

.fa-archway:before {
  content: "\f557"; }

.fa-arrow-alt-circle-down:before {
  content: "\f358"; }

.fa-arrow-alt-circle-left:before {
  content: "\f359"; }

.fa-arrow-alt-circle-right:before {
  content: "\f35a"; }

.fa-arrow-alt-circle-up:before {
  content: "\f35b"; }

.fa-arrow-alt-down:before {
  content: "\f354"; }

.fa-arrow-alt-from-bottom:before {
  content: "\f346"; }

.fa-arrow-alt-from-left:before {
  content: "\f347"; }

.fa-arrow-alt-from-right:before {
  content: "\f348"; }

.fa-arrow-alt-from-top:before {
  content: "\f349"; }

.fa-arrow-alt-left:before {
  content: "\f355"; }

.fa-arrow-alt-right:before {
  content: "\f356"; }

.fa-arrow-alt-square-down:before {
  content: "\f350"; }

.fa-arrow-alt-square-left:before {
  content: "\f351"; }

.fa-arrow-alt-square-right:before {
  content: "\f352"; }

.fa-arrow-alt-square-up:before {
  content: "\f353"; }

.fa-arrow-alt-to-bottom:before {
  content: "\f34a"; }

.fa-arrow-alt-to-left:before {
  content: "\f34b"; }

.fa-arrow-alt-to-right:before {
  content: "\f34c"; }

.fa-arrow-alt-to-top:before {
  content: "\f34d"; }

.fa-arrow-alt-up:before {
  content: "\f357"; }

.fa-arrow-circle-down:before {
  content: "\f0ab"; }

.fa-arrow-circle-left:before {
  content: "\f0a8"; }

.fa-arrow-circle-right:before {
  content: "\f0a9"; }

.fa-arrow-circle-up:before {
  content: "\f0aa"; }

.fa-arrow-down:before {
  content: "\f063"; }

.fa-arrow-from-bottom:before {
  content: "\f342"; }

.fa-arrow-from-left:before {
  content: "\f343"; }

.fa-arrow-from-right:before {
  content: "\f344"; }

.fa-arrow-from-top:before {
  content: "\f345"; }

.fa-arrow-left:before {
  content: "\f060"; }

.fa-arrow-right:before {
  content: "\f061"; }

.fa-arrow-square-down:before {
  content: "\f339"; }

.fa-arrow-square-left:before {
  content: "\f33a"; }

.fa-arrow-square-right:before {
  content: "\f33b"; }

.fa-arrow-square-up:before {
  content: "\f33c"; }

.fa-arrow-to-bottom:before {
  content: "\f33d"; }

.fa-arrow-to-left:before {
  content: "\f33e"; }

.fa-arrow-to-right:before {
  content: "\f340"; }

.fa-arrow-to-top:before {
  content: "\f341"; }

.fa-arrow-up:before {
  content: "\f062"; }

.fa-arrows:before {
  content: "\f047"; }

.fa-arrows-alt:before {
  content: "\f0b2"; }

.fa-arrows-alt-h:before {
  content: "\f337"; }

.fa-arrows-alt-v:before {
  content: "\f338"; }

.fa-arrows-h:before {
  content: "\f07e"; }

.fa-arrows-v:before {
  content: "\f07d"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-assistive-listening-systems:before {
  content: "\f2a2"; }

.fa-asterisk:before {
  content: "\f069"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-at:before {
  content: "\f1fa"; }

.fa-atlas:before {
  content: "\f558"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-atom:before {
  content: "\f5d2"; }

.fa-atom-alt:before {
  content: "\f5d3"; }

.fa-audible:before {
  content: "\f373"; }

.fa-audio-description:before {
  content: "\f29e"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-award:before {
  content: "\f559"; }

.fa-aws:before {
  content: "\f375"; }

.fa-axe:before {
  content: "\f6b2"; }

.fa-axe-battle:before {
  content: "\f6b3"; }

.fa-baby:before {
  content: "\f77c"; }

.fa-baby-carriage:before {
  content: "\f77d"; }

.fa-backpack:before {
  content: "\f5d4"; }

.fa-backspace:before {
  content: "\f55a"; }

.fa-backward:before {
  content: "\f04a"; }

.fa-bacon:before {
  content: "\f7e5"; }

.fa-badge:before {
  content: "\f335"; }

.fa-badge-check:before {
  content: "\f336"; }

.fa-badge-dollar:before {
  content: "\f645"; }

.fa-badge-percent:before {
  content: "\f646"; }

.fa-badger-honey:before {
  content: "\f6b4"; }

.fa-bags-shopping:before {
  content: "\f847"; }

.fa-balance-scale:before {
  content: "\f24e"; }

.fa-balance-scale-left:before {
  content: "\f515"; }

.fa-balance-scale-right:before {
  content: "\f516"; }

.fa-ball-pile:before {
  content: "\f77e"; }

.fa-ballot:before {
  content: "\f732"; }

.fa-ballot-check:before {
  content: "\f733"; }

.fa-ban:before {
  content: "\f05e"; }

.fa-band-aid:before {
  content: "\f462"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-barcode:before {
  content: "\f02a"; }

.fa-barcode-alt:before {
  content: "\f463"; }

.fa-barcode-read:before {
  content: "\f464"; }

.fa-barcode-scan:before {
  content: "\f465"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-baseball:before {
  content: "\f432"; }

.fa-baseball-ball:before {
  content: "\f433"; }

.fa-basketball-ball:before {
  content: "\f434"; }

.fa-basketball-hoop:before {
  content: "\f435"; }

.fa-bat:before {
  content: "\f6b5"; }

.fa-bath:before {
  content: "\f2cd"; }

.fa-battery-bolt:before {
  content: "\f376"; }

.fa-battery-empty:before {
  content: "\f244"; }

.fa-battery-full:before {
  content: "\f240"; }

.fa-battery-half:before {
  content: "\f242"; }

.fa-battery-quarter:before {
  content: "\f243"; }

.fa-battery-slash:before {
  content: "\f377"; }

.fa-battery-three-quarters:before {
  content: "\f241"; }

.fa-battle-net:before {
  content: "\f835"; }

.fa-bed:before {
  content: "\f236"; }

.fa-beer:before {
  content: "\f0fc"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bell:before {
  content: "\f0f3"; }

.fa-bell-exclamation:before {
  content: "\f848"; }

.fa-bell-plus:before {
  content: "\f849"; }

.fa-bell-school:before {
  content: "\f5d5"; }

.fa-bell-school-slash:before {
  content: "\f5d6"; }

.fa-bell-slash:before {
  content: "\f1f6"; }

.fa-bells:before {
  content: "\f77f"; }

.fa-bezier-curve:before {
  content: "\f55b"; }

.fa-bible:before {
  content: "\f647"; }

.fa-bicycle:before {
  content: "\f206"; }

.fa-biking:before {
  content: "\f84a"; }

.fa-biking-mountain:before {
  content: "\f84b"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-binoculars:before {
  content: "\f1e5"; }

.fa-biohazard:before {
  content: "\f780"; }

.fa-birthday-cake:before {
  content: "\f1fd"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blanket:before {
  content: "\f498"; }

.fa-blender:before {
  content: "\f517"; }

.fa-blender-phone:before {
  content: "\f6b6"; }

.fa-blind:before {
  content: "\f29d"; }

.fa-blog:before {
  content: "\f781"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bold:before {
  content: "\f032"; }

.fa-bolt:before {
  content: "\f0e7"; }

.fa-bomb:before {
  content: "\f1e2"; }

.fa-bone:before {
  content: "\f5d7"; }

.fa-bone-break:before {
  content: "\f5d8"; }

.fa-bong:before {
  content: "\f55c"; }

.fa-book:before {
  content: "\f02d"; }

.fa-book-alt:before {
  content: "\f5d9"; }

.fa-book-dead:before {
  content: "\f6b7"; }

.fa-book-heart:before {
  content: "\f499"; }

.fa-book-medical:before {
  content: "\f7e6"; }

.fa-book-open:before {
  content: "\f518"; }

.fa-book-reader:before {
  content: "\f5da"; }

.fa-book-spells:before {
  content: "\f6b8"; }

.fa-book-user:before {
  content: "\f7e7"; }

.fa-bookmark:before {
  content: "\f02e"; }

.fa-books:before {
  content: "\f5db"; }

.fa-books-medical:before {
  content: "\f7e8"; }

.fa-boot:before {
  content: "\f782"; }

.fa-booth-curtain:before {
  content: "\f734"; }

.fa-bootstrap:before {
  content: "\f836"; }

.fa-border-all:before {
  content: "\f84c"; }

.fa-border-bottom:before {
  content: "\f84d"; }

.fa-border-center-h:before {
  content: "\f89c"; }

.fa-border-center-v:before {
  content: "\f89d"; }

.fa-border-inner:before {
  content: "\f84e"; }

.fa-border-left:before {
  content: "\f84f"; }

.fa-border-none:before {
  content: "\f850"; }

.fa-border-outer:before {
  content: "\f851"; }

.fa-border-right:before {
  content: "\f852"; }

.fa-border-style:before {
  content: "\f853"; }

.fa-border-style-alt:before {
  content: "\f854"; }

.fa-border-top:before {
  content: "\f855"; }

.fa-bow-arrow:before {
  content: "\f6b9"; }

.fa-bowling-ball:before {
  content: "\f436"; }

.fa-bowling-pins:before {
  content: "\f437"; }

.fa-box:before {
  content: "\f466"; }

.fa-box-alt:before {
  content: "\f49a"; }

.fa-box-ballot:before {
  content: "\f735"; }

.fa-box-check:before {
  content: "\f467"; }

.fa-box-fragile:before {
  content: "\f49b"; }

.fa-box-full:before {
  content: "\f49c"; }

.fa-box-heart:before {
  content: "\f49d"; }

.fa-box-open:before {
  content: "\f49e"; }

.fa-box-up:before {
  content: "\f49f"; }

.fa-box-usd:before {
  content: "\f4a0"; }

.fa-boxes:before {
  content: "\f468"; }

.fa-boxes-alt:before {
  content: "\f4a1"; }

.fa-boxing-glove:before {
  content: "\f438"; }

.fa-brackets:before {
  content: "\f7e9"; }

.fa-brackets-curly:before {
  content: "\f7ea"; }

.fa-braille:before {
  content: "\f2a1"; }

.fa-brain:before {
  content: "\f5dc"; }

.fa-bread-loaf:before {
  content: "\f7eb"; }

.fa-bread-slice:before {
  content: "\f7ec"; }

.fa-briefcase:before {
  content: "\f0b1"; }

.fa-briefcase-medical:before {
  content: "\f469"; }

.fa-bring-forward:before {
  content: "\f856"; }

.fa-bring-front:before {
  content: "\f857"; }

.fa-broadcast-tower:before {
  content: "\f519"; }

.fa-broom:before {
  content: "\f51a"; }

.fa-browser:before {
  content: "\f37e"; }

.fa-brush:before {
  content: "\f55d"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-buffer:before {
  content: "\f837"; }

.fa-bug:before {
  content: "\f188"; }

.fa-building:before {
  content: "\f1ad"; }

.fa-bullhorn:before {
  content: "\f0a1"; }

.fa-bullseye:before {
  content: "\f140"; }

.fa-bullseye-arrow:before {
  content: "\f648"; }

.fa-bullseye-pointer:before {
  content: "\f649"; }

.fa-burger-soda:before {
  content: "\f858"; }

.fa-burn:before {
  content: "\f46a"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-burrito:before {
  content: "\f7ed"; }

.fa-bus:before {
  content: "\f207"; }

.fa-bus-alt:before {
  content: "\f55e"; }

.fa-bus-school:before {
  content: "\f5dd"; }

.fa-business-time:before {
  content: "\f64a"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-cabinet-filing:before {
  content: "\f64b"; }

.fa-calculator:before {
  content: "\f1ec"; }

.fa-calculator-alt:before {
  content: "\f64c"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-calendar-alt:before {
  content: "\f073"; }

.fa-calendar-check:before {
  content: "\f274"; }

.fa-calendar-day:before {
  content: "\f783"; }

.fa-calendar-edit:before {
  content: "\f333"; }

.fa-calendar-exclamation:before {
  content: "\f334"; }

.fa-calendar-minus:before {
  content: "\f272"; }

.fa-calendar-plus:before {
  content: "\f271"; }

.fa-calendar-star:before {
  content: "\f736"; }

.fa-calendar-times:before {
  content: "\f273"; }

.fa-calendar-week:before {
  content: "\f784"; }

.fa-camera:before {
  content: "\f030"; }

.fa-camera-alt:before {
  content: "\f332"; }

.fa-camera-retro:before {
  content: "\f083"; }

.fa-campfire:before {
  content: "\f6ba"; }

.fa-campground:before {
  content: "\f6bb"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-candle-holder:before {
  content: "\f6bc"; }

.fa-candy-cane:before {
  content: "\f786"; }

.fa-candy-corn:before {
  content: "\f6bd"; }

.fa-cannabis:before {
  content: "\f55f"; }

.fa-capsules:before {
  content: "\f46b"; }

.fa-car:before {
  content: "\f1b9"; }

.fa-car-alt:before {
  content: "\f5de"; }

.fa-car-battery:before {
  content: "\f5df"; }

.fa-car-building:before {
  content: "\f859"; }

.fa-car-bump:before {
  content: "\f5e0"; }

.fa-car-bus:before {
  content: "\f85a"; }

.fa-car-crash:before {
  content: "\f5e1"; }

.fa-car-garage:before {
  content: "\f5e2"; }

.fa-car-mechanic:before {
  content: "\f5e3"; }

.fa-car-side:before {
  content: "\f5e4"; }

.fa-car-tilt:before {
  content: "\f5e5"; }

.fa-car-wash:before {
  content: "\f5e6"; }

.fa-caret-circle-down:before {
  content: "\f32d"; }

.fa-caret-circle-left:before {
  content: "\f32e"; }

.fa-caret-circle-right:before {
  content: "\f330"; }

.fa-caret-circle-up:before {
  content: "\f331"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-square-down:before {
  content: "\f150"; }

.fa-caret-square-left:before {
  content: "\f191"; }

.fa-caret-square-right:before {
  content: "\f152"; }

.fa-caret-square-up:before {
  content: "\f151"; }

.fa-caret-up:before {
  content: "\f0d8"; }

.fa-carrot:before {
  content: "\f787"; }

.fa-cars:before {
  content: "\f85b"; }

.fa-cart-arrow-down:before {
  content: "\f218"; }

.fa-cart-plus:before {
  content: "\f217"; }

.fa-cash-register:before {
  content: "\f788"; }

.fa-cat:before {
  content: "\f6be"; }

.fa-cauldron:before {
  content: "\f6bf"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-certificate:before {
  content: "\f0a3"; }

.fa-chair:before {
  content: "\f6c0"; }

.fa-chair-office:before {
  content: "\f6c1"; }

.fa-chalkboard:before {
  content: "\f51b"; }

.fa-chalkboard-teacher:before {
  content: "\f51c"; }

.fa-charging-station:before {
  content: "\f5e7"; }

.fa-chart-area:before {
  content: "\f1fe"; }

.fa-chart-bar:before {
  content: "\f080"; }

.fa-chart-line:before {
  content: "\f201"; }

.fa-chart-line-down:before {
  content: "\f64d"; }

.fa-chart-network:before {
  content: "\f78a"; }

.fa-chart-pie:before {
  content: "\f200"; }

.fa-chart-pie-alt:before {
  content: "\f64e"; }

.fa-chart-scatter:before {
  content: "\f7ee"; }

.fa-check:before {
  content: "\f00c"; }

.fa-check-circle:before {
  content: "\f058"; }

.fa-check-double:before {
  content: "\f560"; }

.fa-check-square:before {
  content: "\f14a"; }

.fa-cheese:before {
  content: "\f7ef"; }

.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fa-cheeseburger:before {
  content: "\f7f1"; }

.fa-chess:before {
  content: "\f439"; }

.fa-chess-bishop:before {
  content: "\f43a"; }

.fa-chess-bishop-alt:before {
  content: "\f43b"; }

.fa-chess-board:before {
  content: "\f43c"; }

.fa-chess-clock:before {
  content: "\f43d"; }

.fa-chess-clock-alt:before {
  content: "\f43e"; }

.fa-chess-king:before {
  content: "\f43f"; }

.fa-chess-king-alt:before {
  content: "\f440"; }

.fa-chess-knight:before {
  content: "\f441"; }

.fa-chess-knight-alt:before {
  content: "\f442"; }

.fa-chess-pawn:before {
  content: "\f443"; }

.fa-chess-pawn-alt:before {
  content: "\f444"; }

.fa-chess-queen:before {
  content: "\f445"; }

.fa-chess-queen-alt:before {
  content: "\f446"; }

.fa-chess-rook:before {
  content: "\f447"; }

.fa-chess-rook-alt:before {
  content: "\f448"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-double-down:before {
  content: "\f322"; }

.fa-chevron-double-left:before {
  content: "\f323"; }

.fa-chevron-double-right:before {
  content: "\f324"; }

.fa-chevron-double-up:before {
  content: "\f325"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-square-down:before {
  content: "\f329"; }

.fa-chevron-square-left:before {
  content: "\f32a"; }

.fa-chevron-square-right:before {
  content: "\f32b"; }

.fa-chevron-square-up:before {
  content: "\f32c"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-child:before {
  content: "\f1ae"; }

.fa-chimney:before {
  content: "\f78b"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-chromecast:before {
  content: "\f838"; }

.fa-church:before {
  content: "\f51d"; }

.fa-circle:before {
  content: "\f111"; }

.fa-circle-notch:before {
  content: "\f1ce"; }

.fa-city:before {
  content: "\f64f"; }

.fa-claw-marks:before {
  content: "\f6c2"; }

.fa-clinic-medical:before {
  content: "\f7f2"; }

.fa-clipboard:before {
  content: "\f328"; }

.fa-clipboard-check:before {
  content: "\f46c"; }

.fa-clipboard-list:before {
  content: "\f46d"; }

.fa-clipboard-list-check:before {
  content: "\f737"; }

.fa-clipboard-prescription:before {
  content: "\f5e8"; }

.fa-clipboard-user:before {
  content: "\f7f3"; }

.fa-clock:before {
  content: "\f017"; }

.fa-clone:before {
  content: "\f24d"; }

.fa-closed-captioning:before {
  content: "\f20a"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-cloud-download:before {
  content: "\f0ed"; }

.fa-cloud-download-alt:before {
  content: "\f381"; }

.fa-cloud-drizzle:before {
  content: "\f738"; }

.fa-cloud-hail:before {
  content: "\f739"; }

.fa-cloud-hail-mixed:before {
  content: "\f73a"; }

.fa-cloud-meatball:before {
  content: "\f73b"; }

.fa-cloud-moon:before {
  content: "\f6c3"; }

.fa-cloud-moon-rain:before {
  content: "\f73c"; }

.fa-cloud-rain:before {
  content: "\f73d"; }

.fa-cloud-rainbow:before {
  content: "\f73e"; }

.fa-cloud-showers:before {
  content: "\f73f"; }

.fa-cloud-showers-heavy:before {
  content: "\f740"; }

.fa-cloud-sleet:before {
  content: "\f741"; }

.fa-cloud-snow:before {
  content: "\f742"; }

.fa-cloud-sun:before {
  content: "\f6c4"; }

.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fa-cloud-upload:before {
  content: "\f0ee"; }

.fa-cloud-upload-alt:before {
  content: "\f382"; }

.fa-clouds:before {
  content: "\f744"; }

.fa-clouds-moon:before {
  content: "\f745"; }

.fa-clouds-sun:before {
  content: "\f746"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-club:before {
  content: "\f327"; }

.fa-cocktail:before {
  content: "\f561"; }

.fa-code:before {
  content: "\f121"; }

.fa-code-branch:before {
  content: "\f126"; }

.fa-code-commit:before {
  content: "\f386"; }

.fa-code-merge:before {
  content: "\f387"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-coffee:before {
  content: "\f0f4"; }

.fa-coffee-togo:before {
  content: "\f6c5"; }

.fa-coffin:before {
  content: "\f6c6"; }

.fa-cog:before {
  content: "\f013"; }

.fa-cogs:before {
  content: "\f085"; }

.fa-coin:before {
  content: "\f85c"; }

.fa-coins:before {
  content: "\f51e"; }

.fa-columns:before {
  content: "\f0db"; }

.fa-comment:before {
  content: "\f075"; }

.fa-comment-alt:before {
  content: "\f27a"; }

.fa-comment-alt-check:before {
  content: "\f4a2"; }

.fa-comment-alt-dollar:before {
  content: "\f650"; }

.fa-comment-alt-dots:before {
  content: "\f4a3"; }

.fa-comment-alt-edit:before {
  content: "\f4a4"; }

.fa-comment-alt-exclamation:before {
  content: "\f4a5"; }

.fa-comment-alt-lines:before {
  content: "\f4a6"; }

.fa-comment-alt-medical:before {
  content: "\f7f4"; }

.fa-comment-alt-minus:before {
  content: "\f4a7"; }

.fa-comment-alt-plus:before {
  content: "\f4a8"; }

.fa-comment-alt-slash:before {
  content: "\f4a9"; }

.fa-comment-alt-smile:before {
  content: "\f4aa"; }

.fa-comment-alt-times:before {
  content: "\f4ab"; }

.fa-comment-check:before {
  content: "\f4ac"; }

.fa-comment-dollar:before {
  content: "\f651"; }

.fa-comment-dots:before {
  content: "\f4ad"; }

.fa-comment-edit:before {
  content: "\f4ae"; }

.fa-comment-exclamation:before {
  content: "\f4af"; }

.fa-comment-lines:before {
  content: "\f4b0"; }

.fa-comment-medical:before {
  content: "\f7f5"; }

.fa-comment-minus:before {
  content: "\f4b1"; }

.fa-comment-plus:before {
  content: "\f4b2"; }

.fa-comment-slash:before {
  content: "\f4b3"; }

.fa-comment-smile:before {
  content: "\f4b4"; }

.fa-comment-times:before {
  content: "\f4b5"; }

.fa-comments:before {
  content: "\f086"; }

.fa-comments-alt:before {
  content: "\f4b6"; }

.fa-comments-alt-dollar:before {
  content: "\f652"; }

.fa-comments-dollar:before {
  content: "\f653"; }

.fa-compact-disc:before {
  content: "\f51f"; }

.fa-compass:before {
  content: "\f14e"; }

.fa-compass-slash:before {
  content: "\f5e9"; }

.fa-compress:before {
  content: "\f066"; }

.fa-compress-alt:before {
  content: "\f422"; }

.fa-compress-arrows-alt:before {
  content: "\f78c"; }

.fa-compress-wide:before {
  content: "\f326"; }

.fa-concierge-bell:before {
  content: "\f562"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-construction:before {
  content: "\f85d"; }

.fa-container-storage:before {
  content: "\f4b7"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-conveyor-belt:before {
  content: "\f46e"; }

.fa-conveyor-belt-alt:before {
  content: "\f46f"; }

.fa-cookie:before {
  content: "\f563"; }

.fa-cookie-bite:before {
  content: "\f564"; }

.fa-copy:before {
  content: "\f0c5"; }

.fa-copyright:before {
  content: "\f1f9"; }

.fa-corn:before {
  content: "\f6c7"; }

.fa-cotton-bureau:before {
  content: "\f89e"; }

.fa-couch:before {
  content: "\f4b8"; }

.fa-cow:before {
  content: "\f6c8"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-credit-card-blank:before {
  content: "\f389"; }

.fa-credit-card-front:before {
  content: "\f38a"; }

.fa-cricket:before {
  content: "\f449"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-croissant:before {
  content: "\f7f6"; }

.fa-crop:before {
  content: "\f125"; }

.fa-crop-alt:before {
  content: "\f565"; }

.fa-cross:before {
  content: "\f654"; }

.fa-crosshairs:before {
  content: "\f05b"; }

.fa-crow:before {
  content: "\f520"; }

.fa-crown:before {
  content: "\f521"; }

.fa-crutch:before {
  content: "\f7f7"; }

.fa-crutches:before {
  content: "\f7f8"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cube:before {
  content: "\f1b2"; }

.fa-cubes:before {
  content: "\f1b3"; }

.fa-curling:before {
  content: "\f44a"; }

.fa-cut:before {
  content: "\f0c4"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dagger:before {
  content: "\f6cb"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-database:before {
  content: "\f1c0"; }

.fa-deaf:before {
  content: "\f2a4"; }

.fa-debug:before {
  content: "\f7f9"; }

.fa-deer:before {
  content: "\f78e"; }

.fa-deer-rudolph:before {
  content: "\f78f"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-democrat:before {
  content: "\f747"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-desktop:before {
  content: "\f108"; }

.fa-desktop-alt:before {
  content: "\f390"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dewpoint:before {
  content: "\f748"; }

.fa-dharmachakra:before {
  content: "\f655"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diagnoses:before {
  content: "\f470"; }

.fa-diamond:before {
  content: "\f219"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-dice:before {
  content: "\f522"; }

.fa-dice-d10:before {
  content: "\f6cd"; }

.fa-dice-d12:before {
  content: "\f6ce"; }

.fa-dice-d20:before {
  content: "\f6cf"; }

.fa-dice-d4:before {
  content: "\f6d0"; }

.fa-dice-d6:before {
  content: "\f6d1"; }

.fa-dice-d8:before {
  content: "\f6d2"; }

.fa-dice-five:before {
  content: "\f523"; }

.fa-dice-four:before {
  content: "\f524"; }

.fa-dice-one:before {
  content: "\f525"; }

.fa-dice-six:before {
  content: "\f526"; }

.fa-dice-three:before {
  content: "\f527"; }

.fa-dice-two:before {
  content: "\f528"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digging:before {
  content: "\f85e"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-digital-tachograph:before {
  content: "\f566"; }

.fa-diploma:before {
  content: "\f5ea"; }

.fa-directions:before {
  content: "\f5eb"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-disease:before {
  content: "\f7fa"; }

.fa-divide:before {
  content: "\f529"; }

.fa-dizzy:before {
  content: "\f567"; }

.fa-dna:before {
  content: "\f471"; }

.fa-do-not-enter:before {
  content: "\f5ec"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-dog:before {
  content: "\f6d3"; }

.fa-dog-leashed:before {
  content: "\f6d4"; }

.fa-dollar-sign:before {
  content: "\f155"; }

.fa-dolly:before {
  content: "\f472"; }

.fa-dolly-empty:before {
  content: "\f473"; }

.fa-dolly-flatbed:before {
  content: "\f474"; }

.fa-dolly-flatbed-alt:before {
  content: "\f475"; }

.fa-dolly-flatbed-empty:before {
  content: "\f476"; }

.fa-donate:before {
  content: "\f4b9"; }

.fa-door-closed:before {
  content: "\f52a"; }

.fa-door-open:before {
  content: "\f52b"; }

.fa-dot-circle:before {
  content: "\f192"; }

.fa-dove:before {
  content: "\f4ba"; }

.fa-download:before {
  content: "\f019"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-drafting-compass:before {
  content: "\f568"; }

.fa-dragon:before {
  content: "\f6d5"; }

.fa-draw-circle:before {
  content: "\f5ed"; }

.fa-draw-polygon:before {
  content: "\f5ee"; }

.fa-draw-square:before {
  content: "\f5ef"; }

.fa-dreidel:before {
  content: "\f792"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-drone:before {
  content: "\f85f"; }

.fa-drone-alt:before {
  content: "\f860"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drum:before {
  content: "\f569"; }

.fa-drum-steelpan:before {
  content: "\f56a"; }

.fa-drumstick:before {
  content: "\f6d6"; }

.fa-drumstick-bite:before {
  content: "\f6d7"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dryer:before {
  content: "\f861"; }

.fa-dryer-alt:before {
  content: "\f862"; }

.fa-duck:before {
  content: "\f6d8"; }

.fa-dumbbell:before {
  content: "\f44b"; }

.fa-dumpster:before {
  content: "\f793"; }

.fa-dumpster-fire:before {
  content: "\f794"; }

.fa-dungeon:before {
  content: "\f6d9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-ear:before {
  content: "\f5f0"; }

.fa-ear-muffs:before {
  content: "\f795"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-eclipse:before {
  content: "\f749"; }

.fa-eclipse-alt:before {
  content: "\f74a"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edit:before {
  content: "\f044"; }

.fa-egg:before {
  content: "\f7fb"; }

.fa-egg-fried:before {
  content: "\f7fc"; }

.fa-eject:before {
  content: "\f052"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-elephant:before {
  content: "\f6da"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-h-alt:before {
  content: "\f39b"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-ellipsis-v-alt:before {
  content: "\f39c"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-empty-set:before {
  content: "\f656"; }

.fa-engine-warning:before {
  content: "\f5f2"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-envelope-open:before {
  content: "\f2b6"; }

.fa-envelope-open-dollar:before {
  content: "\f657"; }

.fa-envelope-open-text:before {
  content: "\f658"; }

.fa-envelope-square:before {
  content: "\f199"; }

.fa-envira:before {
  content: "\f299"; }

.fa-equals:before {
  content: "\f52c"; }

.fa-eraser:before {
  content: "\f12d"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-ethernet:before {
  content: "\f796"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-euro-sign:before {
  content: "\f153"; }

.fa-evernote:before {
  content: "\f839"; }

.fa-exchange:before {
  content: "\f0ec"; }

.fa-exchange-alt:before {
  content: "\f362"; }

.fa-exclamation:before {
  content: "\f12a"; }

.fa-exclamation-circle:before {
  content: "\f06a"; }

.fa-exclamation-square:before {
  content: "\f321"; }

.fa-exclamation-triangle:before {
  content: "\f071"; }

.fa-expand:before {
  content: "\f065"; }

.fa-expand-alt:before {
  content: "\f424"; }

.fa-expand-arrows:before {
  content: "\f31d"; }

.fa-expand-arrows-alt:before {
  content: "\f31e"; }

.fa-expand-wide:before {
  content: "\f320"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-external-link:before {
  content: "\f08e"; }

.fa-external-link-alt:before {
  content: "\f35d"; }

.fa-external-link-square:before {
  content: "\f14c"; }

.fa-external-link-square-alt:before {
  content: "\f360"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-eye-dropper:before {
  content: "\f1fb"; }

.fa-eye-evil:before {
  content: "\f6db"; }

.fa-eye-slash:before {
  content: "\f070"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fan:before {
  content: "\f863"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-farm:before {
  content: "\f864"; }

.fa-fast-backward:before {
  content: "\f049"; }

.fa-fast-forward:before {
  content: "\f050"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-feather:before {
  content: "\f52d"; }

.fa-feather-alt:before {
  content: "\f56b"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-female:before {
  content: "\f182"; }

.fa-field-hockey:before {
  content: "\f44c"; }

.fa-fighter-jet:before {
  content: "\f0fb"; }

.fa-figma:before {
  content: "\f799"; }

.fa-file:before {
  content: "\f15b"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-file-archive:before {
  content: "\f1c6"; }

.fa-file-audio:before {
  content: "\f1c7"; }

.fa-file-certificate:before {
  content: "\f5f3"; }

.fa-file-chart-line:before {
  content: "\f659"; }

.fa-file-chart-pie:before {
  content: "\f65a"; }

.fa-file-check:before {
  content: "\f316"; }

.fa-file-code:before {
  content: "\f1c9"; }

.fa-file-contract:before {
  content: "\f56c"; }

.fa-file-csv:before {
  content: "\f6dd"; }

.fa-file-download:before {
  content: "\f56d"; }

.fa-file-edit:before {
  content: "\f31c"; }

.fa-file-excel:before {
  content: "\f1c3"; }

.fa-file-exclamation:before {
  content: "\f31a"; }

.fa-file-export:before {
  content: "\f56e"; }

.fa-file-image:before {
  content: "\f1c5"; }

.fa-file-import:before {
  content: "\f56f"; }

.fa-file-invoice:before {
  content: "\f570"; }

.fa-file-invoice-dollar:before {
  content: "\f571"; }

.fa-file-medical:before {
  content: "\f477"; }

.fa-file-medical-alt:before {
  content: "\f478"; }

.fa-file-minus:before {
  content: "\f318"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-file-plus:before {
  content: "\f319"; }

.fa-file-powerpoint:before {
  content: "\f1c4"; }

.fa-file-prescription:before {
  content: "\f572"; }

.fa-file-search:before {
  content: "\f865"; }

.fa-file-signature:before {
  content: "\f573"; }

.fa-file-spreadsheet:before {
  content: "\f65b"; }

.fa-file-times:before {
  content: "\f317"; }

.fa-file-upload:before {
  content: "\f574"; }

.fa-file-user:before {
  content: "\f65c"; }

.fa-file-video:before {
  content: "\f1c8"; }

.fa-file-word:before {
  content: "\f1c2"; }

.fa-files-medical:before {
  content: "\f7fd"; }

.fa-fill:before {
  content: "\f575"; }

.fa-fill-drip:before {
  content: "\f576"; }

.fa-film:before {
  content: "\f008"; }

.fa-film-alt:before {
  content: "\f3a0"; }

.fa-filter:before {
  content: "\f0b0"; }

.fa-fingerprint:before {
  content: "\f577"; }

.fa-fire:before {
  content: "\f06d"; }

.fa-fire-alt:before {
  content: "\f7e4"; }

.fa-fire-extinguisher:before {
  content: "\f134"; }

.fa-fire-smoke:before {
  content: "\f74b"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-fireplace:before {
  content: "\f79a"; }

.fa-first-aid:before {
  content: "\f479"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-fish:before {
  content: "\f578"; }

.fa-fish-cooked:before {
  content: "\f7fe"; }

.fa-fist-raised:before {
  content: "\f6de"; }

.fa-flag:before {
  content: "\f024"; }

.fa-flag-alt:before {
  content: "\f74c"; }

.fa-flag-checkered:before {
  content: "\f11e"; }

.fa-flag-usa:before {
  content: "\f74d"; }

.fa-flame:before {
  content: "\f6df"; }

.fa-flask:before {
  content: "\f0c3"; }

.fa-flask-poison:before {
  content: "\f6e0"; }

.fa-flask-potion:before {
  content: "\f6e1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-flower:before {
  content: "\f7ff"; }

.fa-flower-daffodil:before {
  content: "\f800"; }

.fa-flower-tulip:before {
  content: "\f801"; }

.fa-flushed:before {
  content: "\f579"; }

.fa-fly:before {
  content: "\f417"; }

.fa-fog:before {
  content: "\f74e"; }

.fa-folder:before {
  content: "\f07b"; }

.fa-folder-minus:before {
  content: "\f65d"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-folder-plus:before {
  content: "\f65e"; }

.fa-folder-times:before {
  content: "\f65f"; }

.fa-folder-tree:before {
  content: "\f802"; }

.fa-folders:before {
  content: "\f660"; }

.fa-font:before {
  content: "\f031"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-font-awesome-flag:before {
  content: "\f425"; }

.fa-font-awesome-logo-full:before {
  content: "\f4e6"; }

.fa-font-case:before {
  content: "\f866"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-football-ball:before {
  content: "\f44e"; }

.fa-football-helmet:before {
  content: "\f44f"; }

.fa-forklift:before {
  content: "\f47a"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-forward:before {
  content: "\f04e"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-fragile:before {
  content: "\f4bb"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-french-fries:before {
  content: "\f803"; }

.fa-frog:before {
  content: "\f52e"; }

.fa-frosty-head:before {
  content: "\f79b"; }

.fa-frown:before {
  content: "\f119"; }

.fa-frown-open:before {
  content: "\f57a"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-function:before {
  content: "\f661"; }

.fa-funnel-dollar:before {
  content: "\f662"; }

.fa-futbol:before {
  content: "\f1e3"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-game-board:before {
  content: "\f867"; }

.fa-game-board-alt:before {
  content: "\f868"; }

.fa-gamepad:before {
  content: "\f11b"; }

.fa-gas-pump:before {
  content: "\f52f"; }

.fa-gas-pump-slash:before {
  content: "\f5f4"; }

.fa-gavel:before {
  content: "\f0e3"; }

.fa-gem:before {
  content: "\f3a5"; }

.fa-genderless:before {
  content: "\f22d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-ghost:before {
  content: "\f6e2"; }

.fa-gift:before {
  content: "\f06b"; }

.fa-gift-card:before {
  content: "\f663"; }

.fa-gifts:before {
  content: "\f79c"; }

.fa-gingerbread-man:before {
  content: "\f79d"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-alt:before {
  content: "\f841"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glass:before {
  content: "\f804"; }

.fa-glass-champagne:before {
  content: "\f79e"; }

.fa-glass-cheers:before {
  content: "\f79f"; }

.fa-glass-citrus:before {
  content: "\f869"; }

.fa-glass-martini:before {
  content: "\f000"; }

.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fa-glass-whiskey-rocks:before {
  content: "\f7a1"; }

.fa-glasses:before {
  content: "\f530"; }

.fa-glasses-alt:before {
  content: "\f5f5"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-globe:before {
  content: "\f0ac"; }

.fa-globe-africa:before {
  content: "\f57c"; }

.fa-globe-americas:before {
  content: "\f57d"; }

.fa-globe-asia:before {
  content: "\f57e"; }

.fa-globe-europe:before {
  content: "\f7a2"; }

.fa-globe-snow:before {
  content: "\f7a3"; }

.fa-globe-stand:before {
  content: "\f5f6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-golf-ball:before {
  content: "\f450"; }

.fa-golf-club:before {
  content: "\f451"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gopuram:before {
  content: "\f664"; }

.fa-graduation-cap:before {
  content: "\f19d"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-greater-than:before {
  content: "\f531"; }

.fa-greater-than-equal:before {
  content: "\f532"; }

.fa-grimace:before {
  content: "\f57f"; }

.fa-grin:before {
  content: "\f580"; }

.fa-grin-alt:before {
  content: "\f581"; }

.fa-grin-beam:before {
  content: "\f582"; }

.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fa-grin-hearts:before {
  content: "\f584"; }

.fa-grin-squint:before {
  content: "\f585"; }

.fa-grin-squint-tears:before {
  content: "\f586"; }

.fa-grin-stars:before {
  content: "\f587"; }

.fa-grin-tears:before {
  content: "\f588"; }

.fa-grin-tongue:before {
  content: "\f589"; }

.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fa-grin-wink:before {
  content: "\f58c"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-grip-lines:before {
  content: "\f7a4"; }

.fa-grip-lines-vertical:before {
  content: "\f7a5"; }

.fa-grip-vertical:before {
  content: "\f58e"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guitar:before {
  content: "\f7a6"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-h-square:before {
  content: "\f0fd"; }

.fa-h1:before {
  content: "\f313"; }

.fa-h2:before {
  content: "\f314"; }

.fa-h3:before {
  content: "\f315"; }

.fa-h4:before {
  content: "\f86a"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hamburger:before {
  content: "\f805"; }

.fa-hammer:before {
  content: "\f6e3"; }

.fa-hammer-war:before {
  content: "\f6e4"; }

.fa-hamsa:before {
  content: "\f665"; }

.fa-hand-heart:before {
  content: "\f4bc"; }

.fa-hand-holding:before {
  content: "\f4bd"; }

.fa-hand-holding-box:before {
  content: "\f47b"; }

.fa-hand-holding-heart:before {
  content: "\f4be"; }

.fa-hand-holding-magic:before {
  content: "\f6e5"; }

.fa-hand-holding-seedling:before {
  content: "\f4bf"; }

.fa-hand-holding-usd:before {
  content: "\f4c0"; }

.fa-hand-holding-water:before {
  content: "\f4c1"; }

.fa-hand-lizard:before {
  content: "\f258"; }

.fa-hand-middle-finger:before {
  content: "\f806"; }

.fa-hand-paper:before {
  content: "\f256"; }

.fa-hand-peace:before {
  content: "\f25b"; }

.fa-hand-point-down:before {
  content: "\f0a7"; }

.fa-hand-point-left:before {
  content: "\f0a5"; }

.fa-hand-point-right:before {
  content: "\f0a4"; }

.fa-hand-point-up:before {
  content: "\f0a6"; }

.fa-hand-pointer:before {
  content: "\f25a"; }

.fa-hand-receiving:before {
  content: "\f47c"; }

.fa-hand-rock:before {
  content: "\f255"; }

.fa-hand-scissors:before {
  content: "\f257"; }

.fa-hand-spock:before {
  content: "\f259"; }

.fa-hands:before {
  content: "\f4c2"; }

.fa-hands-heart:before {
  content: "\f4c3"; }

.fa-hands-helping:before {
  content: "\f4c4"; }

.fa-hands-usd:before {
  content: "\f4c5"; }

.fa-handshake:before {
  content: "\f2b5"; }

.fa-handshake-alt:before {
  content: "\f4c6"; }

.fa-hanukiah:before {
  content: "\f6e6"; }

.fa-hard-hat:before {
  content: "\f807"; }

.fa-hashtag:before {
  content: "\f292"; }

.fa-hat-chef:before {
  content: "\f86b"; }

.fa-hat-santa:before {
  content: "\f7a7"; }

.fa-hat-winter:before {
  content: "\f7a8"; }

.fa-hat-witch:before {
  content: "\f6e7"; }

.fa-hat-wizard:before {
  content: "\f6e8"; }

.fa-haykal:before {
  content: "\f666"; }

.fa-hdd:before {
  content: "\f0a0"; }

.fa-head-side:before {
  content: "\f6e9"; }

.fa-head-side-brain:before {
  content: "\f808"; }

.fa-head-side-medical:before {
  content: "\f809"; }

.fa-head-vr:before {
  content: "\f6ea"; }

.fa-heading:before {
  content: "\f1dc"; }

.fa-headphones:before {
  content: "\f025"; }

.fa-headphones-alt:before {
  content: "\f58f"; }

.fa-headset:before {
  content: "\f590"; }

.fa-heart:before {
  content: "\f004"; }

.fa-heart-broken:before {
  content: "\f7a9"; }

.fa-heart-circle:before {
  content: "\f4c7"; }

.fa-heart-rate:before {
  content: "\f5f8"; }

.fa-heart-square:before {
  content: "\f4c8"; }

.fa-heartbeat:before {
  content: "\f21e"; }

.fa-helicopter:before {
  content: "\f533"; }

.fa-helmet-battle:before {
  content: "\f6eb"; }

.fa-hexagon:before {
  content: "\f312"; }

.fa-highlighter:before {
  content: "\f591"; }

.fa-hiking:before {
  content: "\f6ec"; }

.fa-hippo:before {
  content: "\f6ed"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-history:before {
  content: "\f1da"; }

.fa-hockey-mask:before {
  content: "\f6ee"; }

.fa-hockey-puck:before {
  content: "\f453"; }

.fa-hockey-sticks:before {
  content: "\f454"; }

.fa-holly-berry:before {
  content: "\f7aa"; }

.fa-home:before {
  content: "\f015"; }

.fa-home-alt:before {
  content: "\f80a"; }

.fa-home-heart:before {
  content: "\f4c9"; }

.fa-home-lg:before {
  content: "\f80b"; }

.fa-home-lg-alt:before {
  content: "\f80c"; }

.fa-hood-cloak:before {
  content: "\f6ef"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-horizontal-rule:before {
  content: "\f86c"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-horse:before {
  content: "\f6f0"; }

.fa-horse-head:before {
  content: "\f7ab"; }

.fa-hospital:before {
  content: "\f0f8"; }

.fa-hospital-alt:before {
  content: "\f47d"; }

.fa-hospital-symbol:before {
  content: "\f47e"; }

.fa-hospital-user:before {
  content: "\f80d"; }

.fa-hospitals:before {
  content: "\f80e"; }

.fa-hot-tub:before {
  content: "\f593"; }

.fa-hotdog:before {
  content: "\f80f"; }

.fa-hotel:before {
  content: "\f594"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-hourglass:before {
  content: "\f254"; }

.fa-hourglass-end:before {
  content: "\f253"; }

.fa-hourglass-half:before {
  content: "\f252"; }

.fa-hourglass-start:before {
  content: "\f251"; }

.fa-house-damage:before {
  content: "\f6f1"; }

.fa-house-flood:before {
  content: "\f74f"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-hryvnia:before {
  content: "\f6f2"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-humidity:before {
  content: "\f750"; }

.fa-hurricane:before {
  content: "\f751"; }

.fa-i-cursor:before {
  content: "\f246"; }

.fa-ice-cream:before {
  content: "\f810"; }

.fa-ice-skate:before {
  content: "\f7ac"; }

.fa-icicles:before {
  content: "\f7ad"; }

.fa-icons:before {
  content: "\f86d"; }

.fa-icons-alt:before {
  content: "\f86e"; }

.fa-id-badge:before {
  content: "\f2c1"; }

.fa-id-card:before {
  content: "\f2c2"; }

.fa-id-card-alt:before {
  content: "\f47f"; }

.fa-igloo:before {
  content: "\f7ae"; }

.fa-image:before {
  content: "\f03e"; }

.fa-images:before {
  content: "\f302"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-inbox:before {
  content: "\f01c"; }

.fa-inbox-in:before {
  content: "\f310"; }

.fa-inbox-out:before {
  content: "\f311"; }

.fa-indent:before {
  content: "\f03c"; }

.fa-industry:before {
  content: "\f275"; }

.fa-industry-alt:before {
  content: "\f3b3"; }

.fa-infinity:before {
  content: "\f534"; }

.fa-info:before {
  content: "\f129"; }

.fa-info-circle:before {
  content: "\f05a"; }

.fa-info-square:before {
  content: "\f30f"; }

.fa-inhaler:before {
  content: "\f5f9"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-integral:before {
  content: "\f667"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-intersection:before {
  content: "\f668"; }

.fa-inventory:before {
  content: "\f480"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-island-tropical:before {
  content: "\f811"; }

.fa-italic:before {
  content: "\f033"; }

.fa-itch-io:before {
  content: "\f83a"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi:before {
  content: "\f669"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joint:before {
  content: "\f595"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-journal-whills:before {
  content: "\f66a"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaaba:before {
  content: "\f66b"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-kerning:before {
  content: "\f86f"; }

.fa-key:before {
  content: "\f084"; }

.fa-key-skeleton:before {
  content: "\f6f3"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keyboard:before {
  content: "\f11c"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-keynote:before {
  content: "\f66c"; }

.fa-khanda:before {
  content: "\f66d"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-kidneys:before {
  content: "\f5fb"; }

.fa-kiss:before {
  content: "\f596"; }

.fa-kiss-beam:before {
  content: "\f597"; }

.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fa-kite:before {
  content: "\f6f4"; }

.fa-kiwi-bird:before {
  content: "\f535"; }

.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-lambda:before {
  content: "\f66e"; }

.fa-lamp:before {
  content: "\f4ca"; }

.fa-landmark:before {
  content: "\f66f"; }

.fa-landmark-alt:before {
  content: "\f752"; }

.fa-language:before {
  content: "\f1ab"; }

.fa-laptop:before {
  content: "\f109"; }

.fa-laptop-code:before {
  content: "\f5fc"; }

.fa-laptop-medical:before {
  content: "\f812"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-laugh:before {
  content: "\f599"; }

.fa-laugh-beam:before {
  content: "\f59a"; }

.fa-laugh-squint:before {
  content: "\f59b"; }

.fa-laugh-wink:before {
  content: "\f59c"; }

.fa-layer-group:before {
  content: "\f5fd"; }

.fa-layer-minus:before {
  content: "\f5fe"; }

.fa-layer-plus:before {
  content: "\f5ff"; }

.fa-leaf:before {
  content: "\f06c"; }

.fa-leaf-heart:before {
  content: "\f4cb"; }

.fa-leaf-maple:before {
  content: "\f6f6"; }

.fa-leaf-oak:before {
  content: "\f6f7"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-lemon:before {
  content: "\f094"; }

.fa-less:before {
  content: "\f41d"; }

.fa-less-than:before {
  content: "\f536"; }

.fa-less-than-equal:before {
  content: "\f537"; }

.fa-level-down:before {
  content: "\f149"; }

.fa-level-down-alt:before {
  content: "\f3be"; }

.fa-level-up:before {
  content: "\f148"; }

.fa-level-up-alt:before {
  content: "\f3bf"; }

.fa-life-ring:before {
  content: "\f1cd"; }

.fa-lightbulb:before {
  content: "\f0eb"; }

.fa-lightbulb-dollar:before {
  content: "\f670"; }

.fa-lightbulb-exclamation:before {
  content: "\f671"; }

.fa-lightbulb-on:before {
  content: "\f672"; }

.fa-lightbulb-slash:before {
  content: "\f673"; }

.fa-lights-holiday:before {
  content: "\f7b2"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-line-columns:before {
  content: "\f870"; }

.fa-line-height:before {
  content: "\f871"; }

.fa-link:before {
  content: "\f0c1"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lips:before {
  content: "\f600"; }

.fa-lira-sign:before {
  content: "\f195"; }

.fa-list:before {
  content: "\f03a"; }

.fa-list-alt:before {
  content: "\f022"; }

.fa-list-ol:before {
  content: "\f0cb"; }

.fa-list-ul:before {
  content: "\f0ca"; }

.fa-location:before {
  content: "\f601"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-location-circle:before {
  content: "\f602"; }

.fa-location-slash:before {
  content: "\f603"; }

.fa-lock:before {
  content: "\f023"; }

.fa-lock-alt:before {
  content: "\f30d"; }

.fa-lock-open:before {
  content: "\f3c1"; }

.fa-lock-open-alt:before {
  content: "\f3c2"; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-long-arrow-down:before {
  content: "\f175"; }

.fa-long-arrow-left:before {
  content: "\f177"; }

.fa-long-arrow-right:before {
  content: "\f178"; }

.fa-long-arrow-up:before {
  content: "\f176"; }

.fa-loveseat:before {
  content: "\f4cc"; }

.fa-low-vision:before {
  content: "\f2a8"; }

.fa-luchador:before {
  content: "\f455"; }

.fa-luggage-cart:before {
  content: "\f59d"; }

.fa-lungs:before {
  content: "\f604"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-mace:before {
  content: "\f6f8"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-magic:before {
  content: "\f0d0"; }

.fa-magnet:before {
  content: "\f076"; }

.fa-mail-bulk:before {
  content: "\f674"; }

.fa-mailbox:before {
  content: "\f813"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-male:before {
  content: "\f183"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-mandolin:before {
  content: "\f6f9"; }

.fa-map:before {
  content: "\f279"; }

.fa-map-marked:before {
  content: "\f59f"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-map-marker:before {
  content: "\f041"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-map-marker-alt-slash:before {
  content: "\f605"; }

.fa-map-marker-check:before {
  content: "\f606"; }

.fa-map-marker-edit:before {
  content: "\f607"; }

.fa-map-marker-exclamation:before {
  content: "\f608"; }

.fa-map-marker-minus:before {
  content: "\f609"; }

.fa-map-marker-plus:before {
  content: "\f60a"; }

.fa-map-marker-question:before {
  content: "\f60b"; }

.fa-map-marker-slash:before {
  content: "\f60c"; }

.fa-map-marker-smile:before {
  content: "\f60d"; }

.fa-map-marker-times:before {
  content: "\f60e"; }

.fa-map-pin:before {
  content: "\f276"; }

.fa-map-signs:before {
  content: "\f277"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-marker:before {
  content: "\f5a1"; }

.fa-mars:before {
  content: "\f222"; }

.fa-mars-double:before {
  content: "\f227"; }

.fa-mars-stroke:before {
  content: "\f229"; }

.fa-mars-stroke-h:before {
  content: "\f22b"; }

.fa-mars-stroke-v:before {
  content: "\f22a"; }

.fa-mask:before {
  content: "\f6fa"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-meat:before {
  content: "\f814"; }

.fa-medal:before {
  content: "\f5a2"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f3c7"; }

.fa-medkit:before {
  content: "\f0fa"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaphone:before {
  content: "\f675"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-meh:before {
  content: "\f11a"; }

.fa-meh-blank:before {
  content: "\f5a4"; }

.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fa-memory:before {
  content: "\f538"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-menorah:before {
  content: "\f676"; }

.fa-mercury:before {
  content: "\f223"; }

.fa-meteor:before {
  content: "\f753"; }

.fa-microchip:before {
  content: "\f2db"; }

.fa-microphone:before {
  content: "\f130"; }

.fa-microphone-alt:before {
  content: "\f3c9"; }

.fa-microphone-alt-slash:before {
  content: "\f539"; }

.fa-microphone-slash:before {
  content: "\f131"; }

.fa-microscope:before {
  content: "\f610"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mind-share:before {
  content: "\f677"; }

.fa-minus:before {
  content: "\f068"; }

.fa-minus-circle:before {
  content: "\f056"; }

.fa-minus-hexagon:before {
  content: "\f307"; }

.fa-minus-octagon:before {
  content: "\f308"; }

.fa-minus-square:before {
  content: "\f146"; }

.fa-mistletoe:before {
  content: "\f7b4"; }

.fa-mitten:before {
  content: "\f7b5"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-mobile:before {
  content: "\f10b"; }

.fa-mobile-alt:before {
  content: "\f3cd"; }

.fa-mobile-android:before {
  content: "\f3ce"; }

.fa-mobile-android-alt:before {
  content: "\f3cf"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-money-bill:before {
  content: "\f0d6"; }

.fa-money-bill-alt:before {
  content: "\f3d1"; }

.fa-money-bill-wave:before {
  content: "\f53a"; }

.fa-money-bill-wave-alt:before {
  content: "\f53b"; }

.fa-money-check:before {
  content: "\f53c"; }

.fa-money-check-alt:before {
  content: "\f53d"; }

.fa-money-check-edit:before {
  content: "\f872"; }

.fa-money-check-edit-alt:before {
  content: "\f873"; }

.fa-monitor-heart-rate:before {
  content: "\f611"; }

.fa-monkey:before {
  content: "\f6fb"; }

.fa-monument:before {
  content: "\f5a6"; }

.fa-moon:before {
  content: "\f186"; }

.fa-moon-cloud:before {
  content: "\f754"; }

.fa-moon-stars:before {
  content: "\f755"; }

.fa-mortar-pestle:before {
  content: "\f5a7"; }

.fa-mosque:before {
  content: "\f678"; }

.fa-motorcycle:before {
  content: "\f21c"; }

.fa-mountain:before {
  content: "\f6fc"; }

.fa-mountains:before {
  content: "\f6fd"; }

.fa-mouse-pointer:before {
  content: "\f245"; }

.fa-mug:before {
  content: "\f874"; }

.fa-mug-hot:before {
  content: "\f7b6"; }

.fa-mug-marshmallows:before {
  content: "\f7b7"; }

.fa-mug-tea:before {
  content: "\f875"; }

.fa-music:before {
  content: "\f001"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-narwhal:before {
  content: "\f6fe"; }

.fa-neos:before {
  content: "\f612"; }

.fa-network-wired:before {
  content: "\f6ff"; }

.fa-neuter:before {
  content: "\f22c"; }

.fa-newspaper:before {
  content: "\f1ea"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-not-equal:before {
  content: "\f53e"; }

.fa-notes-medical:before {
  content: "\f481"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-object-group:before {
  content: "\f247"; }

.fa-object-ungroup:before {
  content: "\f248"; }

.fa-octagon:before {
  content: "\f306"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-oil-can:before {
  content: "\f613"; }

.fa-oil-temp:before {
  content: "\f614"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-om:before {
  content: "\f679"; }

.fa-omega:before {
  content: "\f67a"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-ornament:before {
  content: "\f7b8"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-otter:before {
  content: "\f700"; }

.fa-outdent:before {
  content: "\f03b"; }

.fa-overline:before {
  content: "\f876"; }

.fa-page-break:before {
  content: "\f877"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-pager:before {
  content: "\f815"; }

.fa-paint-brush:before {
  content: "\f1fc"; }

.fa-paint-brush-alt:before {
  content: "\f5a9"; }

.fa-paint-roller:before {
  content: "\f5aa"; }

.fa-palette:before {
  content: "\f53f"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-pallet:before {
  content: "\f482"; }

.fa-pallet-alt:before {
  content: "\f483"; }

.fa-paper-plane:before {
  content: "\f1d8"; }

.fa-paperclip:before {
  content: "\f0c6"; }

.fa-parachute-box:before {
  content: "\f4cd"; }

.fa-paragraph:before {
  content: "\f1dd"; }

.fa-paragraph-rtl:before {
  content: "\f878"; }

.fa-parking:before {
  content: "\f540"; }

.fa-parking-circle:before {
  content: "\f615"; }

.fa-parking-circle-slash:before {
  content: "\f616"; }

.fa-parking-slash:before {
  content: "\f617"; }

.fa-passport:before {
  content: "\f5ab"; }

.fa-pastafarianism:before {
  content: "\f67b"; }

.fa-paste:before {
  content: "\f0ea"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-pause:before {
  content: "\f04c"; }

.fa-pause-circle:before {
  content: "\f28b"; }

.fa-paw:before {
  content: "\f1b0"; }

.fa-paw-alt:before {
  content: "\f701"; }

.fa-paw-claws:before {
  content: "\f702"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-peace:before {
  content: "\f67c"; }

.fa-pegasus:before {
  content: "\f703"; }

.fa-pen:before {
  content: "\f304"; }

.fa-pen-alt:before {
  content: "\f305"; }

.fa-pen-fancy:before {
  content: "\f5ac"; }

.fa-pen-nib:before {
  content: "\f5ad"; }

.fa-pen-square:before {
  content: "\f14b"; }

.fa-pencil:before {
  content: "\f040"; }

.fa-pencil-alt:before {
  content: "\f303"; }

.fa-pencil-paintbrush:before {
  content: "\f618"; }

.fa-pencil-ruler:before {
  content: "\f5ae"; }

.fa-pennant:before {
  content: "\f456"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-people-carry:before {
  content: "\f4ce"; }

.fa-pepper-hot:before {
  content: "\f816"; }

.fa-percent:before {
  content: "\f295"; }

.fa-percentage:before {
  content: "\f541"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-person-booth:before {
  content: "\f756"; }

.fa-person-carry:before {
  content: "\f4cf"; }

.fa-person-dolly:before {
  content: "\f4d0"; }

.fa-person-dolly-empty:before {
  content: "\f4d1"; }

.fa-person-sign:before {
  content: "\f757"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-phone:before {
  content: "\f095"; }

.fa-phone-alt:before {
  content: "\f879"; }

.fa-phone-laptop:before {
  content: "\f87a"; }

.fa-phone-office:before {
  content: "\f67d"; }

.fa-phone-plus:before {
  content: "\f4d2"; }

.fa-phone-slash:before {
  content: "\f3dd"; }

.fa-phone-square:before {
  content: "\f098"; }

.fa-phone-square-alt:before {
  content: "\f87b"; }

.fa-phone-volume:before {
  content: "\f2a0"; }

.fa-photo-video:before {
  content: "\f87c"; }

.fa-php:before {
  content: "\f457"; }

.fa-pi:before {
  content: "\f67e"; }

.fa-pie:before {
  content: "\f705"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pig:before {
  content: "\f706"; }

.fa-piggy-bank:before {
  content: "\f4d3"; }

.fa-pills:before {
  content: "\f484"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-pizza:before {
  content: "\f817"; }

.fa-pizza-slice:before {
  content: "\f818"; }

.fa-place-of-worship:before {
  content: "\f67f"; }

.fa-plane:before {
  content: "\f072"; }

.fa-plane-alt:before {
  content: "\f3de"; }

.fa-plane-arrival:before {
  content: "\f5af"; }

.fa-plane-departure:before {
  content: "\f5b0"; }

.fa-play:before {
  content: "\f04b"; }

.fa-play-circle:before {
  content: "\f144"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-plug:before {
  content: "\f1e6"; }

.fa-plus:before {
  content: "\f067"; }

.fa-plus-circle:before {
  content: "\f055"; }

.fa-plus-hexagon:before {
  content: "\f300"; }

.fa-plus-octagon:before {
  content: "\f301"; }

.fa-plus-square:before {
  content: "\f0fe"; }

.fa-podcast:before {
  content: "\f2ce"; }

.fa-podium:before {
  content: "\f680"; }

.fa-podium-star:before {
  content: "\f758"; }

.fa-poll:before {
  content: "\f681"; }

.fa-poll-h:before {
  content: "\f682"; }

.fa-poll-people:before {
  content: "\f759"; }

.fa-poo:before {
  content: "\f2fe"; }

.fa-poo-storm:before {
  content: "\f75a"; }

.fa-poop:before {
  content: "\f619"; }

.fa-popcorn:before {
  content: "\f819"; }

.fa-portrait:before {
  content: "\f3e0"; }

.fa-pound-sign:before {
  content: "\f154"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-pray:before {
  content: "\f683"; }

.fa-praying-hands:before {
  content: "\f684"; }

.fa-prescription:before {
  content: "\f5b1"; }

.fa-prescription-bottle:before {
  content: "\f485"; }

.fa-prescription-bottle-alt:before {
  content: "\f486"; }

.fa-presentation:before {
  content: "\f685"; }

.fa-print:before {
  content: "\f02f"; }

.fa-print-search:before {
  content: "\f81a"; }

.fa-print-slash:before {
  content: "\f686"; }

.fa-procedures:before {
  content: "\f487"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-project-diagram:before {
  content: "\f542"; }

.fa-pumpkin:before {
  content: "\f707"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-puzzle-piece:before {
  content: "\f12e"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-qrcode:before {
  content: "\f029"; }

.fa-question:before {
  content: "\f128"; }

.fa-question-circle:before {
  content: "\f059"; }

.fa-question-square:before {
  content: "\f2fd"; }

.fa-quidditch:before {
  content: "\f458"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-quote-left:before {
  content: "\f10d"; }

.fa-quote-right:before {
  content: "\f10e"; }

.fa-quran:before {
  content: "\f687"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-rabbit:before {
  content: "\f708"; }

.fa-rabbit-fast:before {
  content: "\f709"; }

.fa-racquet:before {
  content: "\f45a"; }

.fa-radiation:before {
  content: "\f7b9"; }

.fa-radiation-alt:before {
  content: "\f7ba"; }

.fa-rainbow:before {
  content: "\f75b"; }

.fa-raindrops:before {
  content: "\f75c"; }

.fa-ram:before {
  content: "\f70a"; }

.fa-ramp-loading:before {
  content: "\f4d4"; }

.fa-random:before {
  content: "\f074"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-receipt:before {
  content: "\f543"; }

.fa-rectangle-landscape:before {
  content: "\f2fa"; }

.fa-rectangle-portrait:before {
  content: "\f2fb"; }

.fa-rectangle-wide:before {
  content: "\f2fc"; }

.fa-recycle:before {
  content: "\f1b8"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-redo:before {
  content: "\f01e"; }

.fa-redo-alt:before {
  content: "\f2f9"; }

.fa-registered:before {
  content: "\f25d"; }

.fa-remove-format:before {
  content: "\f87d"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-repeat:before {
  content: "\f363"; }

.fa-repeat-1:before {
  content: "\f365"; }

.fa-repeat-1-alt:before {
  content: "\f366"; }

.fa-repeat-alt:before {
  content: "\f364"; }

.fa-reply:before {
  content: "\f3e5"; }

.fa-reply-all:before {
  content: "\f122"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-republican:before {
  content: "\f75e"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-restroom:before {
  content: "\f7bd"; }

.fa-retweet:before {
  content: "\f079"; }

.fa-retweet-alt:before {
  content: "\f361"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-ribbon:before {
  content: "\f4d6"; }

.fa-ring:before {
  content: "\f70b"; }

.fa-rings-wedding:before {
  content: "\f81b"; }

.fa-road:before {
  content: "\f018"; }

.fa-robot:before {
  content: "\f544"; }

.fa-rocket:before {
  content: "\f135"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-route:before {
  content: "\f4d7"; }

.fa-route-highway:before {
  content: "\f61a"; }

.fa-route-interstate:before {
  content: "\f61b"; }

.fa-rss:before {
  content: "\f09e"; }

.fa-rss-square:before {
  content: "\f143"; }

.fa-ruble-sign:before {
  content: "\f158"; }

.fa-ruler:before {
  content: "\f545"; }

.fa-ruler-combined:before {
  content: "\f546"; }

.fa-ruler-horizontal:before {
  content: "\f547"; }

.fa-ruler-triangle:before {
  content: "\f61c"; }

.fa-ruler-vertical:before {
  content: "\f548"; }

.fa-running:before {
  content: "\f70c"; }

.fa-rupee-sign:before {
  content: "\f156"; }

.fa-rv:before {
  content: "\f7be"; }

.fa-sack:before {
  content: "\f81c"; }

.fa-sack-dollar:before {
  content: "\f81d"; }

.fa-sad-cry:before {
  content: "\f5b3"; }

.fa-sad-tear:before {
  content: "\f5b4"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salad:before {
  content: "\f81e"; }

.fa-salesforce:before {
  content: "\f83b"; }

.fa-sandwich:before {
  content: "\f81f"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-satellite:before {
  content: "\f7bf"; }

.fa-satellite-dish:before {
  content: "\f7c0"; }

.fa-sausage:before {
  content: "\f820"; }

.fa-save:before {
  content: "\f0c7"; }

.fa-scalpel:before {
  content: "\f61d"; }

.fa-scalpel-path:before {
  content: "\f61e"; }

.fa-scanner:before {
  content: "\f488"; }

.fa-scanner-keyboard:before {
  content: "\f489"; }

.fa-scanner-touchscreen:before {
  content: "\f48a"; }

.fa-scarecrow:before {
  content: "\f70d"; }

.fa-scarf:before {
  content: "\f7c1"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-school:before {
  content: "\f549"; }

.fa-screwdriver:before {
  content: "\f54a"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-scroll:before {
  content: "\f70e"; }

.fa-scroll-old:before {
  content: "\f70f"; }

.fa-scrubber:before {
  content: "\f2f8"; }

.fa-scythe:before {
  content: "\f710"; }

.fa-sd-card:before {
  content: "\f7c2"; }

.fa-search:before {
  content: "\f002"; }

.fa-search-dollar:before {
  content: "\f688"; }

.fa-search-location:before {
  content: "\f689"; }

.fa-search-minus:before {
  content: "\f010"; }

.fa-search-plus:before {
  content: "\f00e"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-send-back:before {
  content: "\f87e"; }

.fa-send-backward:before {
  content: "\f87f"; }

.fa-server:before {
  content: "\f233"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shapes:before {
  content: "\f61f"; }

.fa-share:before {
  content: "\f064"; }

.fa-share-all:before {
  content: "\f367"; }

.fa-share-alt:before {
  content: "\f1e0"; }

.fa-share-alt-square:before {
  content: "\f1e1"; }

.fa-share-square:before {
  content: "\f14d"; }

.fa-sheep:before {
  content: "\f711"; }

.fa-shekel-sign:before {
  content: "\f20b"; }

.fa-shield:before {
  content: "\f132"; }

.fa-shield-alt:before {
  content: "\f3ed"; }

.fa-shield-check:before {
  content: "\f2f7"; }

.fa-shield-cross:before {
  content: "\f712"; }

.fa-ship:before {
  content: "\f21a"; }

.fa-shipping-fast:before {
  content: "\f48b"; }

.fa-shipping-timed:before {
  content: "\f48c"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shish-kebab:before {
  content: "\f821"; }

.fa-shoe-prints:before {
  content: "\f54b"; }

.fa-shopping-bag:before {
  content: "\f290"; }

.fa-shopping-basket:before {
  content: "\f291"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-shovel:before {
  content: "\f713"; }

.fa-shovel-snow:before {
  content: "\f7c3"; }

.fa-shower:before {
  content: "\f2cc"; }

.fa-shredder:before {
  content: "\f68a"; }

.fa-shuttle-van:before {
  content: "\f5b6"; }

.fa-shuttlecock:before {
  content: "\f45b"; }

.fa-sickle:before {
  content: "\f822"; }

.fa-sigma:before {
  content: "\f68b"; }

.fa-sign:before {
  content: "\f4d9"; }

.fa-sign-in:before {
  content: "\f090"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-sign-language:before {
  content: "\f2a7"; }

.fa-sign-out:before {
  content: "\f08b"; }

.fa-sign-out-alt:before {
  content: "\f2f5"; }

.fa-signal:before {
  content: "\f012"; }

.fa-signal-1:before {
  content: "\f68c"; }

.fa-signal-2:before {
  content: "\f68d"; }

.fa-signal-3:before {
  content: "\f68e"; }

.fa-signal-4:before {
  content: "\f68f"; }

.fa-signal-alt:before {
  content: "\f690"; }

.fa-signal-alt-1:before {
  content: "\f691"; }

.fa-signal-alt-2:before {
  content: "\f692"; }

.fa-signal-alt-3:before {
  content: "\f693"; }

.fa-signal-alt-slash:before {
  content: "\f694"; }

.fa-signal-slash:before {
  content: "\f695"; }

.fa-signature:before {
  content: "\f5b7"; }

.fa-sim-card:before {
  content: "\f7c4"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sitemap:before {
  content: "\f0e8"; }

.fa-sith:before {
  content: "\f512"; }

.fa-skating:before {
  content: "\f7c5"; }

.fa-skeleton:before {
  content: "\f620"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-ski-jump:before {
  content: "\f7c7"; }

.fa-ski-lift:before {
  content: "\f7c8"; }

.fa-skiing:before {
  content: "\f7c9"; }

.fa-skiing-nordic:before {
  content: "\f7ca"; }

.fa-skull:before {
  content: "\f54c"; }

.fa-skull-crossbones:before {
  content: "\f714"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f3ef"; }

.fa-slash:before {
  content: "\f715"; }

.fa-sledding:before {
  content: "\f7cb"; }

.fa-sleigh:before {
  content: "\f7cc"; }

.fa-sliders-h:before {
  content: "\f1de"; }

.fa-sliders-h-square:before {
  content: "\f3f0"; }

.fa-sliders-v:before {
  content: "\f3f1"; }

.fa-sliders-v-square:before {
  content: "\f3f2"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-smile:before {
  content: "\f118"; }

.fa-smile-beam:before {
  content: "\f5b8"; }

.fa-smile-plus:before {
  content: "\f5b9"; }

.fa-smile-wink:before {
  content: "\f4da"; }

.fa-smog:before {
  content: "\f75f"; }

.fa-smoke:before {
  content: "\f760"; }

.fa-smoking:before {
  content: "\f48d"; }

.fa-smoking-ban:before {
  content: "\f54d"; }

.fa-sms:before {
  content: "\f7cd"; }

.fa-snake:before {
  content: "\f716"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ac"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-snooze:before {
  content: "\f880"; }

.fa-snow-blowing:before {
  content: "\f761"; }

.fa-snowboarding:before {
  content: "\f7ce"; }

.fa-snowflake:before {
  content: "\f2dc"; }

.fa-snowflakes:before {
  content: "\f7cf"; }

.fa-snowman:before {
  content: "\f7d0"; }

.fa-snowmobile:before {
  content: "\f7d1"; }

.fa-snowplow:before {
  content: "\f7d2"; }

.fa-socks:before {
  content: "\f696"; }

.fa-solar-panel:before {
  content: "\f5ba"; }

.fa-sort:before {
  content: "\f0dc"; }

.fa-sort-alpha-down:before {
  content: "\f15d"; }

.fa-sort-alpha-down-alt:before {
  content: "\f881"; }

.fa-sort-alpha-up:before {
  content: "\f15e"; }

.fa-sort-alpha-up-alt:before {
  content: "\f882"; }

.fa-sort-alt:before {
  content: "\f883"; }

.fa-sort-amount-down:before {
  content: "\f160"; }

.fa-sort-amount-down-alt:before {
  content: "\f884"; }

.fa-sort-amount-up:before {
  content: "\f161"; }

.fa-sort-amount-up-alt:before {
  content: "\f885"; }

.fa-sort-down:before {
  content: "\f0dd"; }

.fa-sort-numeric-down:before {
  content: "\f162"; }

.fa-sort-numeric-down-alt:before {
  content: "\f886"; }

.fa-sort-numeric-up:before {
  content: "\f163"; }

.fa-sort-numeric-up-alt:before {
  content: "\f887"; }

.fa-sort-shapes-down:before {
  content: "\f888"; }

.fa-sort-shapes-down-alt:before {
  content: "\f889"; }

.fa-sort-shapes-up:before {
  content: "\f88a"; }

.fa-sort-shapes-up-alt:before {
  content: "\f88b"; }

.fa-sort-size-down:before {
  content: "\f88c"; }

.fa-sort-size-down-alt:before {
  content: "\f88d"; }

.fa-sort-size-up:before {
  content: "\f88e"; }

.fa-sort-size-up-alt:before {
  content: "\f88f"; }

.fa-sort-up:before {
  content: "\f0de"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-soup:before {
  content: "\f823"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-spa:before {
  content: "\f5bb"; }

.fa-space-shuttle:before {
  content: "\f197"; }

.fa-spade:before {
  content: "\f2f4"; }

.fa-sparkles:before {
  content: "\f890"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-speaker-deck:before {
  content: "\f83c"; }

.fa-spell-check:before {
  content: "\f891"; }

.fa-spider:before {
  content: "\f717"; }

.fa-spider-black-widow:before {
  content: "\f718"; }

.fa-spider-web:before {
  content: "\f719"; }

.fa-spinner:before {
  content: "\f110"; }

.fa-spinner-third:before {
  content: "\f3f4"; }

.fa-splotch:before {
  content: "\f5bc"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-spray-can:before {
  content: "\f5bd"; }

.fa-square:before {
  content: "\f0c8"; }

.fa-square-full:before {
  content: "\f45c"; }

.fa-square-root:before {
  content: "\f697"; }

.fa-square-root-alt:before {
  content: "\f698"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-squirrel:before {
  content: "\f71a"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stackpath:before {
  content: "\f842"; }

.fa-staff:before {
  content: "\f71b"; }

.fa-stamp:before {
  content: "\f5bf"; }

.fa-star:before {
  content: "\f005"; }

.fa-star-and-crescent:before {
  content: "\f699"; }

.fa-star-christmas:before {
  content: "\f7d4"; }

.fa-star-exclamation:before {
  content: "\f2f3"; }

.fa-star-half:before {
  content: "\f089"; }

.fa-star-half-alt:before {
  content: "\f5c0"; }

.fa-star-of-david:before {
  content: "\f69a"; }

.fa-star-of-life:before {
  content: "\f621"; }

.fa-stars:before {
  content: "\f762"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steak:before {
  content: "\f824"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-steering-wheel:before {
  content: "\f622"; }

.fa-step-backward:before {
  content: "\f048"; }

.fa-step-forward:before {
  content: "\f051"; }

.fa-stethoscope:before {
  content: "\f0f1"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-sticky-note:before {
  content: "\f249"; }

.fa-stocking:before {
  content: "\f7d5"; }

.fa-stomach:before {
  content: "\f623"; }

.fa-stop:before {
  content: "\f04d"; }

.fa-stop-circle:before {
  content: "\f28d"; }

.fa-stopwatch:before {
  content: "\f2f2"; }

.fa-store:before {
  content: "\f54e"; }

.fa-store-alt:before {
  content: "\f54f"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stream:before {
  content: "\f550"; }

.fa-street-view:before {
  content: "\f21d"; }

.fa-stretcher:before {
  content: "\f825"; }

.fa-strikethrough:before {
  content: "\f0cc"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-stroopwafel:before {
  content: "\f551"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-subscript:before {
  content: "\f12c"; }

.fa-subway:before {
  content: "\f239"; }

.fa-suitcase:before {
  content: "\f0f2"; }

.fa-suitcase-rolling:before {
  content: "\f5c1"; }

.fa-sun:before {
  content: "\f185"; }

.fa-sun-cloud:before {
  content: "\f763"; }

.fa-sun-dust:before {
  content: "\f764"; }

.fa-sun-haze:before {
  content: "\f765"; }

.fa-sunglasses:before {
  content: "\f892"; }

.fa-sunrise:before {
  content: "\f766"; }

.fa-sunset:before {
  content: "\f767"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-superscript:before {
  content: "\f12b"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-surprise:before {
  content: "\f5c2"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swatchbook:before {
  content: "\f5c3"; }

.fa-swimmer:before {
  content: "\f5c4"; }

.fa-swimming-pool:before {
  content: "\f5c5"; }

.fa-sword:before {
  content: "\f71c"; }

.fa-swords:before {
  content: "\f71d"; }

.fa-symfony:before {
  content: "\f83d"; }

.fa-synagogue:before {
  content: "\f69b"; }

.fa-sync:before {
  content: "\f021"; }

.fa-sync-alt:before {
  content: "\f2f1"; }

.fa-syringe:before {
  content: "\f48e"; }

.fa-table:before {
  content: "\f0ce"; }

.fa-table-tennis:before {
  content: "\f45d"; }

.fa-tablet:before {
  content: "\f10a"; }

.fa-tablet-alt:before {
  content: "\f3fa"; }

.fa-tablet-android:before {
  content: "\f3fb"; }

.fa-tablet-android-alt:before {
  content: "\f3fc"; }

.fa-tablet-rugged:before {
  content: "\f48f"; }

.fa-tablets:before {
  content: "\f490"; }

.fa-tachometer:before {
  content: "\f0e4"; }

.fa-tachometer-alt:before {
  content: "\f3fd"; }

.fa-tachometer-alt-average:before {
  content: "\f624"; }

.fa-tachometer-alt-fast:before {
  content: "\f625"; }

.fa-tachometer-alt-fastest:before {
  content: "\f626"; }

.fa-tachometer-alt-slow:before {
  content: "\f627"; }

.fa-tachometer-alt-slowest:before {
  content: "\f628"; }

.fa-tachometer-average:before {
  content: "\f629"; }

.fa-tachometer-fast:before {
  content: "\f62a"; }

.fa-tachometer-fastest:before {
  content: "\f62b"; }

.fa-tachometer-slow:before {
  content: "\f62c"; }

.fa-tachometer-slowest:before {
  content: "\f62d"; }

.fa-taco:before {
  content: "\f826"; }

.fa-tag:before {
  content: "\f02b"; }

.fa-tags:before {
  content: "\f02c"; }

.fa-tally:before {
  content: "\f69c"; }

.fa-tanakh:before {
  content: "\f827"; }

.fa-tape:before {
  content: "\f4db"; }

.fa-tasks:before {
  content: "\f0ae"; }

.fa-tasks-alt:before {
  content: "\f828"; }

.fa-taxi:before {
  content: "\f1ba"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-teeth:before {
  content: "\f62e"; }

.fa-teeth-open:before {
  content: "\f62f"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f3fe"; }

.fa-temperature-frigid:before {
  content: "\f768"; }

.fa-temperature-high:before {
  content: "\f769"; }

.fa-temperature-hot:before {
  content: "\f76a"; }

.fa-temperature-low:before {
  content: "\f76b"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-tenge:before {
  content: "\f7d7"; }

.fa-tennis-ball:before {
  content: "\f45e"; }

.fa-terminal:before {
  content: "\f120"; }

.fa-text:before {
  content: "\f893"; }

.fa-text-height:before {
  content: "\f034"; }

.fa-text-size:before {
  content: "\f894"; }

.fa-text-width:before {
  content: "\f035"; }

.fa-th:before {
  content: "\f00a"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-th-list:before {
  content: "\f00b"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-theater-masks:before {
  content: "\f630"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-thermometer:before {
  content: "\f491"; }

.fa-thermometer-empty:before {
  content: "\f2cb"; }

.fa-thermometer-full:before {
  content: "\f2c7"; }

.fa-thermometer-half:before {
  content: "\f2c9"; }

.fa-thermometer-quarter:before {
  content: "\f2ca"; }

.fa-thermometer-three-quarters:before {
  content: "\f2c8"; }

.fa-theta:before {
  content: "\f69e"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-thumbs-down:before {
  content: "\f165"; }

.fa-thumbs-up:before {
  content: "\f164"; }

.fa-thumbtack:before {
  content: "\f08d"; }

.fa-thunderstorm:before {
  content: "\f76c"; }

.fa-thunderstorm-moon:before {
  content: "\f76d"; }

.fa-thunderstorm-sun:before {
  content: "\f76e"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-ticket-alt:before {
  content: "\f3ff"; }

.fa-tilde:before {
  content: "\f69f"; }

.fa-times:before {
  content: "\f00d"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-times-hexagon:before {
  content: "\f2ee"; }

.fa-times-octagon:before {
  content: "\f2f0"; }

.fa-times-square:before {
  content: "\f2d3"; }

.fa-tint:before {
  content: "\f043"; }

.fa-tint-slash:before {
  content: "\f5c7"; }

.fa-tire:before {
  content: "\f631"; }

.fa-tire-flat:before {
  content: "\f632"; }

.fa-tire-pressure-warning:before {
  content: "\f633"; }

.fa-tire-rugged:before {
  content: "\f634"; }

.fa-tired:before {
  content: "\f5c8"; }

.fa-toggle-off:before {
  content: "\f204"; }

.fa-toggle-on:before {
  content: "\f205"; }

.fa-toilet:before {
  content: "\f7d8"; }

.fa-toilet-paper:before {
  content: "\f71e"; }

.fa-toilet-paper-alt:before {
  content: "\f71f"; }

.fa-tombstone:before {
  content: "\f720"; }

.fa-tombstone-alt:before {
  content: "\f721"; }

.fa-toolbox:before {
  content: "\f552"; }

.fa-tools:before {
  content: "\f7d9"; }

.fa-tooth:before {
  content: "\f5c9"; }

.fa-toothbrush:before {
  content: "\f635"; }

.fa-torah:before {
  content: "\f6a0"; }

.fa-torii-gate:before {
  content: "\f6a1"; }

.fa-tornado:before {
  content: "\f76f"; }

.fa-tractor:before {
  content: "\f722"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trademark:before {
  content: "\f25c"; }

.fa-traffic-cone:before {
  content: "\f636"; }

.fa-traffic-light:before {
  content: "\f637"; }

.fa-traffic-light-go:before {
  content: "\f638"; }

.fa-traffic-light-slow:before {
  content: "\f639"; }

.fa-traffic-light-stop:before {
  content: "\f63a"; }

.fa-train:before {
  content: "\f238"; }

.fa-tram:before {
  content: "\f7da"; }

.fa-transgender:before {
  content: "\f224"; }

.fa-transgender-alt:before {
  content: "\f225"; }

.fa-trash:before {
  content: "\f1f8"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-trash-restore:before {
  content: "\f829"; }

.fa-trash-restore-alt:before {
  content: "\f82a"; }

.fa-trash-undo:before {
  content: "\f895"; }

.fa-trash-undo-alt:before {
  content: "\f896"; }

.fa-treasure-chest:before {
  content: "\f723"; }

.fa-tree:before {
  content: "\f1bb"; }

.fa-tree-alt:before {
  content: "\f400"; }

.fa-tree-christmas:before {
  content: "\f7db"; }

.fa-tree-decorated:before {
  content: "\f7dc"; }

.fa-tree-large:before {
  content: "\f7dd"; }

.fa-tree-palm:before {
  content: "\f82b"; }

.fa-trees:before {
  content: "\f724"; }

.fa-trello:before {
  content: "\f181"; }

.fa-triangle:before {
  content: "\f2ec"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-trophy:before {
  content: "\f091"; }

.fa-trophy-alt:before {
  content: "\f2eb"; }

.fa-truck:before {
  content: "\f0d1"; }

.fa-truck-container:before {
  content: "\f4dc"; }

.fa-truck-couch:before {
  content: "\f4dd"; }

.fa-truck-loading:before {
  content: "\f4de"; }

.fa-truck-monster:before {
  content: "\f63b"; }

.fa-truck-moving:before {
  content: "\f4df"; }

.fa-truck-pickup:before {
  content: "\f63c"; }

.fa-truck-plow:before {
  content: "\f7de"; }

.fa-truck-ramp:before {
  content: "\f4e0"; }

.fa-tshirt:before {
  content: "\f553"; }

.fa-tty:before {
  content: "\f1e4"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-turkey:before {
  content: "\f725"; }

.fa-turtle:before {
  content: "\f726"; }

.fa-tv:before {
  content: "\f26c"; }

.fa-tv-retro:before {
  content: "\f401"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbrella:before {
  content: "\f0e9"; }

.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fa-underline:before {
  content: "\f0cd"; }

.fa-undo:before {
  content: "\f0e2"; }

.fa-undo-alt:before {
  content: "\f2ea"; }

.fa-unicorn:before {
  content: "\f727"; }

.fa-union:before {
  content: "\f6a2"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-universal-access:before {
  content: "\f29a"; }

.fa-university:before {
  content: "\f19c"; }

.fa-unlink:before {
  content: "\f127"; }

.fa-unlock:before {
  content: "\f09c"; }

.fa-unlock-alt:before {
  content: "\f13e"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-upload:before {
  content: "\f093"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usd-circle:before {
  content: "\f2e8"; }

.fa-usd-square:before {
  content: "\f2e9"; }

.fa-user:before {
  content: "\f007"; }

.fa-user-alt:before {
  content: "\f406"; }

.fa-user-alt-slash:before {
  content: "\f4fa"; }

.fa-user-astronaut:before {
  content: "\f4fb"; }

.fa-user-chart:before {
  content: "\f6a3"; }

.fa-user-check:before {
  content: "\f4fc"; }

.fa-user-circle:before {
  content: "\f2bd"; }

.fa-user-clock:before {
  content: "\f4fd"; }

.fa-user-cog:before {
  content: "\f4fe"; }

.fa-user-crown:before {
  content: "\f6a4"; }

.fa-user-edit:before {
  content: "\f4ff"; }

.fa-user-friends:before {
  content: "\f500"; }

.fa-user-graduate:before {
  content: "\f501"; }

.fa-user-hard-hat:before {
  content: "\f82c"; }

.fa-user-headset:before {
  content: "\f82d"; }

.fa-user-injured:before {
  content: "\f728"; }

.fa-user-lock:before {
  content: "\f502"; }

.fa-user-md:before {
  content: "\f0f0"; }

.fa-user-md-chat:before {
  content: "\f82e"; }

.fa-user-minus:before {
  content: "\f503"; }

.fa-user-ninja:before {
  content: "\f504"; }

.fa-user-nurse:before {
  content: "\f82f"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-user-secret:before {
  content: "\f21b"; }

.fa-user-shield:before {
  content: "\f505"; }

.fa-user-slash:before {
  content: "\f506"; }

.fa-user-tag:before {
  content: "\f507"; }

.fa-user-tie:before {
  content: "\f508"; }

.fa-user-times:before {
  content: "\f235"; }

.fa-users:before {
  content: "\f0c0"; }

.fa-users-class:before {
  content: "\f63d"; }

.fa-users-cog:before {
  content: "\f509"; }

.fa-users-crown:before {
  content: "\f6a5"; }

.fa-users-medical:before {
  content: "\f830"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-utensil-fork:before {
  content: "\f2e3"; }

.fa-utensil-knife:before {
  content: "\f2e4"; }

.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fa-utensils:before {
  content: "\f2e7"; }

.fa-utensils-alt:before {
  content: "\f2e6"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-value-absolute:before {
  content: "\f6a6"; }

.fa-vector-square:before {
  content: "\f5cb"; }

.fa-venus:before {
  content: "\f221"; }

.fa-venus-double:before {
  content: "\f226"; }

.fa-venus-mars:before {
  content: "\f228"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-vial:before {
  content: "\f492"; }

.fa-vials:before {
  content: "\f493"; }

.fa-viber:before {
  content: "\f409"; }

.fa-video:before {
  content: "\f03d"; }

.fa-video-plus:before {
  content: "\f4e1"; }

.fa-video-slash:before {
  content: "\f4e2"; }

.fa-vihara:before {
  content: "\f6a7"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-voicemail:before {
  content: "\f897"; }

.fa-volcano:before {
  content: "\f770"; }

.fa-volleyball-ball:before {
  content: "\f45f"; }

.fa-volume:before {
  content: "\f6a8"; }

.fa-volume-down:before {
  content: "\f027"; }

.fa-volume-mute:before {
  content: "\f6a9"; }

.fa-volume-off:before {
  content: "\f026"; }

.fa-volume-slash:before {
  content: "\f2e2"; }

.fa-volume-up:before {
  content: "\f028"; }

.fa-vote-nay:before {
  content: "\f771"; }

.fa-vote-yea:before {
  content: "\f772"; }

.fa-vr-cardboard:before {
  content: "\f729"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-walker:before {
  content: "\f831"; }

.fa-walking:before {
  content: "\f554"; }

.fa-wallet:before {
  content: "\f555"; }

.fa-wand:before {
  content: "\f72a"; }

.fa-wand-magic:before {
  content: "\f72b"; }

.fa-warehouse:before {
  content: "\f494"; }

.fa-warehouse-alt:before {
  content: "\f495"; }

.fa-washer:before {
  content: "\f898"; }

.fa-watch:before {
  content: "\f2e1"; }

.fa-watch-fitness:before {
  content: "\f63e"; }

.fa-water:before {
  content: "\f773"; }

.fa-water-lower:before {
  content: "\f774"; }

.fa-water-rise:before {
  content: "\f775"; }

.fa-wave-sine:before {
  content: "\f899"; }

.fa-wave-square:before {
  content: "\f83e"; }

.fa-wave-triangle:before {
  content: "\f89a"; }

.fa-waze:before {
  content: "\f83f"; }

.fa-webcam:before {
  content: "\f832"; }

.fa-webcam-slash:before {
  content: "\f833"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weight:before {
  content: "\f496"; }

.fa-weight-hanging:before {
  content: "\f5cd"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whale:before {
  content: "\f72c"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-wheat:before {
  content: "\f72d"; }

.fa-wheelchair:before {
  content: "\f193"; }

.fa-whistle:before {
  content: "\f460"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wifi:before {
  content: "\f1eb"; }

.fa-wifi-1:before {
  content: "\f6aa"; }

.fa-wifi-2:before {
  content: "\f6ab"; }

.fa-wifi-slash:before {
  content: "\f6ac"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-wind:before {
  content: "\f72e"; }

.fa-wind-turbine:before {
  content: "\f89b"; }

.fa-wind-warning:before {
  content: "\f776"; }

.fa-window:before {
  content: "\f40e"; }

.fa-window-alt:before {
  content: "\f40f"; }

.fa-window-close:before {
  content: "\f410"; }

.fa-window-maximize:before {
  content: "\f2d0"; }

.fa-window-minimize:before {
  content: "\f2d1"; }

.fa-window-restore:before {
  content: "\f2d2"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-windsock:before {
  content: "\f777"; }

.fa-wine-bottle:before {
  content: "\f72f"; }

.fa-wine-glass:before {
  content: "\f4e3"; }

.fa-wine-glass-alt:before {
  content: "\f5ce"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-won-sign:before {
  content: "\f159"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-wreath:before {
  content: "\f7e2"; }

.fa-wrench:before {
  content: "\f0ad"; }

.fa-x-ray:before {
  content: "\f497"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yammer:before {
  content: "\f840"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yen-sign:before {
  content: "\f157"; }

.fa-yin-yang:before {
  content: "\f6ad"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*!
 * Font Awesome Pro 5.10.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../webfonts/fa-light-300.eot");
  src: url("../webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.woff") format("woff"), url("../webfonts/fa-light-300.ttf") format("truetype"), url("../webfonts/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }

/*!
 * Font Awesome Pro 5.10.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../webfonts/fa-duotone-900.eot");
  src: url("../webfonts/fa-duotone-900.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-duotone-900.woff2") format("woff2"), url("../webfonts/fa-duotone-900.woff") format("woff"), url("../webfonts/fa-duotone-900.ttf") format("truetype"), url("../webfonts/fa-duotone-900.svg#fontawesome") format("svg"); }

.fad {
  position: relative;
  font-family: 'Font Awesome 5 Duotone';
  font-weight: 900; }

.fad:before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad:after {
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:after,
.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse {
  color: #fff; }

.fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute; }

.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fad.fa-fw:before {
  left: 50%;
  transform: translateX(-50%); }

.fad.fa-abacus:after {
  content: "\10f640"; }

.fad.fa-acorn:after {
  content: "\10f6ae"; }

.fad.fa-ad:after {
  content: "\10f641"; }

.fad.fa-address-book:after {
  content: "\10f2b9"; }

.fad.fa-address-card:after {
  content: "\10f2bb"; }

.fad.fa-adjust:after {
  content: "\10f042"; }

.fad.fa-air-freshener:after {
  content: "\10f5d0"; }

.fad.fa-alarm-clock:after {
  content: "\10f34e"; }

.fad.fa-alarm-exclamation:after {
  content: "\10f843"; }

.fad.fa-alarm-plus:after {
  content: "\10f844"; }

.fad.fa-alarm-snooze:after {
  content: "\10f845"; }

.fad.fa-alicorn:after {
  content: "\10f6b0"; }

.fad.fa-align-center:after {
  content: "\10f037"; }

.fad.fa-align-justify:after {
  content: "\10f039"; }

.fad.fa-align-left:after {
  content: "\10f036"; }

.fad.fa-align-right:after {
  content: "\10f038"; }

.fad.fa-align-slash:after {
  content: "\10f846"; }

.fad.fa-allergies:after {
  content: "\10f461"; }

.fad.fa-ambulance:after {
  content: "\10f0f9"; }

.fad.fa-american-sign-language-interpreting:after {
  content: "\10f2a3"; }

.fad.fa-analytics:after {
  content: "\10f643"; }

.fad.fa-anchor:after {
  content: "\10f13d"; }

.fad.fa-angel:after {
  content: "\10f779"; }

.fad.fa-angle-double-down:after {
  content: "\10f103"; }

.fad.fa-angle-double-left:after {
  content: "\10f100"; }

.fad.fa-angle-double-right:after {
  content: "\10f101"; }

.fad.fa-angle-double-up:after {
  content: "\10f102"; }

.fad.fa-angle-down:after {
  content: "\10f107"; }

.fad.fa-angle-left:after {
  content: "\10f104"; }

.fad.fa-angle-right:after {
  content: "\10f105"; }

.fad.fa-angle-up:after {
  content: "\10f106"; }

.fad.fa-angry:after {
  content: "\10f556"; }

.fad.fa-ankh:after {
  content: "\10f644"; }

.fad.fa-apple-alt:after {
  content: "\10f5d1"; }

.fad.fa-apple-crate:after {
  content: "\10f6b1"; }

.fad.fa-archive:after {
  content: "\10f187"; }

.fad.fa-archway:after {
  content: "\10f557"; }

.fad.fa-arrow-alt-circle-down:after {
  content: "\10f358"; }

.fad.fa-arrow-alt-circle-left:after {
  content: "\10f359"; }

.fad.fa-arrow-alt-circle-right:after {
  content: "\10f35a"; }

.fad.fa-arrow-alt-circle-up:after {
  content: "\10f35b"; }

.fad.fa-arrow-alt-down:after {
  content: "\10f354"; }

.fad.fa-arrow-alt-from-bottom:after {
  content: "\10f346"; }

.fad.fa-arrow-alt-from-left:after {
  content: "\10f347"; }

.fad.fa-arrow-alt-from-right:after {
  content: "\10f348"; }

.fad.fa-arrow-alt-from-top:after {
  content: "\10f349"; }

.fad.fa-arrow-alt-left:after {
  content: "\10f355"; }

.fad.fa-arrow-alt-right:after {
  content: "\10f356"; }

.fad.fa-arrow-alt-square-down:after {
  content: "\10f350"; }

.fad.fa-arrow-alt-square-left:after {
  content: "\10f351"; }

.fad.fa-arrow-alt-square-right:after {
  content: "\10f352"; }

.fad.fa-arrow-alt-square-up:after {
  content: "\10f353"; }

.fad.fa-arrow-alt-to-bottom:after {
  content: "\10f34a"; }

.fad.fa-arrow-alt-to-left:after {
  content: "\10f34b"; }

.fad.fa-arrow-alt-to-right:after {
  content: "\10f34c"; }

.fad.fa-arrow-alt-to-top:after {
  content: "\10f34d"; }

.fad.fa-arrow-alt-up:after {
  content: "\10f357"; }

.fad.fa-arrow-circle-down:after {
  content: "\10f0ab"; }

.fad.fa-arrow-circle-left:after {
  content: "\10f0a8"; }

.fad.fa-arrow-circle-right:after {
  content: "\10f0a9"; }

.fad.fa-arrow-circle-up:after {
  content: "\10f0aa"; }

.fad.fa-arrow-down:after {
  content: "\10f063"; }

.fad.fa-arrow-from-bottom:after {
  content: "\10f342"; }

.fad.fa-arrow-from-left:after {
  content: "\10f343"; }

.fad.fa-arrow-from-right:after {
  content: "\10f344"; }

.fad.fa-arrow-from-top:after {
  content: "\10f345"; }

.fad.fa-arrow-left:after {
  content: "\10f060"; }

.fad.fa-arrow-right:after {
  content: "\10f061"; }

.fad.fa-arrow-square-down:after {
  content: "\10f339"; }

.fad.fa-arrow-square-left:after {
  content: "\10f33a"; }

.fad.fa-arrow-square-right:after {
  content: "\10f33b"; }

.fad.fa-arrow-square-up:after {
  content: "\10f33c"; }

.fad.fa-arrow-to-bottom:after {
  content: "\10f33d"; }

.fad.fa-arrow-to-left:after {
  content: "\10f33e"; }

.fad.fa-arrow-to-right:after {
  content: "\10f340"; }

.fad.fa-arrow-to-top:after {
  content: "\10f341"; }

.fad.fa-arrow-up:after {
  content: "\10f062"; }

.fad.fa-arrows:after {
  content: "\10f047"; }

.fad.fa-arrows-alt:after {
  content: "\10f0b2"; }

.fad.fa-arrows-alt-h:after {
  content: "\10f337"; }

.fad.fa-arrows-alt-v:after {
  content: "\10f338"; }

.fad.fa-arrows-h:after {
  content: "\10f07e"; }

.fad.fa-arrows-v:after {
  content: "\10f07d"; }

.fad.fa-assistive-listening-systems:after {
  content: "\10f2a2"; }

.fad.fa-asterisk:after {
  content: "\10f069"; }

.fad.fa-at:after {
  content: "\10f1fa"; }

.fad.fa-atlas:after {
  content: "\10f558"; }

.fad.fa-atom:after {
  content: "\10f5d2"; }

.fad.fa-atom-alt:after {
  content: "\10f5d3"; }

.fad.fa-audio-description:after {
  content: "\10f29e"; }

.fad.fa-award:after {
  content: "\10f559"; }

.fad.fa-axe:after {
  content: "\10f6b2"; }

.fad.fa-axe-battle:after {
  content: "\10f6b3"; }

.fad.fa-baby:after {
  content: "\10f77c"; }

.fad.fa-baby-carriage:after {
  content: "\10f77d"; }

.fad.fa-backpack:after {
  content: "\10f5d4"; }

.fad.fa-backspace:after {
  content: "\10f55a"; }

.fad.fa-backward:after {
  content: "\10f04a"; }

.fad.fa-bacon:after {
  content: "\10f7e5"; }

.fad.fa-badge:after {
  content: "\10f335"; }

.fad.fa-badge-check:after {
  content: "\10f336"; }

.fad.fa-badge-dollar:after {
  content: "\10f645"; }

.fad.fa-badge-percent:after {
  content: "\10f646"; }

.fad.fa-badger-honey:after {
  content: "\10f6b4"; }

.fad.fa-bags-shopping:after {
  content: "\10f847"; }

.fad.fa-balance-scale:after {
  content: "\10f24e"; }

.fad.fa-balance-scale-left:after {
  content: "\10f515"; }

.fad.fa-balance-scale-right:after {
  content: "\10f516"; }

.fad.fa-ball-pile:after {
  content: "\10f77e"; }

.fad.fa-ballot:after {
  content: "\10f732"; }

.fad.fa-ballot-check:after {
  content: "\10f733"; }

.fad.fa-ban:after {
  content: "\10f05e"; }

.fad.fa-band-aid:after {
  content: "\10f462"; }

.fad.fa-barcode:after {
  content: "\10f02a"; }

.fad.fa-barcode-alt:after {
  content: "\10f463"; }

.fad.fa-barcode-read:after {
  content: "\10f464"; }

.fad.fa-barcode-scan:after {
  content: "\10f465"; }

.fad.fa-bars:after {
  content: "\10f0c9"; }

.fad.fa-baseball:after {
  content: "\10f432"; }

.fad.fa-baseball-ball:after {
  content: "\10f433"; }

.fad.fa-basketball-ball:after {
  content: "\10f434"; }

.fad.fa-basketball-hoop:after {
  content: "\10f435"; }

.fad.fa-bat:after {
  content: "\10f6b5"; }

.fad.fa-bath:after {
  content: "\10f2cd"; }

.fad.fa-battery-bolt:after {
  content: "\10f376"; }

.fad.fa-battery-empty:after {
  content: "\10f244"; }

.fad.fa-battery-full:after {
  content: "\10f240"; }

.fad.fa-battery-half:after {
  content: "\10f242"; }

.fad.fa-battery-quarter:after {
  content: "\10f243"; }

.fad.fa-battery-slash:after {
  content: "\10f377"; }

.fad.fa-battery-three-quarters:after {
  content: "\10f241"; }

.fad.fa-bed:after {
  content: "\10f236"; }

.fad.fa-beer:after {
  content: "\10f0fc"; }

.fad.fa-bell:after {
  content: "\10f0f3"; }

.fad.fa-bell-exclamation:after {
  content: "\10f848"; }

.fad.fa-bell-plus:after {
  content: "\10f849"; }

.fad.fa-bell-school:after {
  content: "\10f5d5"; }

.fad.fa-bell-school-slash:after {
  content: "\10f5d6"; }

.fad.fa-bell-slash:after {
  content: "\10f1f6"; }

.fad.fa-bells:after {
  content: "\10f77f"; }

.fad.fa-bezier-curve:after {
  content: "\10f55b"; }

.fad.fa-bible:after {
  content: "\10f647"; }

.fad.fa-bicycle:after {
  content: "\10f206"; }

.fad.fa-biking:after {
  content: "\10f84a"; }

.fad.fa-biking-mountain:after {
  content: "\10f84b"; }

.fad.fa-binoculars:after {
  content: "\10f1e5"; }

.fad.fa-biohazard:after {
  content: "\10f780"; }

.fad.fa-birthday-cake:after {
  content: "\10f1fd"; }

.fad.fa-blanket:after {
  content: "\10f498"; }

.fad.fa-blender:after {
  content: "\10f517"; }

.fad.fa-blender-phone:after {
  content: "\10f6b6"; }

.fad.fa-blind:after {
  content: "\10f29d"; }

.fad.fa-blog:after {
  content: "\10f781"; }

.fad.fa-bold:after {
  content: "\10f032"; }

.fad.fa-bolt:after {
  content: "\10f0e7"; }

.fad.fa-bomb:after {
  content: "\10f1e2"; }

.fad.fa-bone:after {
  content: "\10f5d7"; }

.fad.fa-bone-break:after {
  content: "\10f5d8"; }

.fad.fa-bong:after {
  content: "\10f55c"; }

.fad.fa-book:after {
  content: "\10f02d"; }

.fad.fa-book-alt:after {
  content: "\10f5d9"; }

.fad.fa-book-dead:after {
  content: "\10f6b7"; }

.fad.fa-book-heart:after {
  content: "\10f499"; }

.fad.fa-book-medical:after {
  content: "\10f7e6"; }

.fad.fa-book-open:after {
  content: "\10f518"; }

.fad.fa-book-reader:after {
  content: "\10f5da"; }

.fad.fa-book-spells:after {
  content: "\10f6b8"; }

.fad.fa-book-user:after {
  content: "\10f7e7"; }

.fad.fa-bookmark:after {
  content: "\10f02e"; }

.fad.fa-books:after {
  content: "\10f5db"; }

.fad.fa-books-medical:after {
  content: "\10f7e8"; }

.fad.fa-boot:after {
  content: "\10f782"; }

.fad.fa-booth-curtain:after {
  content: "\10f734"; }

.fad.fa-border-all:after {
  content: "\10f84c"; }

.fad.fa-border-bottom:after {
  content: "\10f84d"; }

.fad.fa-border-center-h:after {
  content: "\10f89c"; }

.fad.fa-border-center-v:after {
  content: "\10f89d"; }

.fad.fa-border-inner:after {
  content: "\10f84e"; }

.fad.fa-border-left:after {
  content: "\10f84f"; }

.fad.fa-border-none:after {
  content: "\10f850"; }

.fad.fa-border-outer:after {
  content: "\10f851"; }

.fad.fa-border-right:after {
  content: "\10f852"; }

.fad.fa-border-style:after {
  content: "\10f853"; }

.fad.fa-border-style-alt:after {
  content: "\10f854"; }

.fad.fa-border-top:after {
  content: "\10f855"; }

.fad.fa-bow-arrow:after {
  content: "\10f6b9"; }

.fad.fa-bowling-ball:after {
  content: "\10f436"; }

.fad.fa-bowling-pins:after {
  content: "\10f437"; }

.fad.fa-box:after {
  content: "\10f466"; }

.fad.fa-box-alt:after {
  content: "\10f49a"; }

.fad.fa-box-ballot:after {
  content: "\10f735"; }

.fad.fa-box-check:after {
  content: "\10f467"; }

.fad.fa-box-fragile:after {
  content: "\10f49b"; }

.fad.fa-box-full:after {
  content: "\10f49c"; }

.fad.fa-box-heart:after {
  content: "\10f49d"; }

.fad.fa-box-open:after {
  content: "\10f49e"; }

.fad.fa-box-up:after {
  content: "\10f49f"; }

.fad.fa-box-usd:after {
  content: "\10f4a0"; }

.fad.fa-boxes:after {
  content: "\10f468"; }

.fad.fa-boxes-alt:after {
  content: "\10f4a1"; }

.fad.fa-boxing-glove:after {
  content: "\10f438"; }

.fad.fa-brackets:after {
  content: "\10f7e9"; }

.fad.fa-brackets-curly:after {
  content: "\10f7ea"; }

.fad.fa-braille:after {
  content: "\10f2a1"; }

.fad.fa-brain:after {
  content: "\10f5dc"; }

.fad.fa-bread-loaf:after {
  content: "\10f7eb"; }

.fad.fa-bread-slice:after {
  content: "\10f7ec"; }

.fad.fa-briefcase:after {
  content: "\10f0b1"; }

.fad.fa-briefcase-medical:after {
  content: "\10f469"; }

.fad.fa-bring-forward:after {
  content: "\10f856"; }

.fad.fa-bring-front:after {
  content: "\10f857"; }

.fad.fa-broadcast-tower:after {
  content: "\10f519"; }

.fad.fa-broom:after {
  content: "\10f51a"; }

.fad.fa-browser:after {
  content: "\10f37e"; }

.fad.fa-brush:after {
  content: "\10f55d"; }

.fad.fa-bug:after {
  content: "\10f188"; }

.fad.fa-building:after {
  content: "\10f1ad"; }

.fad.fa-bullhorn:after {
  content: "\10f0a1"; }

.fad.fa-bullseye:after {
  content: "\10f140"; }

.fad.fa-bullseye-arrow:after {
  content: "\10f648"; }

.fad.fa-bullseye-pointer:after {
  content: "\10f649"; }

.fad.fa-burger-soda:after {
  content: "\10f858"; }

.fad.fa-burn:after {
  content: "\10f46a"; }

.fad.fa-burrito:after {
  content: "\10f7ed"; }

.fad.fa-bus:after {
  content: "\10f207"; }

.fad.fa-bus-alt:after {
  content: "\10f55e"; }

.fad.fa-bus-school:after {
  content: "\10f5dd"; }

.fad.fa-business-time:after {
  content: "\10f64a"; }

.fad.fa-cabinet-filing:after {
  content: "\10f64b"; }

.fad.fa-calculator:after {
  content: "\10f1ec"; }

.fad.fa-calculator-alt:after {
  content: "\10f64c"; }

.fad.fa-calendar:after {
  content: "\10f133"; }

.fad.fa-calendar-alt:after {
  content: "\10f073"; }

.fad.fa-calendar-check:after {
  content: "\10f274"; }

.fad.fa-calendar-day:after {
  content: "\10f783"; }

.fad.fa-calendar-edit:after {
  content: "\10f333"; }

.fad.fa-calendar-exclamation:after {
  content: "\10f334"; }

.fad.fa-calendar-minus:after {
  content: "\10f272"; }

.fad.fa-calendar-plus:after {
  content: "\10f271"; }

.fad.fa-calendar-star:after {
  content: "\10f736"; }

.fad.fa-calendar-times:after {
  content: "\10f273"; }

.fad.fa-calendar-week:after {
  content: "\10f784"; }

.fad.fa-camera:after {
  content: "\10f030"; }

.fad.fa-camera-alt:after {
  content: "\10f332"; }

.fad.fa-camera-retro:after {
  content: "\10f083"; }

.fad.fa-campfire:after {
  content: "\10f6ba"; }

.fad.fa-campground:after {
  content: "\10f6bb"; }

.fad.fa-candle-holder:after {
  content: "\10f6bc"; }

.fad.fa-candy-cane:after {
  content: "\10f786"; }

.fad.fa-candy-corn:after {
  content: "\10f6bd"; }

.fad.fa-cannabis:after {
  content: "\10f55f"; }

.fad.fa-capsules:after {
  content: "\10f46b"; }

.fad.fa-car:after {
  content: "\10f1b9"; }

.fad.fa-car-alt:after {
  content: "\10f5de"; }

.fad.fa-car-battery:after {
  content: "\10f5df"; }

.fad.fa-car-building:after {
  content: "\10f859"; }

.fad.fa-car-bump:after {
  content: "\10f5e0"; }

.fad.fa-car-bus:after {
  content: "\10f85a"; }

.fad.fa-car-crash:after {
  content: "\10f5e1"; }

.fad.fa-car-garage:after {
  content: "\10f5e2"; }

.fad.fa-car-mechanic:after {
  content: "\10f5e3"; }

.fad.fa-car-side:after {
  content: "\10f5e4"; }

.fad.fa-car-tilt:after {
  content: "\10f5e5"; }

.fad.fa-car-wash:after {
  content: "\10f5e6"; }

.fad.fa-caret-circle-down:after {
  content: "\10f32d"; }

.fad.fa-caret-circle-left:after {
  content: "\10f32e"; }

.fad.fa-caret-circle-right:after {
  content: "\10f330"; }

.fad.fa-caret-circle-up:after {
  content: "\10f331"; }

.fad.fa-caret-down:after {
  content: "\10f0d7"; }

.fad.fa-caret-left:after {
  content: "\10f0d9"; }

.fad.fa-caret-right:after {
  content: "\10f0da"; }

.fad.fa-caret-square-down:after {
  content: "\10f150"; }

.fad.fa-caret-square-left:after {
  content: "\10f191"; }

.fad.fa-caret-square-right:after {
  content: "\10f152"; }

.fad.fa-caret-square-up:after {
  content: "\10f151"; }

.fad.fa-caret-up:after {
  content: "\10f0d8"; }

.fad.fa-carrot:after {
  content: "\10f787"; }

.fad.fa-cars:after {
  content: "\10f85b"; }

.fad.fa-cart-arrow-down:after {
  content: "\10f218"; }

.fad.fa-cart-plus:after {
  content: "\10f217"; }

.fad.fa-cash-register:after {
  content: "\10f788"; }

.fad.fa-cat:after {
  content: "\10f6be"; }

.fad.fa-cauldron:after {
  content: "\10f6bf"; }

.fad.fa-certificate:after {
  content: "\10f0a3"; }

.fad.fa-chair:after {
  content: "\10f6c0"; }

.fad.fa-chair-office:after {
  content: "\10f6c1"; }

.fad.fa-chalkboard:after {
  content: "\10f51b"; }

.fad.fa-chalkboard-teacher:after {
  content: "\10f51c"; }

.fad.fa-charging-station:after {
  content: "\10f5e7"; }

.fad.fa-chart-area:after {
  content: "\10f1fe"; }

.fad.fa-chart-bar:after {
  content: "\10f080"; }

.fad.fa-chart-line:after {
  content: "\10f201"; }

.fad.fa-chart-line-down:after {
  content: "\10f64d"; }

.fad.fa-chart-network:after {
  content: "\10f78a"; }

.fad.fa-chart-pie:after {
  content: "\10f200"; }

.fad.fa-chart-pie-alt:after {
  content: "\10f64e"; }

.fad.fa-chart-scatter:after {
  content: "\10f7ee"; }

.fad.fa-check:after {
  content: "\10f00c"; }

.fad.fa-check-circle:after {
  content: "\10f058"; }

.fad.fa-check-double:after {
  content: "\10f560"; }

.fad.fa-check-square:after {
  content: "\10f14a"; }

.fad.fa-cheese:after {
  content: "\10f7ef"; }

.fad.fa-cheese-swiss:after {
  content: "\10f7f0"; }

.fad.fa-cheeseburger:after {
  content: "\10f7f1"; }

.fad.fa-chess:after {
  content: "\10f439"; }

.fad.fa-chess-bishop:after {
  content: "\10f43a"; }

.fad.fa-chess-bishop-alt:after {
  content: "\10f43b"; }

.fad.fa-chess-board:after {
  content: "\10f43c"; }

.fad.fa-chess-clock:after {
  content: "\10f43d"; }

.fad.fa-chess-clock-alt:after {
  content: "\10f43e"; }

.fad.fa-chess-king:after {
  content: "\10f43f"; }

.fad.fa-chess-king-alt:after {
  content: "\10f440"; }

.fad.fa-chess-knight:after {
  content: "\10f441"; }

.fad.fa-chess-knight-alt:after {
  content: "\10f442"; }

.fad.fa-chess-pawn:after {
  content: "\10f443"; }

.fad.fa-chess-pawn-alt:after {
  content: "\10f444"; }

.fad.fa-chess-queen:after {
  content: "\10f445"; }

.fad.fa-chess-queen-alt:after {
  content: "\10f446"; }

.fad.fa-chess-rook:after {
  content: "\10f447"; }

.fad.fa-chess-rook-alt:after {
  content: "\10f448"; }

.fad.fa-chevron-circle-down:after {
  content: "\10f13a"; }

.fad.fa-chevron-circle-left:after {
  content: "\10f137"; }

.fad.fa-chevron-circle-right:after {
  content: "\10f138"; }

.fad.fa-chevron-circle-up:after {
  content: "\10f139"; }

.fad.fa-chevron-double-down:after {
  content: "\10f322"; }

.fad.fa-chevron-double-left:after {
  content: "\10f323"; }

.fad.fa-chevron-double-right:after {
  content: "\10f324"; }

.fad.fa-chevron-double-up:after {
  content: "\10f325"; }

.fad.fa-chevron-down:after {
  content: "\10f078"; }

.fad.fa-chevron-left:after {
  content: "\10f053"; }

.fad.fa-chevron-right:after {
  content: "\10f054"; }

.fad.fa-chevron-square-down:after {
  content: "\10f329"; }

.fad.fa-chevron-square-left:after {
  content: "\10f32a"; }

.fad.fa-chevron-square-right:after {
  content: "\10f32b"; }

.fad.fa-chevron-square-up:after {
  content: "\10f32c"; }

.fad.fa-chevron-up:after {
  content: "\10f077"; }

.fad.fa-child:after {
  content: "\10f1ae"; }

.fad.fa-chimney:after {
  content: "\10f78b"; }

.fad.fa-church:after {
  content: "\10f51d"; }

.fad.fa-circle:after {
  content: "\10f111"; }

.fad.fa-circle-notch:after {
  content: "\10f1ce"; }

.fad.fa-city:after {
  content: "\10f64f"; }

.fad.fa-claw-marks:after {
  content: "\10f6c2"; }

.fad.fa-clinic-medical:after {
  content: "\10f7f2"; }

.fad.fa-clipboard:after {
  content: "\10f328"; }

.fad.fa-clipboard-check:after {
  content: "\10f46c"; }

.fad.fa-clipboard-list:after {
  content: "\10f46d"; }

.fad.fa-clipboard-list-check:after {
  content: "\10f737"; }

.fad.fa-clipboard-prescription:after {
  content: "\10f5e8"; }

.fad.fa-clipboard-user:after {
  content: "\10f7f3"; }

.fad.fa-clock:after {
  content: "\10f017"; }

.fad.fa-clone:after {
  content: "\10f24d"; }

.fad.fa-closed-captioning:after {
  content: "\10f20a"; }

.fad.fa-cloud:after {
  content: "\10f0c2"; }

.fad.fa-cloud-download:after {
  content: "\10f0ed"; }

.fad.fa-cloud-download-alt:after {
  content: "\10f381"; }

.fad.fa-cloud-drizzle:after {
  content: "\10f738"; }

.fad.fa-cloud-hail:after {
  content: "\10f739"; }

.fad.fa-cloud-hail-mixed:after {
  content: "\10f73a"; }

.fad.fa-cloud-meatball:after {
  content: "\10f73b"; }

.fad.fa-cloud-moon:after {
  content: "\10f6c3"; }

.fad.fa-cloud-moon-rain:after {
  content: "\10f73c"; }

.fad.fa-cloud-rain:after {
  content: "\10f73d"; }

.fad.fa-cloud-rainbow:after {
  content: "\10f73e"; }

.fad.fa-cloud-showers:after {
  content: "\10f73f"; }

.fad.fa-cloud-showers-heavy:after {
  content: "\10f740"; }

.fad.fa-cloud-sleet:after {
  content: "\10f741"; }

.fad.fa-cloud-snow:after {
  content: "\10f742"; }

.fad.fa-cloud-sun:after {
  content: "\10f6c4"; }

.fad.fa-cloud-sun-rain:after {
  content: "\10f743"; }

.fad.fa-cloud-upload:after {
  content: "\10f0ee"; }

.fad.fa-cloud-upload-alt:after {
  content: "\10f382"; }

.fad.fa-clouds:after {
  content: "\10f744"; }

.fad.fa-clouds-moon:after {
  content: "\10f745"; }

.fad.fa-clouds-sun:after {
  content: "\10f746"; }

.fad.fa-club:after {
  content: "\10f327"; }

.fad.fa-cocktail:after {
  content: "\10f561"; }

.fad.fa-code:after {
  content: "\10f121"; }

.fad.fa-code-branch:after {
  content: "\10f126"; }

.fad.fa-code-commit:after {
  content: "\10f386"; }

.fad.fa-code-merge:after {
  content: "\10f387"; }

.fad.fa-coffee:after {
  content: "\10f0f4"; }

.fad.fa-coffee-togo:after {
  content: "\10f6c5"; }

.fad.fa-coffin:after {
  content: "\10f6c6"; }

.fad.fa-cog:after {
  content: "\10f013"; }

.fad.fa-cogs:after {
  content: "\10f085"; }

.fad.fa-coin:after {
  content: "\10f85c"; }

.fad.fa-coins:after {
  content: "\10f51e"; }

.fad.fa-columns:after {
  content: "\10f0db"; }

.fad.fa-comment:after {
  content: "\10f075"; }

.fad.fa-comment-alt:after {
  content: "\10f27a"; }

.fad.fa-comment-alt-check:after {
  content: "\10f4a2"; }

.fad.fa-comment-alt-dollar:after {
  content: "\10f650"; }

.fad.fa-comment-alt-dots:after {
  content: "\10f4a3"; }

.fad.fa-comment-alt-edit:after {
  content: "\10f4a4"; }

.fad.fa-comment-alt-exclamation:after {
  content: "\10f4a5"; }

.fad.fa-comment-alt-lines:after {
  content: "\10f4a6"; }

.fad.fa-comment-alt-medical:after {
  content: "\10f7f4"; }

.fad.fa-comment-alt-minus:after {
  content: "\10f4a7"; }

.fad.fa-comment-alt-plus:after {
  content: "\10f4a8"; }

.fad.fa-comment-alt-slash:after {
  content: "\10f4a9"; }

.fad.fa-comment-alt-smile:after {
  content: "\10f4aa"; }

.fad.fa-comment-alt-times:after {
  content: "\10f4ab"; }

.fad.fa-comment-check:after {
  content: "\10f4ac"; }

.fad.fa-comment-dollar:after {
  content: "\10f651"; }

.fad.fa-comment-dots:after {
  content: "\10f4ad"; }

.fad.fa-comment-edit:after {
  content: "\10f4ae"; }

.fad.fa-comment-exclamation:after {
  content: "\10f4af"; }

.fad.fa-comment-lines:after {
  content: "\10f4b0"; }

.fad.fa-comment-medical:after {
  content: "\10f7f5"; }

.fad.fa-comment-minus:after {
  content: "\10f4b1"; }

.fad.fa-comment-plus:after {
  content: "\10f4b2"; }

.fad.fa-comment-slash:after {
  content: "\10f4b3"; }

.fad.fa-comment-smile:after {
  content: "\10f4b4"; }

.fad.fa-comment-times:after {
  content: "\10f4b5"; }

.fad.fa-comments:after {
  content: "\10f086"; }

.fad.fa-comments-alt:after {
  content: "\10f4b6"; }

.fad.fa-comments-alt-dollar:after {
  content: "\10f652"; }

.fad.fa-comments-dollar:after {
  content: "\10f653"; }

.fad.fa-compact-disc:after {
  content: "\10f51f"; }

.fad.fa-compass:after {
  content: "\10f14e"; }

.fad.fa-compass-slash:after {
  content: "\10f5e9"; }

.fad.fa-compress:after {
  content: "\10f066"; }

.fad.fa-compress-alt:after {
  content: "\10f422"; }

.fad.fa-compress-arrows-alt:after {
  content: "\10f78c"; }

.fad.fa-compress-wide:after {
  content: "\10f326"; }

.fad.fa-concierge-bell:after {
  content: "\10f562"; }

.fad.fa-construction:after {
  content: "\10f85d"; }

.fad.fa-container-storage:after {
  content: "\10f4b7"; }

.fad.fa-conveyor-belt:after {
  content: "\10f46e"; }

.fad.fa-conveyor-belt-alt:after {
  content: "\10f46f"; }

.fad.fa-cookie:after {
  content: "\10f563"; }

.fad.fa-cookie-bite:after {
  content: "\10f564"; }

.fad.fa-copy:after {
  content: "\10f0c5"; }

.fad.fa-copyright:after {
  content: "\10f1f9"; }

.fad.fa-corn:after {
  content: "\10f6c7"; }

.fad.fa-couch:after {
  content: "\10f4b8"; }

.fad.fa-cow:after {
  content: "\10f6c8"; }

.fad.fa-credit-card:after {
  content: "\10f09d"; }

.fad.fa-credit-card-blank:after {
  content: "\10f389"; }

.fad.fa-credit-card-front:after {
  content: "\10f38a"; }

.fad.fa-cricket:after {
  content: "\10f449"; }

.fad.fa-croissant:after {
  content: "\10f7f6"; }

.fad.fa-crop:after {
  content: "\10f125"; }

.fad.fa-crop-alt:after {
  content: "\10f565"; }

.fad.fa-cross:after {
  content: "\10f654"; }

.fad.fa-crosshairs:after {
  content: "\10f05b"; }

.fad.fa-crow:after {
  content: "\10f520"; }

.fad.fa-crown:after {
  content: "\10f521"; }

.fad.fa-crutch:after {
  content: "\10f7f7"; }

.fad.fa-crutches:after {
  content: "\10f7f8"; }

.fad.fa-cube:after {
  content: "\10f1b2"; }

.fad.fa-cubes:after {
  content: "\10f1b3"; }

.fad.fa-curling:after {
  content: "\10f44a"; }

.fad.fa-cut:after {
  content: "\10f0c4"; }

.fad.fa-dagger:after {
  content: "\10f6cb"; }

.fad.fa-database:after {
  content: "\10f1c0"; }

.fad.fa-deaf:after {
  content: "\10f2a4"; }

.fad.fa-debug:after {
  content: "\10f7f9"; }

.fad.fa-deer:after {
  content: "\10f78e"; }

.fad.fa-deer-rudolph:after {
  content: "\10f78f"; }

.fad.fa-democrat:after {
  content: "\10f747"; }

.fad.fa-desktop:after {
  content: "\10f108"; }

.fad.fa-desktop-alt:after {
  content: "\10f390"; }

.fad.fa-dewpoint:after {
  content: "\10f748"; }

.fad.fa-dharmachakra:after {
  content: "\10f655"; }

.fad.fa-diagnoses:after {
  content: "\10f470"; }

.fad.fa-diamond:after {
  content: "\10f219"; }

.fad.fa-dice:after {
  content: "\10f522"; }

.fad.fa-dice-d10:after {
  content: "\10f6cd"; }

.fad.fa-dice-d12:after {
  content: "\10f6ce"; }

.fad.fa-dice-d20:after {
  content: "\10f6cf"; }

.fad.fa-dice-d4:after {
  content: "\10f6d0"; }

.fad.fa-dice-d6:after {
  content: "\10f6d1"; }

.fad.fa-dice-d8:after {
  content: "\10f6d2"; }

.fad.fa-dice-five:after {
  content: "\10f523"; }

.fad.fa-dice-four:after {
  content: "\10f524"; }

.fad.fa-dice-one:after {
  content: "\10f525"; }

.fad.fa-dice-six:after {
  content: "\10f526"; }

.fad.fa-dice-three:after {
  content: "\10f527"; }

.fad.fa-dice-two:after {
  content: "\10f528"; }

.fad.fa-digging:after {
  content: "\10f85e"; }

.fad.fa-digital-tachograph:after {
  content: "\10f566"; }

.fad.fa-diploma:after {
  content: "\10f5ea"; }

.fad.fa-directions:after {
  content: "\10f5eb"; }

.fad.fa-disease:after {
  content: "\10f7fa"; }

.fad.fa-divide:after {
  content: "\10f529"; }

.fad.fa-dizzy:after {
  content: "\10f567"; }

.fad.fa-dna:after {
  content: "\10f471"; }

.fad.fa-do-not-enter:after {
  content: "\10f5ec"; }

.fad.fa-dog:after {
  content: "\10f6d3"; }

.fad.fa-dog-leashed:after {
  content: "\10f6d4"; }

.fad.fa-dollar-sign:after {
  content: "\10f155"; }

.fad.fa-dolly:after {
  content: "\10f472"; }

.fad.fa-dolly-empty:after {
  content: "\10f473"; }

.fad.fa-dolly-flatbed:after {
  content: "\10f474"; }

.fad.fa-dolly-flatbed-alt:after {
  content: "\10f475"; }

.fad.fa-dolly-flatbed-empty:after {
  content: "\10f476"; }

.fad.fa-donate:after {
  content: "\10f4b9"; }

.fad.fa-door-closed:after {
  content: "\10f52a"; }

.fad.fa-door-open:after {
  content: "\10f52b"; }

.fad.fa-dot-circle:after {
  content: "\10f192"; }

.fad.fa-dove:after {
  content: "\10f4ba"; }

.fad.fa-download:after {
  content: "\10f019"; }

.fad.fa-drafting-compass:after {
  content: "\10f568"; }

.fad.fa-dragon:after {
  content: "\10f6d5"; }

.fad.fa-draw-circle:after {
  content: "\10f5ed"; }

.fad.fa-draw-polygon:after {
  content: "\10f5ee"; }

.fad.fa-draw-square:after {
  content: "\10f5ef"; }

.fad.fa-dreidel:after {
  content: "\10f792"; }

.fad.fa-drone:after {
  content: "\10f85f"; }

.fad.fa-drone-alt:after {
  content: "\10f860"; }

.fad.fa-drum:after {
  content: "\10f569"; }

.fad.fa-drum-steelpan:after {
  content: "\10f56a"; }

.fad.fa-drumstick:after {
  content: "\10f6d6"; }

.fad.fa-drumstick-bite:after {
  content: "\10f6d7"; }

.fad.fa-dryer:after {
  content: "\10f861"; }

.fad.fa-dryer-alt:after {
  content: "\10f862"; }

.fad.fa-duck:after {
  content: "\10f6d8"; }

.fad.fa-dumbbell:after {
  content: "\10f44b"; }

.fad.fa-dumpster:after {
  content: "\10f793"; }

.fad.fa-dumpster-fire:after {
  content: "\10f794"; }

.fad.fa-dungeon:after {
  content: "\10f6d9"; }

.fad.fa-ear:after {
  content: "\10f5f0"; }

.fad.fa-ear-muffs:after {
  content: "\10f795"; }

.fad.fa-eclipse:after {
  content: "\10f749"; }

.fad.fa-eclipse-alt:after {
  content: "\10f74a"; }

.fad.fa-edit:after {
  content: "\10f044"; }

.fad.fa-egg:after {
  content: "\10f7fb"; }

.fad.fa-egg-fried:after {
  content: "\10f7fc"; }

.fad.fa-eject:after {
  content: "\10f052"; }

.fad.fa-elephant:after {
  content: "\10f6da"; }

.fad.fa-ellipsis-h:after {
  content: "\10f141"; }

.fad.fa-ellipsis-h-alt:after {
  content: "\10f39b"; }

.fad.fa-ellipsis-v:after {
  content: "\10f142"; }

.fad.fa-ellipsis-v-alt:after {
  content: "\10f39c"; }

.fad.fa-empty-set:after {
  content: "\10f656"; }

.fad.fa-engine-warning:after {
  content: "\10f5f2"; }

.fad.fa-envelope:after {
  content: "\10f0e0"; }

.fad.fa-envelope-open:after {
  content: "\10f2b6"; }

.fad.fa-envelope-open-dollar:after {
  content: "\10f657"; }

.fad.fa-envelope-open-text:after {
  content: "\10f658"; }

.fad.fa-envelope-square:after {
  content: "\10f199"; }

.fad.fa-equals:after {
  content: "\10f52c"; }

.fad.fa-eraser:after {
  content: "\10f12d"; }

.fad.fa-ethernet:after {
  content: "\10f796"; }

.fad.fa-euro-sign:after {
  content: "\10f153"; }

.fad.fa-exchange:after {
  content: "\10f0ec"; }

.fad.fa-exchange-alt:after {
  content: "\10f362"; }

.fad.fa-exclamation:after {
  content: "\10f12a"; }

.fad.fa-exclamation-circle:after {
  content: "\10f06a"; }

.fad.fa-exclamation-square:after {
  content: "\10f321"; }

.fad.fa-exclamation-triangle:after {
  content: "\10f071"; }

.fad.fa-expand:after {
  content: "\10f065"; }

.fad.fa-expand-alt:after {
  content: "\10f424"; }

.fad.fa-expand-arrows:after {
  content: "\10f31d"; }

.fad.fa-expand-arrows-alt:after {
  content: "\10f31e"; }

.fad.fa-expand-wide:after {
  content: "\10f320"; }

.fad.fa-external-link:after {
  content: "\10f08e"; }

.fad.fa-external-link-alt:after {
  content: "\10f35d"; }

.fad.fa-external-link-square:after {
  content: "\10f14c"; }

.fad.fa-external-link-square-alt:after {
  content: "\10f360"; }

.fad.fa-eye:after {
  content: "\10f06e"; }

.fad.fa-eye-dropper:after {
  content: "\10f1fb"; }

.fad.fa-eye-evil:after {
  content: "\10f6db"; }

.fad.fa-eye-slash:after {
  content: "\10f070"; }

.fad.fa-fan:after {
  content: "\10f863"; }

.fad.fa-farm:after {
  content: "\10f864"; }

.fad.fa-fast-backward:after {
  content: "\10f049"; }

.fad.fa-fast-forward:after {
  content: "\10f050"; }

.fad.fa-fax:after {
  content: "\10f1ac"; }

.fad.fa-feather:after {
  content: "\10f52d"; }

.fad.fa-feather-alt:after {
  content: "\10f56b"; }

.fad.fa-female:after {
  content: "\10f182"; }

.fad.fa-field-hockey:after {
  content: "\10f44c"; }

.fad.fa-fighter-jet:after {
  content: "\10f0fb"; }

.fad.fa-file:after {
  content: "\10f15b"; }

.fad.fa-file-alt:after {
  content: "\10f15c"; }

.fad.fa-file-archive:after {
  content: "\10f1c6"; }

.fad.fa-file-audio:after {
  content: "\10f1c7"; }

.fad.fa-file-certificate:after {
  content: "\10f5f3"; }

.fad.fa-file-chart-line:after {
  content: "\10f659"; }

.fad.fa-file-chart-pie:after {
  content: "\10f65a"; }

.fad.fa-file-check:after {
  content: "\10f316"; }

.fad.fa-file-code:after {
  content: "\10f1c9"; }

.fad.fa-file-contract:after {
  content: "\10f56c"; }

.fad.fa-file-csv:after {
  content: "\10f6dd"; }

.fad.fa-file-download:after {
  content: "\10f56d"; }

.fad.fa-file-edit:after {
  content: "\10f31c"; }

.fad.fa-file-excel:after {
  content: "\10f1c3"; }

.fad.fa-file-exclamation:after {
  content: "\10f31a"; }

.fad.fa-file-export:after {
  content: "\10f56e"; }

.fad.fa-file-image:after {
  content: "\10f1c5"; }

.fad.fa-file-import:after {
  content: "\10f56f"; }

.fad.fa-file-invoice:after {
  content: "\10f570"; }

.fad.fa-file-invoice-dollar:after {
  content: "\10f571"; }

.fad.fa-file-medical:after {
  content: "\10f477"; }

.fad.fa-file-medical-alt:after {
  content: "\10f478"; }

.fad.fa-file-minus:after {
  content: "\10f318"; }

.fad.fa-file-pdf:after {
  content: "\10f1c1"; }

.fad.fa-file-plus:after {
  content: "\10f319"; }

.fad.fa-file-powerpoint:after {
  content: "\10f1c4"; }

.fad.fa-file-prescription:after {
  content: "\10f572"; }

.fad.fa-file-search:after {
  content: "\10f865"; }

.fad.fa-file-signature:after {
  content: "\10f573"; }

.fad.fa-file-spreadsheet:after {
  content: "\10f65b"; }

.fad.fa-file-times:after {
  content: "\10f317"; }

.fad.fa-file-upload:after {
  content: "\10f574"; }

.fad.fa-file-user:after {
  content: "\10f65c"; }

.fad.fa-file-video:after {
  content: "\10f1c8"; }

.fad.fa-file-word:after {
  content: "\10f1c2"; }

.fad.fa-files-medical:after {
  content: "\10f7fd"; }

.fad.fa-fill:after {
  content: "\10f575"; }

.fad.fa-fill-drip:after {
  content: "\10f576"; }

.fad.fa-film:after {
  content: "\10f008"; }

.fad.fa-film-alt:after {
  content: "\10f3a0"; }

.fad.fa-filter:after {
  content: "\10f0b0"; }

.fad.fa-fingerprint:after {
  content: "\10f577"; }

.fad.fa-fire:after {
  content: "\10f06d"; }

.fad.fa-fire-alt:after {
  content: "\10f7e4"; }

.fad.fa-fire-extinguisher:after {
  content: "\10f134"; }

.fad.fa-fire-smoke:after {
  content: "\10f74b"; }

.fad.fa-fireplace:after {
  content: "\10f79a"; }

.fad.fa-first-aid:after {
  content: "\10f479"; }

.fad.fa-fish:after {
  content: "\10f578"; }

.fad.fa-fish-cooked:after {
  content: "\10f7fe"; }

.fad.fa-fist-raised:after {
  content: "\10f6de"; }

.fad.fa-flag:after {
  content: "\10f024"; }

.fad.fa-flag-alt:after {
  content: "\10f74c"; }

.fad.fa-flag-checkered:after {
  content: "\10f11e"; }

.fad.fa-flag-usa:after {
  content: "\10f74d"; }

.fad.fa-flame:after {
  content: "\10f6df"; }

.fad.fa-flask:after {
  content: "\10f0c3"; }

.fad.fa-flask-poison:after {
  content: "\10f6e0"; }

.fad.fa-flask-potion:after {
  content: "\10f6e1"; }

.fad.fa-flower:after {
  content: "\10f7ff"; }

.fad.fa-flower-daffodil:after {
  content: "\10f800"; }

.fad.fa-flower-tulip:after {
  content: "\10f801"; }

.fad.fa-flushed:after {
  content: "\10f579"; }

.fad.fa-fog:after {
  content: "\10f74e"; }

.fad.fa-folder:after {
  content: "\10f07b"; }

.fad.fa-folder-minus:after {
  content: "\10f65d"; }

.fad.fa-folder-open:after {
  content: "\10f07c"; }

.fad.fa-folder-plus:after {
  content: "\10f65e"; }

.fad.fa-folder-times:after {
  content: "\10f65f"; }

.fad.fa-folder-tree:after {
  content: "\10f802"; }

.fad.fa-folders:after {
  content: "\10f660"; }

.fad.fa-font:after {
  content: "\10f031"; }

.fad.fa-font-awesome-logo-full:after {
  content: "\10f4e6"; }

.fad.fa-font-case:after {
  content: "\10f866"; }

.fad.fa-football-ball:after {
  content: "\10f44e"; }

.fad.fa-football-helmet:after {
  content: "\10f44f"; }

.fad.fa-forklift:after {
  content: "\10f47a"; }

.fad.fa-forward:after {
  content: "\10f04e"; }

.fad.fa-fragile:after {
  content: "\10f4bb"; }

.fad.fa-french-fries:after {
  content: "\10f803"; }

.fad.fa-frog:after {
  content: "\10f52e"; }

.fad.fa-frosty-head:after {
  content: "\10f79b"; }

.fad.fa-frown:after {
  content: "\10f119"; }

.fad.fa-frown-open:after {
  content: "\10f57a"; }

.fad.fa-function:after {
  content: "\10f661"; }

.fad.fa-funnel-dollar:after {
  content: "\10f662"; }

.fad.fa-futbol:after {
  content: "\10f1e3"; }

.fad.fa-game-board:after {
  content: "\10f867"; }

.fad.fa-game-board-alt:after {
  content: "\10f868"; }

.fad.fa-gamepad:after {
  content: "\10f11b"; }

.fad.fa-gas-pump:after {
  content: "\10f52f"; }

.fad.fa-gas-pump-slash:after {
  content: "\10f5f4"; }

.fad.fa-gavel:after {
  content: "\10f0e3"; }

.fad.fa-gem:after {
  content: "\10f3a5"; }

.fad.fa-genderless:after {
  content: "\10f22d"; }

.fad.fa-ghost:after {
  content: "\10f6e2"; }

.fad.fa-gift:after {
  content: "\10f06b"; }

.fad.fa-gift-card:after {
  content: "\10f663"; }

.fad.fa-gifts:after {
  content: "\10f79c"; }

.fad.fa-gingerbread-man:after {
  content: "\10f79d"; }

.fad.fa-glass:after {
  content: "\10f804"; }

.fad.fa-glass-champagne:after {
  content: "\10f79e"; }

.fad.fa-glass-cheers:after {
  content: "\10f79f"; }

.fad.fa-glass-citrus:after {
  content: "\10f869"; }

.fad.fa-glass-martini:after {
  content: "\10f000"; }

.fad.fa-glass-martini-alt:after {
  content: "\10f57b"; }

.fad.fa-glass-whiskey:after {
  content: "\10f7a0"; }

.fad.fa-glass-whiskey-rocks:after {
  content: "\10f7a1"; }

.fad.fa-glasses:after {
  content: "\10f530"; }

.fad.fa-glasses-alt:after {
  content: "\10f5f5"; }

.fad.fa-globe:after {
  content: "\10f0ac"; }

.fad.fa-globe-africa:after {
  content: "\10f57c"; }

.fad.fa-globe-americas:after {
  content: "\10f57d"; }

.fad.fa-globe-asia:after {
  content: "\10f57e"; }

.fad.fa-globe-europe:after {
  content: "\10f7a2"; }

.fad.fa-globe-snow:after {
  content: "\10f7a3"; }

.fad.fa-globe-stand:after {
  content: "\10f5f6"; }

.fad.fa-golf-ball:after {
  content: "\10f450"; }

.fad.fa-golf-club:after {
  content: "\10f451"; }

.fad.fa-gopuram:after {
  content: "\10f664"; }

.fad.fa-graduation-cap:after {
  content: "\10f19d"; }

.fad.fa-greater-than:after {
  content: "\10f531"; }

.fad.fa-greater-than-equal:after {
  content: "\10f532"; }

.fad.fa-grimace:after {
  content: "\10f57f"; }

.fad.fa-grin:after {
  content: "\10f580"; }

.fad.fa-grin-alt:after {
  content: "\10f581"; }

.fad.fa-grin-beam:after {
  content: "\10f582"; }

.fad.fa-grin-beam-sweat:after {
  content: "\10f583"; }

.fad.fa-grin-hearts:after {
  content: "\10f584"; }

.fad.fa-grin-squint:after {
  content: "\10f585"; }

.fad.fa-grin-squint-tears:after {
  content: "\10f586"; }

.fad.fa-grin-stars:after {
  content: "\10f587"; }

.fad.fa-grin-tears:after {
  content: "\10f588"; }

.fad.fa-grin-tongue:after {
  content: "\10f589"; }

.fad.fa-grin-tongue-squint:after {
  content: "\10f58a"; }

.fad.fa-grin-tongue-wink:after {
  content: "\10f58b"; }

.fad.fa-grin-wink:after {
  content: "\10f58c"; }

.fad.fa-grip-horizontal:after {
  content: "\10f58d"; }

.fad.fa-grip-lines:after {
  content: "\10f7a4"; }

.fad.fa-grip-lines-vertical:after {
  content: "\10f7a5"; }

.fad.fa-grip-vertical:after {
  content: "\10f58e"; }

.fad.fa-guitar:after {
  content: "\10f7a6"; }

.fad.fa-h-square:after {
  content: "\10f0fd"; }

.fad.fa-h1:after {
  content: "\10f313"; }

.fad.fa-h2:after {
  content: "\10f314"; }

.fad.fa-h3:after {
  content: "\10f315"; }

.fad.fa-h4:after {
  content: "\10f86a"; }

.fad.fa-hamburger:after {
  content: "\10f805"; }

.fad.fa-hammer:after {
  content: "\10f6e3"; }

.fad.fa-hammer-war:after {
  content: "\10f6e4"; }

.fad.fa-hamsa:after {
  content: "\10f665"; }

.fad.fa-hand-heart:after {
  content: "\10f4bc"; }

.fad.fa-hand-holding:after {
  content: "\10f4bd"; }

.fad.fa-hand-holding-box:after {
  content: "\10f47b"; }

.fad.fa-hand-holding-heart:after {
  content: "\10f4be"; }

.fad.fa-hand-holding-magic:after {
  content: "\10f6e5"; }

.fad.fa-hand-holding-seedling:after {
  content: "\10f4bf"; }

.fad.fa-hand-holding-usd:after {
  content: "\10f4c0"; }

.fad.fa-hand-holding-water:after {
  content: "\10f4c1"; }

.fad.fa-hand-lizard:after {
  content: "\10f258"; }

.fad.fa-hand-middle-finger:after {
  content: "\10f806"; }

.fad.fa-hand-paper:after {
  content: "\10f256"; }

.fad.fa-hand-peace:after {
  content: "\10f25b"; }

.fad.fa-hand-point-down:after {
  content: "\10f0a7"; }

.fad.fa-hand-point-left:after {
  content: "\10f0a5"; }

.fad.fa-hand-point-right:after {
  content: "\10f0a4"; }

.fad.fa-hand-point-up:after {
  content: "\10f0a6"; }

.fad.fa-hand-pointer:after {
  content: "\10f25a"; }

.fad.fa-hand-receiving:after {
  content: "\10f47c"; }

.fad.fa-hand-rock:after {
  content: "\10f255"; }

.fad.fa-hand-scissors:after {
  content: "\10f257"; }

.fad.fa-hand-spock:after {
  content: "\10f259"; }

.fad.fa-hands:after {
  content: "\10f4c2"; }

.fad.fa-hands-heart:after {
  content: "\10f4c3"; }

.fad.fa-hands-helping:after {
  content: "\10f4c4"; }

.fad.fa-hands-usd:after {
  content: "\10f4c5"; }

.fad.fa-handshake:after {
  content: "\10f2b5"; }

.fad.fa-handshake-alt:after {
  content: "\10f4c6"; }

.fad.fa-hanukiah:after {
  content: "\10f6e6"; }

.fad.fa-hard-hat:after {
  content: "\10f807"; }

.fad.fa-hashtag:after {
  content: "\10f292"; }

.fad.fa-hat-chef:after {
  content: "\10f86b"; }

.fad.fa-hat-santa:after {
  content: "\10f7a7"; }

.fad.fa-hat-winter:after {
  content: "\10f7a8"; }

.fad.fa-hat-witch:after {
  content: "\10f6e7"; }

.fad.fa-hat-wizard:after {
  content: "\10f6e8"; }

.fad.fa-haykal:after {
  content: "\10f666"; }

.fad.fa-hdd:after {
  content: "\10f0a0"; }

.fad.fa-head-side:after {
  content: "\10f6e9"; }

.fad.fa-head-side-brain:after {
  content: "\10f808"; }

.fad.fa-head-side-medical:after {
  content: "\10f809"; }

.fad.fa-head-vr:after {
  content: "\10f6ea"; }

.fad.fa-heading:after {
  content: "\10f1dc"; }

.fad.fa-headphones:after {
  content: "\10f025"; }

.fad.fa-headphones-alt:after {
  content: "\10f58f"; }

.fad.fa-headset:after {
  content: "\10f590"; }

.fad.fa-heart:after {
  content: "\10f004"; }

.fad.fa-heart-broken:after {
  content: "\10f7a9"; }

.fad.fa-heart-circle:after {
  content: "\10f4c7"; }

.fad.fa-heart-rate:after {
  content: "\10f5f8"; }

.fad.fa-heart-square:after {
  content: "\10f4c8"; }

.fad.fa-heartbeat:after {
  content: "\10f21e"; }

.fad.fa-helicopter:after {
  content: "\10f533"; }

.fad.fa-helmet-battle:after {
  content: "\10f6eb"; }

.fad.fa-hexagon:after {
  content: "\10f312"; }

.fad.fa-highlighter:after {
  content: "\10f591"; }

.fad.fa-hiking:after {
  content: "\10f6ec"; }

.fad.fa-hippo:after {
  content: "\10f6ed"; }

.fad.fa-history:after {
  content: "\10f1da"; }

.fad.fa-hockey-mask:after {
  content: "\10f6ee"; }

.fad.fa-hockey-puck:after {
  content: "\10f453"; }

.fad.fa-hockey-sticks:after {
  content: "\10f454"; }

.fad.fa-holly-berry:after {
  content: "\10f7aa"; }

.fad.fa-home:after {
  content: "\10f015"; }

.fad.fa-home-alt:after {
  content: "\10f80a"; }

.fad.fa-home-heart:after {
  content: "\10f4c9"; }

.fad.fa-home-lg:after {
  content: "\10f80b"; }

.fad.fa-home-lg-alt:after {
  content: "\10f80c"; }

.fad.fa-hood-cloak:after {
  content: "\10f6ef"; }

.fad.fa-horizontal-rule:after {
  content: "\10f86c"; }

.fad.fa-horse:after {
  content: "\10f6f0"; }

.fad.fa-horse-head:after {
  content: "\10f7ab"; }

.fad.fa-hospital:after {
  content: "\10f0f8"; }

.fad.fa-hospital-alt:after {
  content: "\10f47d"; }

.fad.fa-hospital-symbol:after {
  content: "\10f47e"; }

.fad.fa-hospital-user:after {
  content: "\10f80d"; }

.fad.fa-hospitals:after {
  content: "\10f80e"; }

.fad.fa-hot-tub:after {
  content: "\10f593"; }

.fad.fa-hotdog:after {
  content: "\10f80f"; }

.fad.fa-hotel:after {
  content: "\10f594"; }

.fad.fa-hourglass:after {
  content: "\10f254"; }

.fad.fa-hourglass-end:after {
  content: "\10f253"; }

.fad.fa-hourglass-half:after {
  content: "\10f252"; }

.fad.fa-hourglass-start:after {
  content: "\10f251"; }

.fad.fa-house-damage:after {
  content: "\10f6f1"; }

.fad.fa-house-flood:after {
  content: "\10f74f"; }

.fad.fa-hryvnia:after {
  content: "\10f6f2"; }

.fad.fa-humidity:after {
  content: "\10f750"; }

.fad.fa-hurricane:after {
  content: "\10f751"; }

.fad.fa-i-cursor:after {
  content: "\10f246"; }

.fad.fa-ice-cream:after {
  content: "\10f810"; }

.fad.fa-ice-skate:after {
  content: "\10f7ac"; }

.fad.fa-icicles:after {
  content: "\10f7ad"; }

.fad.fa-icons:after {
  content: "\10f86d"; }

.fad.fa-icons-alt:after {
  content: "\10f86e"; }

.fad.fa-id-badge:after {
  content: "\10f2c1"; }

.fad.fa-id-card:after {
  content: "\10f2c2"; }

.fad.fa-id-card-alt:after {
  content: "\10f47f"; }

.fad.fa-igloo:after {
  content: "\10f7ae"; }

.fad.fa-image:after {
  content: "\10f03e"; }

.fad.fa-images:after {
  content: "\10f302"; }

.fad.fa-inbox:after {
  content: "\10f01c"; }

.fad.fa-inbox-in:after {
  content: "\10f310"; }

.fad.fa-inbox-out:after {
  content: "\10f311"; }

.fad.fa-indent:after {
  content: "\10f03c"; }

.fad.fa-industry:after {
  content: "\10f275"; }

.fad.fa-industry-alt:after {
  content: "\10f3b3"; }

.fad.fa-infinity:after {
  content: "\10f534"; }

.fad.fa-info:after {
  content: "\10f129"; }

.fad.fa-info-circle:after {
  content: "\10f05a"; }

.fad.fa-info-square:after {
  content: "\10f30f"; }

.fad.fa-inhaler:after {
  content: "\10f5f9"; }

.fad.fa-integral:after {
  content: "\10f667"; }

.fad.fa-intersection:after {
  content: "\10f668"; }

.fad.fa-inventory:after {
  content: "\10f480"; }

.fad.fa-island-tropical:after {
  content: "\10f811"; }

.fad.fa-italic:after {
  content: "\10f033"; }

.fad.fa-jack-o-lantern:after {
  content: "\10f30e"; }

.fad.fa-jedi:after {
  content: "\10f669"; }

.fad.fa-joint:after {
  content: "\10f595"; }

.fad.fa-journal-whills:after {
  content: "\10f66a"; }

.fad.fa-kaaba:after {
  content: "\10f66b"; }

.fad.fa-kerning:after {
  content: "\10f86f"; }

.fad.fa-key:after {
  content: "\10f084"; }

.fad.fa-key-skeleton:after {
  content: "\10f6f3"; }

.fad.fa-keyboard:after {
  content: "\10f11c"; }

.fad.fa-keynote:after {
  content: "\10f66c"; }

.fad.fa-khanda:after {
  content: "\10f66d"; }

.fad.fa-kidneys:after {
  content: "\10f5fb"; }

.fad.fa-kiss:after {
  content: "\10f596"; }

.fad.fa-kiss-beam:after {
  content: "\10f597"; }

.fad.fa-kiss-wink-heart:after {
  content: "\10f598"; }

.fad.fa-kite:after {
  content: "\10f6f4"; }

.fad.fa-kiwi-bird:after {
  content: "\10f535"; }

.fad.fa-knife-kitchen:after {
  content: "\10f6f5"; }

.fad.fa-lambda:after {
  content: "\10f66e"; }

.fad.fa-lamp:after {
  content: "\10f4ca"; }

.fad.fa-landmark:after {
  content: "\10f66f"; }

.fad.fa-landmark-alt:after {
  content: "\10f752"; }

.fad.fa-language:after {
  content: "\10f1ab"; }

.fad.fa-laptop:after {
  content: "\10f109"; }

.fad.fa-laptop-code:after {
  content: "\10f5fc"; }

.fad.fa-laptop-medical:after {
  content: "\10f812"; }

.fad.fa-laugh:after {
  content: "\10f599"; }

.fad.fa-laugh-beam:after {
  content: "\10f59a"; }

.fad.fa-laugh-squint:after {
  content: "\10f59b"; }

.fad.fa-laugh-wink:after {
  content: "\10f59c"; }

.fad.fa-layer-group:after {
  content: "\10f5fd"; }

.fad.fa-layer-minus:after {
  content: "\10f5fe"; }

.fad.fa-layer-plus:after {
  content: "\10f5ff"; }

.fad.fa-leaf:after {
  content: "\10f06c"; }

.fad.fa-leaf-heart:after {
  content: "\10f4cb"; }

.fad.fa-leaf-maple:after {
  content: "\10f6f6"; }

.fad.fa-leaf-oak:after {
  content: "\10f6f7"; }

.fad.fa-lemon:after {
  content: "\10f094"; }

.fad.fa-less-than:after {
  content: "\10f536"; }

.fad.fa-less-than-equal:after {
  content: "\10f537"; }

.fad.fa-level-down:after {
  content: "\10f149"; }

.fad.fa-level-down-alt:after {
  content: "\10f3be"; }

.fad.fa-level-up:after {
  content: "\10f148"; }

.fad.fa-level-up-alt:after {
  content: "\10f3bf"; }

.fad.fa-life-ring:after {
  content: "\10f1cd"; }

.fad.fa-lightbulb:after {
  content: "\10f0eb"; }

.fad.fa-lightbulb-dollar:after {
  content: "\10f670"; }

.fad.fa-lightbulb-exclamation:after {
  content: "\10f671"; }

.fad.fa-lightbulb-on:after {
  content: "\10f672"; }

.fad.fa-lightbulb-slash:after {
  content: "\10f673"; }

.fad.fa-lights-holiday:after {
  content: "\10f7b2"; }

.fad.fa-line-columns:after {
  content: "\10f870"; }

.fad.fa-line-height:after {
  content: "\10f871"; }

.fad.fa-link:after {
  content: "\10f0c1"; }

.fad.fa-lips:after {
  content: "\10f600"; }

.fad.fa-lira-sign:after {
  content: "\10f195"; }

.fad.fa-list:after {
  content: "\10f03a"; }

.fad.fa-list-alt:after {
  content: "\10f022"; }

.fad.fa-list-ol:after {
  content: "\10f0cb"; }

.fad.fa-list-ul:after {
  content: "\10f0ca"; }

.fad.fa-location:after {
  content: "\10f601"; }

.fad.fa-location-arrow:after {
  content: "\10f124"; }

.fad.fa-location-circle:after {
  content: "\10f602"; }

.fad.fa-location-slash:after {
  content: "\10f603"; }

.fad.fa-lock:after {
  content: "\10f023"; }

.fad.fa-lock-alt:after {
  content: "\10f30d"; }

.fad.fa-lock-open:after {
  content: "\10f3c1"; }

.fad.fa-lock-open-alt:after {
  content: "\10f3c2"; }

.fad.fa-long-arrow-alt-down:after {
  content: "\10f309"; }

.fad.fa-long-arrow-alt-left:after {
  content: "\10f30a"; }

.fad.fa-long-arrow-alt-right:after {
  content: "\10f30b"; }

.fad.fa-long-arrow-alt-up:after {
  content: "\10f30c"; }

.fad.fa-long-arrow-down:after {
  content: "\10f175"; }

.fad.fa-long-arrow-left:after {
  content: "\10f177"; }

.fad.fa-long-arrow-right:after {
  content: "\10f178"; }

.fad.fa-long-arrow-up:after {
  content: "\10f176"; }

.fad.fa-loveseat:after {
  content: "\10f4cc"; }

.fad.fa-low-vision:after {
  content: "\10f2a8"; }

.fad.fa-luchador:after {
  content: "\10f455"; }

.fad.fa-luggage-cart:after {
  content: "\10f59d"; }

.fad.fa-lungs:after {
  content: "\10f604"; }

.fad.fa-mace:after {
  content: "\10f6f8"; }

.fad.fa-magic:after {
  content: "\10f0d0"; }

.fad.fa-magnet:after {
  content: "\10f076"; }

.fad.fa-mail-bulk:after {
  content: "\10f674"; }

.fad.fa-mailbox:after {
  content: "\10f813"; }

.fad.fa-male:after {
  content: "\10f183"; }

.fad.fa-mandolin:after {
  content: "\10f6f9"; }

.fad.fa-map:after {
  content: "\10f279"; }

.fad.fa-map-marked:after {
  content: "\10f59f"; }

.fad.fa-map-marked-alt:after {
  content: "\10f5a0"; }

.fad.fa-map-marker:after {
  content: "\10f041"; }

.fad.fa-map-marker-alt:after {
  content: "\10f3c5"; }

.fad.fa-map-marker-alt-slash:after {
  content: "\10f605"; }

.fad.fa-map-marker-check:after {
  content: "\10f606"; }

.fad.fa-map-marker-edit:after {
  content: "\10f607"; }

.fad.fa-map-marker-exclamation:after {
  content: "\10f608"; }

.fad.fa-map-marker-minus:after {
  content: "\10f609"; }

.fad.fa-map-marker-plus:after {
  content: "\10f60a"; }

.fad.fa-map-marker-question:after {
  content: "\10f60b"; }

.fad.fa-map-marker-slash:after {
  content: "\10f60c"; }

.fad.fa-map-marker-smile:after {
  content: "\10f60d"; }

.fad.fa-map-marker-times:after {
  content: "\10f60e"; }

.fad.fa-map-pin:after {
  content: "\10f276"; }

.fad.fa-map-signs:after {
  content: "\10f277"; }

.fad.fa-marker:after {
  content: "\10f5a1"; }

.fad.fa-mars:after {
  content: "\10f222"; }

.fad.fa-mars-double:after {
  content: "\10f227"; }

.fad.fa-mars-stroke:after {
  content: "\10f229"; }

.fad.fa-mars-stroke-h:after {
  content: "\10f22b"; }

.fad.fa-mars-stroke-v:after {
  content: "\10f22a"; }

.fad.fa-mask:after {
  content: "\10f6fa"; }

.fad.fa-meat:after {
  content: "\10f814"; }

.fad.fa-medal:after {
  content: "\10f5a2"; }

.fad.fa-medkit:after {
  content: "\10f0fa"; }

.fad.fa-megaphone:after {
  content: "\10f675"; }

.fad.fa-meh:after {
  content: "\10f11a"; }

.fad.fa-meh-blank:after {
  content: "\10f5a4"; }

.fad.fa-meh-rolling-eyes:after {
  content: "\10f5a5"; }

.fad.fa-memory:after {
  content: "\10f538"; }

.fad.fa-menorah:after {
  content: "\10f676"; }

.fad.fa-mercury:after {
  content: "\10f223"; }

.fad.fa-meteor:after {
  content: "\10f753"; }

.fad.fa-microchip:after {
  content: "\10f2db"; }

.fad.fa-microphone:after {
  content: "\10f130"; }

.fad.fa-microphone-alt:after {
  content: "\10f3c9"; }

.fad.fa-microphone-alt-slash:after {
  content: "\10f539"; }

.fad.fa-microphone-slash:after {
  content: "\10f131"; }

.fad.fa-microscope:after {
  content: "\10f610"; }

.fad.fa-mind-share:after {
  content: "\10f677"; }

.fad.fa-minus:after {
  content: "\10f068"; }

.fad.fa-minus-circle:after {
  content: "\10f056"; }

.fad.fa-minus-hexagon:after {
  content: "\10f307"; }

.fad.fa-minus-octagon:after {
  content: "\10f308"; }

.fad.fa-minus-square:after {
  content: "\10f146"; }

.fad.fa-mistletoe:after {
  content: "\10f7b4"; }

.fad.fa-mitten:after {
  content: "\10f7b5"; }

.fad.fa-mobile:after {
  content: "\10f10b"; }

.fad.fa-mobile-alt:after {
  content: "\10f3cd"; }

.fad.fa-mobile-android:after {
  content: "\10f3ce"; }

.fad.fa-mobile-android-alt:after {
  content: "\10f3cf"; }

.fad.fa-money-bill:after {
  content: "\10f0d6"; }

.fad.fa-money-bill-alt:after {
  content: "\10f3d1"; }

.fad.fa-money-bill-wave:after {
  content: "\10f53a"; }

.fad.fa-money-bill-wave-alt:after {
  content: "\10f53b"; }

.fad.fa-money-check:after {
  content: "\10f53c"; }

.fad.fa-money-check-alt:after {
  content: "\10f53d"; }

.fad.fa-money-check-edit:after {
  content: "\10f872"; }

.fad.fa-money-check-edit-alt:after {
  content: "\10f873"; }

.fad.fa-monitor-heart-rate:after {
  content: "\10f611"; }

.fad.fa-monkey:after {
  content: "\10f6fb"; }

.fad.fa-monument:after {
  content: "\10f5a6"; }

.fad.fa-moon:after {
  content: "\10f186"; }

.fad.fa-moon-cloud:after {
  content: "\10f754"; }

.fad.fa-moon-stars:after {
  content: "\10f755"; }

.fad.fa-mortar-pestle:after {
  content: "\10f5a7"; }

.fad.fa-mosque:after {
  content: "\10f678"; }

.fad.fa-motorcycle:after {
  content: "\10f21c"; }

.fad.fa-mountain:after {
  content: "\10f6fc"; }

.fad.fa-mountains:after {
  content: "\10f6fd"; }

.fad.fa-mouse-pointer:after {
  content: "\10f245"; }

.fad.fa-mug:after {
  content: "\10f874"; }

.fad.fa-mug-hot:after {
  content: "\10f7b6"; }

.fad.fa-mug-marshmallows:after {
  content: "\10f7b7"; }

.fad.fa-mug-tea:after {
  content: "\10f875"; }

.fad.fa-music:after {
  content: "\10f001"; }

.fad.fa-narwhal:after {
  content: "\10f6fe"; }

.fad.fa-network-wired:after {
  content: "\10f6ff"; }

.fad.fa-neuter:after {
  content: "\10f22c"; }

.fad.fa-newspaper:after {
  content: "\10f1ea"; }

.fad.fa-not-equal:after {
  content: "\10f53e"; }

.fad.fa-notes-medical:after {
  content: "\10f481"; }

.fad.fa-object-group:after {
  content: "\10f247"; }

.fad.fa-object-ungroup:after {
  content: "\10f248"; }

.fad.fa-octagon:after {
  content: "\10f306"; }

.fad.fa-oil-can:after {
  content: "\10f613"; }

.fad.fa-oil-temp:after {
  content: "\10f614"; }

.fad.fa-om:after {
  content: "\10f679"; }

.fad.fa-omega:after {
  content: "\10f67a"; }

.fad.fa-ornament:after {
  content: "\10f7b8"; }

.fad.fa-otter:after {
  content: "\10f700"; }

.fad.fa-outdent:after {
  content: "\10f03b"; }

.fad.fa-overline:after {
  content: "\10f876"; }

.fad.fa-page-break:after {
  content: "\10f877"; }

.fad.fa-pager:after {
  content: "\10f815"; }

.fad.fa-paint-brush:after {
  content: "\10f1fc"; }

.fad.fa-paint-brush-alt:after {
  content: "\10f5a9"; }

.fad.fa-paint-roller:after {
  content: "\10f5aa"; }

.fad.fa-palette:after {
  content: "\10f53f"; }

.fad.fa-pallet:after {
  content: "\10f482"; }

.fad.fa-pallet-alt:after {
  content: "\10f483"; }

.fad.fa-paper-plane:after {
  content: "\10f1d8"; }

.fad.fa-paperclip:after {
  content: "\10f0c6"; }

.fad.fa-parachute-box:after {
  content: "\10f4cd"; }

.fad.fa-paragraph:after {
  content: "\10f1dd"; }

.fad.fa-paragraph-rtl:after {
  content: "\10f878"; }

.fad.fa-parking:after {
  content: "\10f540"; }

.fad.fa-parking-circle:after {
  content: "\10f615"; }

.fad.fa-parking-circle-slash:after {
  content: "\10f616"; }

.fad.fa-parking-slash:after {
  content: "\10f617"; }

.fad.fa-passport:after {
  content: "\10f5ab"; }

.fad.fa-pastafarianism:after {
  content: "\10f67b"; }

.fad.fa-paste:after {
  content: "\10f0ea"; }

.fad.fa-pause:after {
  content: "\10f04c"; }

.fad.fa-pause-circle:after {
  content: "\10f28b"; }

.fad.fa-paw:after {
  content: "\10f1b0"; }

.fad.fa-paw-alt:after {
  content: "\10f701"; }

.fad.fa-paw-claws:after {
  content: "\10f702"; }

.fad.fa-peace:after {
  content: "\10f67c"; }

.fad.fa-pegasus:after {
  content: "\10f703"; }

.fad.fa-pen:after {
  content: "\10f304"; }

.fad.fa-pen-alt:after {
  content: "\10f305"; }

.fad.fa-pen-fancy:after {
  content: "\10f5ac"; }

.fad.fa-pen-nib:after {
  content: "\10f5ad"; }

.fad.fa-pen-square:after {
  content: "\10f14b"; }

.fad.fa-pencil:after {
  content: "\10f040"; }

.fad.fa-pencil-alt:after {
  content: "\10f303"; }

.fad.fa-pencil-paintbrush:after {
  content: "\10f618"; }

.fad.fa-pencil-ruler:after {
  content: "\10f5ae"; }

.fad.fa-pennant:after {
  content: "\10f456"; }

.fad.fa-people-carry:after {
  content: "\10f4ce"; }

.fad.fa-pepper-hot:after {
  content: "\10f816"; }

.fad.fa-percent:after {
  content: "\10f295"; }

.fad.fa-percentage:after {
  content: "\10f541"; }

.fad.fa-person-booth:after {
  content: "\10f756"; }

.fad.fa-person-carry:after {
  content: "\10f4cf"; }

.fad.fa-person-dolly:after {
  content: "\10f4d0"; }

.fad.fa-person-dolly-empty:after {
  content: "\10f4d1"; }

.fad.fa-person-sign:after {
  content: "\10f757"; }

.fad.fa-phone:after {
  content: "\10f095"; }

.fad.fa-phone-alt:after {
  content: "\10f879"; }

.fad.fa-phone-laptop:after {
  content: "\10f87a"; }

.fad.fa-phone-office:after {
  content: "\10f67d"; }

.fad.fa-phone-plus:after {
  content: "\10f4d2"; }

.fad.fa-phone-slash:after {
  content: "\10f3dd"; }

.fad.fa-phone-square:after {
  content: "\10f098"; }

.fad.fa-phone-square-alt:after {
  content: "\10f87b"; }

.fad.fa-phone-volume:after {
  content: "\10f2a0"; }

.fad.fa-photo-video:after {
  content: "\10f87c"; }

.fad.fa-pi:after {
  content: "\10f67e"; }

.fad.fa-pie:after {
  content: "\10f705"; }

.fad.fa-pig:after {
  content: "\10f706"; }

.fad.fa-piggy-bank:after {
  content: "\10f4d3"; }

.fad.fa-pills:after {
  content: "\10f484"; }

.fad.fa-pizza:after {
  content: "\10f817"; }

.fad.fa-pizza-slice:after {
  content: "\10f818"; }

.fad.fa-place-of-worship:after {
  content: "\10f67f"; }

.fad.fa-plane:after {
  content: "\10f072"; }

.fad.fa-plane-alt:after {
  content: "\10f3de"; }

.fad.fa-plane-arrival:after {
  content: "\10f5af"; }

.fad.fa-plane-departure:after {
  content: "\10f5b0"; }

.fad.fa-play:after {
  content: "\10f04b"; }

.fad.fa-play-circle:after {
  content: "\10f144"; }

.fad.fa-plug:after {
  content: "\10f1e6"; }

.fad.fa-plus:after {
  content: "\10f067"; }

.fad.fa-plus-circle:after {
  content: "\10f055"; }

.fad.fa-plus-hexagon:after {
  content: "\10f300"; }

.fad.fa-plus-octagon:after {
  content: "\10f301"; }

.fad.fa-plus-square:after {
  content: "\10f0fe"; }

.fad.fa-podcast:after {
  content: "\10f2ce"; }

.fad.fa-podium:after {
  content: "\10f680"; }

.fad.fa-podium-star:after {
  content: "\10f758"; }

.fad.fa-poll:after {
  content: "\10f681"; }

.fad.fa-poll-h:after {
  content: "\10f682"; }

.fad.fa-poll-people:after {
  content: "\10f759"; }

.fad.fa-poo:after {
  content: "\10f2fe"; }

.fad.fa-poo-storm:after {
  content: "\10f75a"; }

.fad.fa-poop:after {
  content: "\10f619"; }

.fad.fa-popcorn:after {
  content: "\10f819"; }

.fad.fa-portrait:after {
  content: "\10f3e0"; }

.fad.fa-pound-sign:after {
  content: "\10f154"; }

.fad.fa-power-off:after {
  content: "\10f011"; }

.fad.fa-pray:after {
  content: "\10f683"; }

.fad.fa-praying-hands:after {
  content: "\10f684"; }

.fad.fa-prescription:after {
  content: "\10f5b1"; }

.fad.fa-prescription-bottle:after {
  content: "\10f485"; }

.fad.fa-prescription-bottle-alt:after {
  content: "\10f486"; }

.fad.fa-presentation:after {
  content: "\10f685"; }

.fad.fa-print:after {
  content: "\10f02f"; }

.fad.fa-print-search:after {
  content: "\10f81a"; }

.fad.fa-print-slash:after {
  content: "\10f686"; }

.fad.fa-procedures:after {
  content: "\10f487"; }

.fad.fa-project-diagram:after {
  content: "\10f542"; }

.fad.fa-pumpkin:after {
  content: "\10f707"; }

.fad.fa-puzzle-piece:after {
  content: "\10f12e"; }

.fad.fa-qrcode:after {
  content: "\10f029"; }

.fad.fa-question:after {
  content: "\10f128"; }

.fad.fa-question-circle:after {
  content: "\10f059"; }

.fad.fa-question-square:after {
  content: "\10f2fd"; }

.fad.fa-quidditch:after {
  content: "\10f458"; }

.fad.fa-quote-left:after {
  content: "\10f10d"; }

.fad.fa-quote-right:after {
  content: "\10f10e"; }

.fad.fa-quran:after {
  content: "\10f687"; }

.fad.fa-rabbit:after {
  content: "\10f708"; }

.fad.fa-rabbit-fast:after {
  content: "\10f709"; }

.fad.fa-racquet:after {
  content: "\10f45a"; }

.fad.fa-radiation:after {
  content: "\10f7b9"; }

.fad.fa-radiation-alt:after {
  content: "\10f7ba"; }

.fad.fa-rainbow:after {
  content: "\10f75b"; }

.fad.fa-raindrops:after {
  content: "\10f75c"; }

.fad.fa-ram:after {
  content: "\10f70a"; }

.fad.fa-ramp-loading:after {
  content: "\10f4d4"; }

.fad.fa-random:after {
  content: "\10f074"; }

.fad.fa-receipt:after {
  content: "\10f543"; }

.fad.fa-rectangle-landscape:after {
  content: "\10f2fa"; }

.fad.fa-rectangle-portrait:after {
  content: "\10f2fb"; }

.fad.fa-rectangle-wide:after {
  content: "\10f2fc"; }

.fad.fa-recycle:after {
  content: "\10f1b8"; }

.fad.fa-redo:after {
  content: "\10f01e"; }

.fad.fa-redo-alt:after {
  content: "\10f2f9"; }

.fad.fa-registered:after {
  content: "\10f25d"; }

.fad.fa-remove-format:after {
  content: "\10f87d"; }

.fad.fa-repeat:after {
  content: "\10f363"; }

.fad.fa-repeat-1:after {
  content: "\10f365"; }

.fad.fa-repeat-1-alt:after {
  content: "\10f366"; }

.fad.fa-repeat-alt:after {
  content: "\10f364"; }

.fad.fa-reply:after {
  content: "\10f3e5"; }

.fad.fa-reply-all:after {
  content: "\10f122"; }

.fad.fa-republican:after {
  content: "\10f75e"; }

.fad.fa-restroom:after {
  content: "\10f7bd"; }

.fad.fa-retweet:after {
  content: "\10f079"; }

.fad.fa-retweet-alt:after {
  content: "\10f361"; }

.fad.fa-ribbon:after {
  content: "\10f4d6"; }

.fad.fa-ring:after {
  content: "\10f70b"; }

.fad.fa-rings-wedding:after {
  content: "\10f81b"; }

.fad.fa-road:after {
  content: "\10f018"; }

.fad.fa-robot:after {
  content: "\10f544"; }

.fad.fa-rocket:after {
  content: "\10f135"; }

.fad.fa-route:after {
  content: "\10f4d7"; }

.fad.fa-route-highway:after {
  content: "\10f61a"; }

.fad.fa-route-interstate:after {
  content: "\10f61b"; }

.fad.fa-rss:after {
  content: "\10f09e"; }

.fad.fa-rss-square:after {
  content: "\10f143"; }

.fad.fa-ruble-sign:after {
  content: "\10f158"; }

.fad.fa-ruler:after {
  content: "\10f545"; }

.fad.fa-ruler-combined:after {
  content: "\10f546"; }

.fad.fa-ruler-horizontal:after {
  content: "\10f547"; }

.fad.fa-ruler-triangle:after {
  content: "\10f61c"; }

.fad.fa-ruler-vertical:after {
  content: "\10f548"; }

.fad.fa-running:after {
  content: "\10f70c"; }

.fad.fa-rupee-sign:after {
  content: "\10f156"; }

.fad.fa-rv:after {
  content: "\10f7be"; }

.fad.fa-sack:after {
  content: "\10f81c"; }

.fad.fa-sack-dollar:after {
  content: "\10f81d"; }

.fad.fa-sad-cry:after {
  content: "\10f5b3"; }

.fad.fa-sad-tear:after {
  content: "\10f5b4"; }

.fad.fa-salad:after {
  content: "\10f81e"; }

.fad.fa-sandwich:after {
  content: "\10f81f"; }

.fad.fa-satellite:after {
  content: "\10f7bf"; }

.fad.fa-satellite-dish:after {
  content: "\10f7c0"; }

.fad.fa-sausage:after {
  content: "\10f820"; }

.fad.fa-save:after {
  content: "\10f0c7"; }

.fad.fa-scalpel:after {
  content: "\10f61d"; }

.fad.fa-scalpel-path:after {
  content: "\10f61e"; }

.fad.fa-scanner:after {
  content: "\10f488"; }

.fad.fa-scanner-keyboard:after {
  content: "\10f489"; }

.fad.fa-scanner-touchscreen:after {
  content: "\10f48a"; }

.fad.fa-scarecrow:after {
  content: "\10f70d"; }

.fad.fa-scarf:after {
  content: "\10f7c1"; }

.fad.fa-school:after {
  content: "\10f549"; }

.fad.fa-screwdriver:after {
  content: "\10f54a"; }

.fad.fa-scroll:after {
  content: "\10f70e"; }

.fad.fa-scroll-old:after {
  content: "\10f70f"; }

.fad.fa-scrubber:after {
  content: "\10f2f8"; }

.fad.fa-scythe:after {
  content: "\10f710"; }

.fad.fa-sd-card:after {
  content: "\10f7c2"; }

.fad.fa-search:after {
  content: "\10f002"; }

.fad.fa-search-dollar:after {
  content: "\10f688"; }

.fad.fa-search-location:after {
  content: "\10f689"; }

.fad.fa-search-minus:after {
  content: "\10f010"; }

.fad.fa-search-plus:after {
  content: "\10f00e"; }

.fad.fa-seedling:after {
  content: "\10f4d8"; }

.fad.fa-send-back:after {
  content: "\10f87e"; }

.fad.fa-send-backward:after {
  content: "\10f87f"; }

.fad.fa-server:after {
  content: "\10f233"; }

.fad.fa-shapes:after {
  content: "\10f61f"; }

.fad.fa-share:after {
  content: "\10f064"; }

.fad.fa-share-all:after {
  content: "\10f367"; }

.fad.fa-share-alt:after {
  content: "\10f1e0"; }

.fad.fa-share-alt-square:after {
  content: "\10f1e1"; }

.fad.fa-share-square:after {
  content: "\10f14d"; }

.fad.fa-sheep:after {
  content: "\10f711"; }

.fad.fa-shekel-sign:after {
  content: "\10f20b"; }

.fad.fa-shield:after {
  content: "\10f132"; }

.fad.fa-shield-alt:after {
  content: "\10f3ed"; }

.fad.fa-shield-check:after {
  content: "\10f2f7"; }

.fad.fa-shield-cross:after {
  content: "\10f712"; }

.fad.fa-ship:after {
  content: "\10f21a"; }

.fad.fa-shipping-fast:after {
  content: "\10f48b"; }

.fad.fa-shipping-timed:after {
  content: "\10f48c"; }

.fad.fa-shish-kebab:after {
  content: "\10f821"; }

.fad.fa-shoe-prints:after {
  content: "\10f54b"; }

.fad.fa-shopping-bag:after {
  content: "\10f290"; }

.fad.fa-shopping-basket:after {
  content: "\10f291"; }

.fad.fa-shopping-cart:after {
  content: "\10f07a"; }

.fad.fa-shovel:after {
  content: "\10f713"; }

.fad.fa-shovel-snow:after {
  content: "\10f7c3"; }

.fad.fa-shower:after {
  content: "\10f2cc"; }

.fad.fa-shredder:after {
  content: "\10f68a"; }

.fad.fa-shuttle-van:after {
  content: "\10f5b6"; }

.fad.fa-shuttlecock:after {
  content: "\10f45b"; }

.fad.fa-sickle:after {
  content: "\10f822"; }

.fad.fa-sigma:after {
  content: "\10f68b"; }

.fad.fa-sign:after {
  content: "\10f4d9"; }

.fad.fa-sign-in:after {
  content: "\10f090"; }

.fad.fa-sign-in-alt:after {
  content: "\10f2f6"; }

.fad.fa-sign-language:after {
  content: "\10f2a7"; }

.fad.fa-sign-out:after {
  content: "\10f08b"; }

.fad.fa-sign-out-alt:after {
  content: "\10f2f5"; }

.fad.fa-signal:after {
  content: "\10f012"; }

.fad.fa-signal-1:after {
  content: "\10f68c"; }

.fad.fa-signal-2:after {
  content: "\10f68d"; }

.fad.fa-signal-3:after {
  content: "\10f68e"; }

.fad.fa-signal-4:after {
  content: "\10f68f"; }

.fad.fa-signal-alt:after {
  content: "\10f690"; }

.fad.fa-signal-alt-1:after {
  content: "\10f691"; }

.fad.fa-signal-alt-2:after {
  content: "\10f692"; }

.fad.fa-signal-alt-3:after {
  content: "\10f693"; }

.fad.fa-signal-alt-slash:after {
  content: "\10f694"; }

.fad.fa-signal-slash:after {
  content: "\10f695"; }

.fad.fa-signature:after {
  content: "\10f5b7"; }

.fad.fa-sim-card:after {
  content: "\10f7c4"; }

.fad.fa-sitemap:after {
  content: "\10f0e8"; }

.fad.fa-skating:after {
  content: "\10f7c5"; }

.fad.fa-skeleton:after {
  content: "\10f620"; }

.fad.fa-ski-jump:after {
  content: "\10f7c7"; }

.fad.fa-ski-lift:after {
  content: "\10f7c8"; }

.fad.fa-skiing:after {
  content: "\10f7c9"; }

.fad.fa-skiing-nordic:after {
  content: "\10f7ca"; }

.fad.fa-skull:after {
  content: "\10f54c"; }

.fad.fa-skull-crossbones:after {
  content: "\10f714"; }

.fad.fa-slash:after {
  content: "\10f715"; }

.fad.fa-sledding:after {
  content: "\10f7cb"; }

.fad.fa-sleigh:after {
  content: "\10f7cc"; }

.fad.fa-sliders-h:after {
  content: "\10f1de"; }

.fad.fa-sliders-h-square:after {
  content: "\10f3f0"; }

.fad.fa-sliders-v:after {
  content: "\10f3f1"; }

.fad.fa-sliders-v-square:after {
  content: "\10f3f2"; }

.fad.fa-smile:after {
  content: "\10f118"; }

.fad.fa-smile-beam:after {
  content: "\10f5b8"; }

.fad.fa-smile-plus:after {
  content: "\10f5b9"; }

.fad.fa-smile-wink:after {
  content: "\10f4da"; }

.fad.fa-smog:after {
  content: "\10f75f"; }

.fad.fa-smoke:after {
  content: "\10f760"; }

.fad.fa-smoking:after {
  content: "\10f48d"; }

.fad.fa-smoking-ban:after {
  content: "\10f54d"; }

.fad.fa-sms:after {
  content: "\10f7cd"; }

.fad.fa-snake:after {
  content: "\10f716"; }

.fad.fa-snooze:after {
  content: "\10f880"; }

.fad.fa-snow-blowing:after {
  content: "\10f761"; }

.fad.fa-snowboarding:after {
  content: "\10f7ce"; }

.fad.fa-snowflake:after {
  content: "\10f2dc"; }

.fad.fa-snowflakes:after {
  content: "\10f7cf"; }

.fad.fa-snowman:after {
  content: "\10f7d0"; }

.fad.fa-snowmobile:after {
  content: "\10f7d1"; }

.fad.fa-snowplow:after {
  content: "\10f7d2"; }

.fad.fa-socks:after {
  content: "\10f696"; }

.fad.fa-solar-panel:after {
  content: "\10f5ba"; }

.fad.fa-sort:after {
  content: "\10f0dc"; }

.fad.fa-sort-alpha-down:after {
  content: "\10f15d"; }

.fad.fa-sort-alpha-down-alt:after {
  content: "\10f881"; }

.fad.fa-sort-alpha-up:after {
  content: "\10f15e"; }

.fad.fa-sort-alpha-up-alt:after {
  content: "\10f882"; }

.fad.fa-sort-alt:after {
  content: "\10f883"; }

.fad.fa-sort-amount-down:after {
  content: "\10f160"; }

.fad.fa-sort-amount-down-alt:after {
  content: "\10f884"; }

.fad.fa-sort-amount-up:after {
  content: "\10f161"; }

.fad.fa-sort-amount-up-alt:after {
  content: "\10f885"; }

.fad.fa-sort-down:after {
  content: "\10f0dd"; }

.fad.fa-sort-numeric-down:after {
  content: "\10f162"; }

.fad.fa-sort-numeric-down-alt:after {
  content: "\10f886"; }

.fad.fa-sort-numeric-up:after {
  content: "\10f163"; }

.fad.fa-sort-numeric-up-alt:after {
  content: "\10f887"; }

.fad.fa-sort-shapes-down:after {
  content: "\10f888"; }

.fad.fa-sort-shapes-down-alt:after {
  content: "\10f889"; }

.fad.fa-sort-shapes-up:after {
  content: "\10f88a"; }

.fad.fa-sort-shapes-up-alt:after {
  content: "\10f88b"; }

.fad.fa-sort-size-down:after {
  content: "\10f88c"; }

.fad.fa-sort-size-down-alt:after {
  content: "\10f88d"; }

.fad.fa-sort-size-up:after {
  content: "\10f88e"; }

.fad.fa-sort-size-up-alt:after {
  content: "\10f88f"; }

.fad.fa-sort-up:after {
  content: "\10f0de"; }

.fad.fa-soup:after {
  content: "\10f823"; }

.fad.fa-spa:after {
  content: "\10f5bb"; }

.fad.fa-space-shuttle:after {
  content: "\10f197"; }

.fad.fa-spade:after {
  content: "\10f2f4"; }

.fad.fa-sparkles:after {
  content: "\10f890"; }

.fad.fa-spell-check:after {
  content: "\10f891"; }

.fad.fa-spider:after {
  content: "\10f717"; }

.fad.fa-spider-black-widow:after {
  content: "\10f718"; }

.fad.fa-spider-web:after {
  content: "\10f719"; }

.fad.fa-spinner:after {
  content: "\10f110"; }

.fad.fa-spinner-third:after {
  content: "\10f3f4"; }

.fad.fa-splotch:after {
  content: "\10f5bc"; }

.fad.fa-spray-can:after {
  content: "\10f5bd"; }

.fad.fa-square:after {
  content: "\10f0c8"; }

.fad.fa-square-full:after {
  content: "\10f45c"; }

.fad.fa-square-root:after {
  content: "\10f697"; }

.fad.fa-square-root-alt:after {
  content: "\10f698"; }

.fad.fa-squirrel:after {
  content: "\10f71a"; }

.fad.fa-staff:after {
  content: "\10f71b"; }

.fad.fa-stamp:after {
  content: "\10f5bf"; }

.fad.fa-star:after {
  content: "\10f005"; }

.fad.fa-star-and-crescent:after {
  content: "\10f699"; }

.fad.fa-star-christmas:after {
  content: "\10f7d4"; }

.fad.fa-star-exclamation:after {
  content: "\10f2f3"; }

.fad.fa-star-half:after {
  content: "\10f089"; }

.fad.fa-star-half-alt:after {
  content: "\10f5c0"; }

.fad.fa-star-of-david:after {
  content: "\10f69a"; }

.fad.fa-star-of-life:after {
  content: "\10f621"; }

.fad.fa-stars:after {
  content: "\10f762"; }

.fad.fa-steak:after {
  content: "\10f824"; }

.fad.fa-steering-wheel:after {
  content: "\10f622"; }

.fad.fa-step-backward:after {
  content: "\10f048"; }

.fad.fa-step-forward:after {
  content: "\10f051"; }

.fad.fa-stethoscope:after {
  content: "\10f0f1"; }

.fad.fa-sticky-note:after {
  content: "\10f249"; }

.fad.fa-stocking:after {
  content: "\10f7d5"; }

.fad.fa-stomach:after {
  content: "\10f623"; }

.fad.fa-stop:after {
  content: "\10f04d"; }

.fad.fa-stop-circle:after {
  content: "\10f28d"; }

.fad.fa-stopwatch:after {
  content: "\10f2f2"; }

.fad.fa-store:after {
  content: "\10f54e"; }

.fad.fa-store-alt:after {
  content: "\10f54f"; }

.fad.fa-stream:after {
  content: "\10f550"; }

.fad.fa-street-view:after {
  content: "\10f21d"; }

.fad.fa-stretcher:after {
  content: "\10f825"; }

.fad.fa-strikethrough:after {
  content: "\10f0cc"; }

.fad.fa-stroopwafel:after {
  content: "\10f551"; }

.fad.fa-subscript:after {
  content: "\10f12c"; }

.fad.fa-subway:after {
  content: "\10f239"; }

.fad.fa-suitcase:after {
  content: "\10f0f2"; }

.fad.fa-suitcase-rolling:after {
  content: "\10f5c1"; }

.fad.fa-sun:after {
  content: "\10f185"; }

.fad.fa-sun-cloud:after {
  content: "\10f763"; }

.fad.fa-sun-dust:after {
  content: "\10f764"; }

.fad.fa-sun-haze:after {
  content: "\10f765"; }

.fad.fa-sunglasses:after {
  content: "\10f892"; }

.fad.fa-sunrise:after {
  content: "\10f766"; }

.fad.fa-sunset:after {
  content: "\10f767"; }

.fad.fa-superscript:after {
  content: "\10f12b"; }

.fad.fa-surprise:after {
  content: "\10f5c2"; }

.fad.fa-swatchbook:after {
  content: "\10f5c3"; }

.fad.fa-swimmer:after {
  content: "\10f5c4"; }

.fad.fa-swimming-pool:after {
  content: "\10f5c5"; }

.fad.fa-sword:after {
  content: "\10f71c"; }

.fad.fa-swords:after {
  content: "\10f71d"; }

.fad.fa-synagogue:after {
  content: "\10f69b"; }

.fad.fa-sync:after {
  content: "\10f021"; }

.fad.fa-sync-alt:after {
  content: "\10f2f1"; }

.fad.fa-syringe:after {
  content: "\10f48e"; }

.fad.fa-table:after {
  content: "\10f0ce"; }

.fad.fa-table-tennis:after {
  content: "\10f45d"; }

.fad.fa-tablet:after {
  content: "\10f10a"; }

.fad.fa-tablet-alt:after {
  content: "\10f3fa"; }

.fad.fa-tablet-android:after {
  content: "\10f3fb"; }

.fad.fa-tablet-android-alt:after {
  content: "\10f3fc"; }

.fad.fa-tablet-rugged:after {
  content: "\10f48f"; }

.fad.fa-tablets:after {
  content: "\10f490"; }

.fad.fa-tachometer:after {
  content: "\10f0e4"; }

.fad.fa-tachometer-alt:after {
  content: "\10f3fd"; }

.fad.fa-tachometer-alt-average:after {
  content: "\10f624"; }

.fad.fa-tachometer-alt-fast:after {
  content: "\10f625"; }

.fad.fa-tachometer-alt-fastest:after {
  content: "\10f626"; }

.fad.fa-tachometer-alt-slow:after {
  content: "\10f627"; }

.fad.fa-tachometer-alt-slowest:after {
  content: "\10f628"; }

.fad.fa-tachometer-average:after {
  content: "\10f629"; }

.fad.fa-tachometer-fast:after {
  content: "\10f62a"; }

.fad.fa-tachometer-fastest:after {
  content: "\10f62b"; }

.fad.fa-tachometer-slow:after {
  content: "\10f62c"; }

.fad.fa-tachometer-slowest:after {
  content: "\10f62d"; }

.fad.fa-taco:after {
  content: "\10f826"; }

.fad.fa-tag:after {
  content: "\10f02b"; }

.fad.fa-tags:after {
  content: "\10f02c"; }

.fad.fa-tally:after {
  content: "\10f69c"; }

.fad.fa-tanakh:after {
  content: "\10f827"; }

.fad.fa-tape:after {
  content: "\10f4db"; }

.fad.fa-tasks:after {
  content: "\10f0ae"; }

.fad.fa-tasks-alt:after {
  content: "\10f828"; }

.fad.fa-taxi:after {
  content: "\10f1ba"; }

.fad.fa-teeth:after {
  content: "\10f62e"; }

.fad.fa-teeth-open:after {
  content: "\10f62f"; }

.fad.fa-temperature-frigid:after {
  content: "\10f768"; }

.fad.fa-temperature-high:after {
  content: "\10f769"; }

.fad.fa-temperature-hot:after {
  content: "\10f76a"; }

.fad.fa-temperature-low:after {
  content: "\10f76b"; }

.fad.fa-tenge:after {
  content: "\10f7d7"; }

.fad.fa-tennis-ball:after {
  content: "\10f45e"; }

.fad.fa-terminal:after {
  content: "\10f120"; }

.fad.fa-text:after {
  content: "\10f893"; }

.fad.fa-text-height:after {
  content: "\10f034"; }

.fad.fa-text-size:after {
  content: "\10f894"; }

.fad.fa-text-width:after {
  content: "\10f035"; }

.fad.fa-th:after {
  content: "\10f00a"; }

.fad.fa-th-large:after {
  content: "\10f009"; }

.fad.fa-th-list:after {
  content: "\10f00b"; }

.fad.fa-theater-masks:after {
  content: "\10f630"; }

.fad.fa-thermometer:after {
  content: "\10f491"; }

.fad.fa-thermometer-empty:after {
  content: "\10f2cb"; }

.fad.fa-thermometer-full:after {
  content: "\10f2c7"; }

.fad.fa-thermometer-half:after {
  content: "\10f2c9"; }

.fad.fa-thermometer-quarter:after {
  content: "\10f2ca"; }

.fad.fa-thermometer-three-quarters:after {
  content: "\10f2c8"; }

.fad.fa-theta:after {
  content: "\10f69e"; }

.fad.fa-thumbs-down:after {
  content: "\10f165"; }

.fad.fa-thumbs-up:after {
  content: "\10f164"; }

.fad.fa-thumbtack:after {
  content: "\10f08d"; }

.fad.fa-thunderstorm:after {
  content: "\10f76c"; }

.fad.fa-thunderstorm-moon:after {
  content: "\10f76d"; }

.fad.fa-thunderstorm-sun:after {
  content: "\10f76e"; }

.fad.fa-ticket:after {
  content: "\10f145"; }

.fad.fa-ticket-alt:after {
  content: "\10f3ff"; }

.fad.fa-tilde:after {
  content: "\10f69f"; }

.fad.fa-times:after {
  content: "\10f00d"; }

.fad.fa-times-circle:after {
  content: "\10f057"; }

.fad.fa-times-hexagon:after {
  content: "\10f2ee"; }

.fad.fa-times-octagon:after {
  content: "\10f2f0"; }

.fad.fa-times-square:after {
  content: "\10f2d3"; }

.fad.fa-tint:after {
  content: "\10f043"; }

.fad.fa-tint-slash:after {
  content: "\10f5c7"; }

.fad.fa-tire:after {
  content: "\10f631"; }

.fad.fa-tire-flat:after {
  content: "\10f632"; }

.fad.fa-tire-pressure-warning:after {
  content: "\10f633"; }

.fad.fa-tire-rugged:after {
  content: "\10f634"; }

.fad.fa-tired:after {
  content: "\10f5c8"; }

.fad.fa-toggle-off:after {
  content: "\10f204"; }

.fad.fa-toggle-on:after {
  content: "\10f205"; }

.fad.fa-toilet:after {
  content: "\10f7d8"; }

.fad.fa-toilet-paper:after {
  content: "\10f71e"; }

.fad.fa-toilet-paper-alt:after {
  content: "\10f71f"; }

.fad.fa-tombstone:after {
  content: "\10f720"; }

.fad.fa-tombstone-alt:after {
  content: "\10f721"; }

.fad.fa-toolbox:after {
  content: "\10f552"; }

.fad.fa-tools:after {
  content: "\10f7d9"; }

.fad.fa-tooth:after {
  content: "\10f5c9"; }

.fad.fa-toothbrush:after {
  content: "\10f635"; }

.fad.fa-torah:after {
  content: "\10f6a0"; }

.fad.fa-torii-gate:after {
  content: "\10f6a1"; }

.fad.fa-tornado:after {
  content: "\10f76f"; }

.fad.fa-tractor:after {
  content: "\10f722"; }

.fad.fa-trademark:after {
  content: "\10f25c"; }

.fad.fa-traffic-cone:after {
  content: "\10f636"; }

.fad.fa-traffic-light:after {
  content: "\10f637"; }

.fad.fa-traffic-light-go:after {
  content: "\10f638"; }

.fad.fa-traffic-light-slow:after {
  content: "\10f639"; }

.fad.fa-traffic-light-stop:after {
  content: "\10f63a"; }

.fad.fa-train:after {
  content: "\10f238"; }

.fad.fa-tram:after {
  content: "\10f7da"; }

.fad.fa-transgender:after {
  content: "\10f224"; }

.fad.fa-transgender-alt:after {
  content: "\10f225"; }

.fad.fa-trash:after {
  content: "\10f1f8"; }

.fad.fa-trash-alt:after {
  content: "\10f2ed"; }

.fad.fa-trash-restore:after {
  content: "\10f829"; }

.fad.fa-trash-restore-alt:after {
  content: "\10f82a"; }

.fad.fa-trash-undo:after {
  content: "\10f895"; }

.fad.fa-trash-undo-alt:after {
  content: "\10f896"; }

.fad.fa-treasure-chest:after {
  content: "\10f723"; }

.fad.fa-tree:after {
  content: "\10f1bb"; }

.fad.fa-tree-alt:after {
  content: "\10f400"; }

.fad.fa-tree-christmas:after {
  content: "\10f7db"; }

.fad.fa-tree-decorated:after {
  content: "\10f7dc"; }

.fad.fa-tree-large:after {
  content: "\10f7dd"; }

.fad.fa-tree-palm:after {
  content: "\10f82b"; }

.fad.fa-trees:after {
  content: "\10f724"; }

.fad.fa-triangle:after {
  content: "\10f2ec"; }

.fad.fa-trophy:after {
  content: "\10f091"; }

.fad.fa-trophy-alt:after {
  content: "\10f2eb"; }

.fad.fa-truck:after {
  content: "\10f0d1"; }

.fad.fa-truck-container:after {
  content: "\10f4dc"; }

.fad.fa-truck-couch:after {
  content: "\10f4dd"; }

.fad.fa-truck-loading:after {
  content: "\10f4de"; }

.fad.fa-truck-monster:after {
  content: "\10f63b"; }

.fad.fa-truck-moving:after {
  content: "\10f4df"; }

.fad.fa-truck-pickup:after {
  content: "\10f63c"; }

.fad.fa-truck-plow:after {
  content: "\10f7de"; }

.fad.fa-truck-ramp:after {
  content: "\10f4e0"; }

.fad.fa-tshirt:after {
  content: "\10f553"; }

.fad.fa-tty:after {
  content: "\10f1e4"; }

.fad.fa-turkey:after {
  content: "\10f725"; }

.fad.fa-turtle:after {
  content: "\10f726"; }

.fad.fa-tv:after {
  content: "\10f26c"; }

.fad.fa-tv-retro:after {
  content: "\10f401"; }

.fad.fa-umbrella:after {
  content: "\10f0e9"; }

.fad.fa-umbrella-beach:after {
  content: "\10f5ca"; }

.fad.fa-underline:after {
  content: "\10f0cd"; }

.fad.fa-undo:after {
  content: "\10f0e2"; }

.fad.fa-undo-alt:after {
  content: "\10f2ea"; }

.fad.fa-unicorn:after {
  content: "\10f727"; }

.fad.fa-union:after {
  content: "\10f6a2"; }

.fad.fa-universal-access:after {
  content: "\10f29a"; }

.fad.fa-university:after {
  content: "\10f19c"; }

.fad.fa-unlink:after {
  content: "\10f127"; }

.fad.fa-unlock:after {
  content: "\10f09c"; }

.fad.fa-unlock-alt:after {
  content: "\10f13e"; }

.fad.fa-upload:after {
  content: "\10f093"; }

.fad.fa-usd-circle:after {
  content: "\10f2e8"; }

.fad.fa-usd-square:after {
  content: "\10f2e9"; }

.fad.fa-user:after {
  content: "\10f007"; }

.fad.fa-user-alt:after {
  content: "\10f406"; }

.fad.fa-user-alt-slash:after {
  content: "\10f4fa"; }

.fad.fa-user-astronaut:after {
  content: "\10f4fb"; }

.fad.fa-user-chart:after {
  content: "\10f6a3"; }

.fad.fa-user-check:after {
  content: "\10f4fc"; }

.fad.fa-user-circle:after {
  content: "\10f2bd"; }

.fad.fa-user-clock:after {
  content: "\10f4fd"; }

.fad.fa-user-cog:after {
  content: "\10f4fe"; }

.fad.fa-user-crown:after {
  content: "\10f6a4"; }

.fad.fa-user-edit:after {
  content: "\10f4ff"; }

.fad.fa-user-friends:after {
  content: "\10f500"; }

.fad.fa-user-graduate:after {
  content: "\10f501"; }

.fad.fa-user-hard-hat:after {
  content: "\10f82c"; }

.fad.fa-user-headset:after {
  content: "\10f82d"; }

.fad.fa-user-injured:after {
  content: "\10f728"; }

.fad.fa-user-lock:after {
  content: "\10f502"; }

.fad.fa-user-md:after {
  content: "\10f0f0"; }

.fad.fa-user-md-chat:after {
  content: "\10f82e"; }

.fad.fa-user-minus:after {
  content: "\10f503"; }

.fad.fa-user-ninja:after {
  content: "\10f504"; }

.fad.fa-user-nurse:after {
  content: "\10f82f"; }

.fad.fa-user-plus:after {
  content: "\10f234"; }

.fad.fa-user-secret:after {
  content: "\10f21b"; }

.fad.fa-user-shield:after {
  content: "\10f505"; }

.fad.fa-user-slash:after {
  content: "\10f506"; }

.fad.fa-user-tag:after {
  content: "\10f507"; }

.fad.fa-user-tie:after {
  content: "\10f508"; }

.fad.fa-user-times:after {
  content: "\10f235"; }

.fad.fa-users:after {
  content: "\10f0c0"; }

.fad.fa-users-class:after {
  content: "\10f63d"; }

.fad.fa-users-cog:after {
  content: "\10f509"; }

.fad.fa-users-crown:after {
  content: "\10f6a5"; }

.fad.fa-users-medical:after {
  content: "\10f830"; }

.fad.fa-utensil-fork:after {
  content: "\10f2e3"; }

.fad.fa-utensil-knife:after {
  content: "\10f2e4"; }

.fad.fa-utensil-spoon:after {
  content: "\10f2e5"; }

.fad.fa-utensils:after {
  content: "\10f2e7"; }

.fad.fa-utensils-alt:after {
  content: "\10f2e6"; }

.fad.fa-value-absolute:after {
  content: "\10f6a6"; }

.fad.fa-vector-square:after {
  content: "\10f5cb"; }

.fad.fa-venus:after {
  content: "\10f221"; }

.fad.fa-venus-double:after {
  content: "\10f226"; }

.fad.fa-venus-mars:after {
  content: "\10f228"; }

.fad.fa-vial:after {
  content: "\10f492"; }

.fad.fa-vials:after {
  content: "\10f493"; }

.fad.fa-video:after {
  content: "\10f03d"; }

.fad.fa-video-plus:after {
  content: "\10f4e1"; }

.fad.fa-video-slash:after {
  content: "\10f4e2"; }

.fad.fa-vihara:after {
  content: "\10f6a7"; }

.fad.fa-voicemail:after {
  content: "\10f897"; }

.fad.fa-volcano:after {
  content: "\10f770"; }

.fad.fa-volleyball-ball:after {
  content: "\10f45f"; }

.fad.fa-volume:after {
  content: "\10f6a8"; }

.fad.fa-volume-down:after {
  content: "\10f027"; }

.fad.fa-volume-mute:after {
  content: "\10f6a9"; }

.fad.fa-volume-off:after {
  content: "\10f026"; }

.fad.fa-volume-slash:after {
  content: "\10f2e2"; }

.fad.fa-volume-up:after {
  content: "\10f028"; }

.fad.fa-vote-nay:after {
  content: "\10f771"; }

.fad.fa-vote-yea:after {
  content: "\10f772"; }

.fad.fa-vr-cardboard:after {
  content: "\10f729"; }

.fad.fa-walker:after {
  content: "\10f831"; }

.fad.fa-walking:after {
  content: "\10f554"; }

.fad.fa-wallet:after {
  content: "\10f555"; }

.fad.fa-wand:after {
  content: "\10f72a"; }

.fad.fa-wand-magic:after {
  content: "\10f72b"; }

.fad.fa-warehouse:after {
  content: "\10f494"; }

.fad.fa-warehouse-alt:after {
  content: "\10f495"; }

.fad.fa-washer:after {
  content: "\10f898"; }

.fad.fa-watch:after {
  content: "\10f2e1"; }

.fad.fa-watch-fitness:after {
  content: "\10f63e"; }

.fad.fa-water:after {
  content: "\10f773"; }

.fad.fa-water-lower:after {
  content: "\10f774"; }

.fad.fa-water-rise:after {
  content: "\10f775"; }

.fad.fa-wave-sine:after {
  content: "\10f899"; }

.fad.fa-wave-square:after {
  content: "\10f83e"; }

.fad.fa-wave-triangle:after {
  content: "\10f89a"; }

.fad.fa-webcam:after {
  content: "\10f832"; }

.fad.fa-webcam-slash:after {
  content: "\10f833"; }

.fad.fa-weight:after {
  content: "\10f496"; }

.fad.fa-weight-hanging:after {
  content: "\10f5cd"; }

.fad.fa-whale:after {
  content: "\10f72c"; }

.fad.fa-wheat:after {
  content: "\10f72d"; }

.fad.fa-wheelchair:after {
  content: "\10f193"; }

.fad.fa-whistle:after {
  content: "\10f460"; }

.fad.fa-wifi:after {
  content: "\10f1eb"; }

.fad.fa-wifi-1:after {
  content: "\10f6aa"; }

.fad.fa-wifi-2:after {
  content: "\10f6ab"; }

.fad.fa-wifi-slash:after {
  content: "\10f6ac"; }

.fad.fa-wind:after {
  content: "\10f72e"; }

.fad.fa-wind-turbine:after {
  content: "\10f89b"; }

.fad.fa-wind-warning:after {
  content: "\10f776"; }

.fad.fa-window:after {
  content: "\10f40e"; }

.fad.fa-window-alt:after {
  content: "\10f40f"; }

.fad.fa-window-close:after {
  content: "\10f410"; }

.fad.fa-window-maximize:after {
  content: "\10f2d0"; }

.fad.fa-window-minimize:after {
  content: "\10f2d1"; }

.fad.fa-window-restore:after {
  content: "\10f2d2"; }

.fad.fa-windsock:after {
  content: "\10f777"; }

.fad.fa-wine-bottle:after {
  content: "\10f72f"; }

.fad.fa-wine-glass:after {
  content: "\10f4e3"; }

.fad.fa-wine-glass-alt:after {
  content: "\10f5ce"; }

.fad.fa-won-sign:after {
  content: "\10f159"; }

.fad.fa-wreath:after {
  content: "\10f7e2"; }

.fad.fa-wrench:after {
  content: "\10f0ad"; }

.fad.fa-x-ray:after {
  content: "\10f497"; }

.fad.fa-yen-sign:after {
  content: "\10f157"; }

.fad.fa-yin-yang:after {
  content: "\10f6ad"; }

/*!
 * Font Awesome Pro 5.10.2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../webfonts/fa-regular-400.eot");
  src: url("../webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff"), url("../webfonts/fa-regular-400.ttf") format("truetype"), url("../webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400; }
