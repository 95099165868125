/* ======================= COLORS ===================== */

$primary-blue: #2a94d6;
$secondary-blue: #3973b6;
$light-blue: #47b4ea;

$light-gray: #d8d8d8;
$med-gray: #8d8d8d;
$dark-gray: #4f4f4f;
$extra-light-gray: #efefef;

$green: #39ab61;
$light-green: #55c97b;
$yellow: #fab50f;
$gold: #fab50f;

$white: #fff;

:root {
	--primary-blue: #2a94d6;
}

.lt-gray-bg {
	background-color: $extra-light-gray;
}

.white-bg {
	background-color: $white;
}

.text-white {
	color: #ffffff;
}

.text-blue {
	color: $primary-blue;
}

.text-green {
	color: $green;
}

.dmCustomerBranding {
	color: $primary-blue;
}

.fa-facebook {
	color: #3b5998;
}
.fa-twitter-square {
	color: #55acee;
}

.fa-linkedin {
	color: #007bb5;
}

.fa-facebook,
.fa-twitter-square,
.fa-linkedin {
	font-size: 30px;
	display: inline-block;
	padding-right: 10px;
}

/* ======================= MODAL ===================== */
//** Modal backdrop background color
$modal-backdrop-bg: #e6e6e6 !default;

$modal-content-bg: $white !default;
$modal-content-border-color: none !default;
$modal-content-border-width: 0px !default;
$modal-content-border-radius: 0rem !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: none !default;

/* ======================= BASE CLASS DEFINITION ===================== */
$fa-font-path: '../webfonts';

.fa,
.fas,
.far,
.fal,
.fad,
.fab {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

/* ======================= JUMBROTRONS ===================== */

.jumbotron-content {
	background: rgba(102, 102, 102, 0.35);
	border: 6px solid $white;
	text-align: center;
	padding: 25px;
	margin-top: 110px;
	h1 {
		font-size: 35pt;
		padding: 0 15px 15px 15px;
	}
	h1.white {
		color: $white;
	}

	p {
		font-size: 14pt;
	}
}

// Hero Banners
.hero img {
	margin: 0 auto -40px auto;
	display: block; //same styles as .img-responsive
	max-width: 100%;
	height: auto;
}
.hero.container {
	padding-left: 0px;
	padding-right: 0px;
}

/* ======================= FONTS ===================== */
body {
	font-family: 'Raleway', sans-serif;
	color: $dark-gray;
	line-height: 1.9rem;
}

/* ======================= BUTTONS ===================== */
.btn {
	text-transform: uppercase;
	padding: 10px 35px;
	border-radius: 25px;
	border: none;
	font-weight: 600;
	@include transition(all, 0.2s, ease-in-out);
}
.green-btn {
	color: $white;
	background-color: $green !important;
	&:hover,
	&:active {
		background-color: $light-green;
		color: $white;
	}
}
.blue-btn {
	color: $white;
	background-color: $primary-blue;
	&:hover,
	&:active {
		background-color: $light-blue;
		color: $white;
	}
}
.btn-xs {
	padding: 5px 15px;
	font-size: 11px;
}

/* ======================= TYPOGRAPHY ===================== */
h1 {
	padding: 25px 0;
}

h3 {
	line-height: 1.3;
	font-weight: 300;
}
h4,
h5 {
	font-weight: 600;
}

h5 {
	font-size: 16px;
}
a {
	font-weight: 600;
	@include transition(all, 0.2s, ease-in-out);
	&:hover,
	&:active {
		text-decoration: none;
	}
	&.gray-link {
		color: $dark-gray;
		&:hover,
		&:active {
			color: $green;
		}
	}
	&.green-link {
		color: $green;
		&:hover,
		&:active {
			color: $light-green;
		}
	}
}
.bold-text {
	font-weight: 700;
}

/* ====================== IMAGES ========================== */
.img-responsive {
	margin: 0 auto 0px auto;
}
.pdp-img-responsive {
	margin: 0 auto 0px auto;
	width: 360px;
}

/* ======================= STRUCTURE ===================== */

.advertisements {
	padding: 50px 0 50px 0;
	img {
		padding: 0 0 25px 0;
	}
}
.extraColPadding {
	padding: 0 20px 0 0;
}

.box {
	background: $white;
	border-radius: 4px;
	border: 1px solid $extra-light-gray;
	padding: 25px;
}

.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.contact-advisor::before {
	background: $light-gray;
	content: '';
}
.nav-fill .tab-item {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	/*text-align: center;*/
}

/* ======================= TABLE ===================== */

.tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	border-bottom: 1px solid #eee;
}

.tab-item {
	display: block;
	padding: 0.5rem 25px;
	text-align: left;
	color: $dark-gray;
	opacity: 0.5;
}

.tab-item.active {
	font-weight: bold;
	border-bottom: 3px solid $primary-blue;
	color: $dark-gray;
	opacity: 1;
}

.faux-thead {
	padding: 10px 25px;
	font-size: 11px;
}

.faux-row {
	.name {
		font-weight: bold;
	}
	.number {
		/*color: $primary-blue;*/
	}

	.row {
		padding: 25px;
	}
	.input {
		border: $dark-gray;
	}
	label {
		display: none;
		color: $dark-gray;
		font-size: 11px;
	}
	.cta-mobile {
		float: right;
	}
}

.faux-row:nth-of-type(odd) {
	background: #f7f7f7;
}

.cta-mobile {
	display: none;
}

.mobile-only-hr {
	display: none;
	width: 100%;
}

.faux-box {
	background: #f7f7f7;
	height: 180px;
	padding: 40px 10px 10px 10px;
}

a .faux-box {
	h4,
	p {
		color: $dark-gray;
	}
	p {
		font-weight: 400;
	}
}

a .faux-box:hover {
	background: #e5f5fd;
}

.img-icon {
	width: 40px;
	padding-bottom: 10px;
}

.padded-row {
	padding-top: 20px;
}

@media (max-width: 768px) {
	.faux-row {
		.checkbox,
		.cta {
			display: none;
		}
		label {
			display: block;
		}

		.col-1 {
			width: 100%;
		}

		.col-1:nth-of-type(even) {
			width: 100%;
			padding-bottom: 10px;
		}
		.col-4 {
			width: 25%;
			float: left;
			text-align: center;
		}
		.number {
			padding: 10px 5px 0;
		}
	}
	.faux-thead,
	.tabs {
		display: none;
	}
	.cta-mobile {
		display: block;
	}
	.mobile-only-hr {
		display: block;
	}

	.strategies {
		.col-1 {
			width: 100%;
		}
	}
}

/* ======================= PADDING ===================== */

.p-15 {
	padding: 15px;
}
.p-25 {
	padding: 25px;
}
.p-50 {
	padding: 50px;
}
.p-75 {
	padding: 75px;
}
.p-100 {
	padding: 100px;
}
.p-200 {
	padding: 200px;
}
.p-top-15 {
	padding-top: 15px;
}
.p-top-25 {
	padding-top: 25px;
}
.p-top-50 {
	padding-top: 50px;
}
.p-top-75 {
	padding-top: 75px;
}
.p-top-100 {
	padding-top: 100px;
}
.p-top-200 {
	padding-top: 200px;
}
.p-right-15 {
	padding-right: 15px;
}
.p-right-25 {
	padding-right: 25px;
}
.p-right-50 {
	padding-right: 50px;
}
.p-right-75 {
	padding-right: 75px;
}
.p-right-100 {
	padding-right: 100px;
}
.p-right-200 {
	padding-right: 200px;
}
.p-left-15 {
	padding-left: 15px;
}
.p-left-25 {
	padding-left: 25px;
}
.p-left-50 {
	padding-left: 50px;
}
.p-left-75 {
	padding-left: 75px;
}
.p-left-100 {
	padding-left: 100px;
}
.p-left-200 {
	padding-left: 200px;
}
.p-bottom-15 {
	padding-bottom: 15px;
}
.p-bottom-25 {
	padding-bottom: 25px;
}
.p-bottom-50 {
	padding-bottom: 50px;
}
.p-bottom-75 {
	padding-bottom: 75px;
}
.p-bottom-100 {
	padding-bottom: 100px;
}
.p-bottom-200 {
	padding-bottom: 200px;
}

.p-vertical-15  { padding-top: 15px;  padding-bottom: 15px;  }
.p-vertical-25  { padding-top: 25px;  padding-bottom: 25px;  }
.p-vertical-50  { padding-top: 50px;  padding-bottom: 50px;  }
.p-vertical-75  { padding-top: 75px;  padding-bottom: 75px;  }
.p-vertical-100 { padding-top: 100px; padding-bottom: 100px; }
.p-vertical-200 { padding-top: 200px; padding-bottom: 200px; }

.p-horizontal-15  { padding-left: 15px;  padding-right: 15px;  }
.p-horizontal-25  { padding-left: 25px;  padding-right: 25px;  }
.p-horizontal-50  { padding-left: 50px;  padding-right: 50px;  }
.p-horizontal-75  { padding-left: 75px;  padding-right: 75px;  }
.p-horizontal-100 { padding-left: 100px; padding-right: 100px; }
.p-horizontal-200 { padding-left: 200px; padding-right: 200px; }

.m-vertical-15  { margin-top: 15px;  margin-bottom: 15px;  }
.m-vertical-25  { margin-top: 25px;  margin-bottom: 25px;  }
.m-vertical-50  { margin-top: 50px;  margin-bottom: 50px;  }
.m-vertical-75  { margin-top: 75px;  margin-bottom: 75px;  }
.m-vertical-100 { margin-top: 100px; margin-bottom: 100px; }
.m-vertical-200 { margin-top: 200px; margin-bottom: 200px; }

.m-horizontal-15  { margin-left: 15px;  margin-right: 15px;  }
.m-horizontal-25  { margin-left: 25px;  margin-right: 25px;  }
.m-horizontal-50  { margin-left: 50px;  margin-right: 50px;  }
.m-horizontal-75  { margin-left: 75px;  margin-right: 75px;  }
.m-horizontal-100 { margin-left: 100px; margin-right: 100px; }
.m-horizontal-200 { margin-left: 200px; margin-right: 200px; }

.m-bottom-50 {
	margin-bottom: 50px;
}

/* ======================= BOOTSTRAP 5 UPDATES ===================== */

.card {
	--bs-card-spacer-y: 1rem;
	--bs-card-spacer-x: 1rem;
	--bs-card-title-spacer-y: 0.5rem;
	--bs-card-border-width: 1px;
	--bs-card-img-overlay-padding: 1rem;
	--bs-card-group-margin: 0.75rem;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 100%;
	height: var(--bs-card-height);
	word-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 1px solid $extra-light-gray;
	border-radius: 4px;
	margin-bottom: 20px;
	overflow: hidden;
	img {
		width: 100%;
	}
}
.card-header {
	padding: 20px 15px 20px;
	margin-bottom: 0;
	border-bottom: 1px solid $extra-light-gray;
	display: flex;
	align-items: center;
	.card-title {
		display: inline-block;
		font-size: 24px;
	}
	.card-action {
		display: inline-block;
		margin-left: auto;
		padding: 0 20px;
	}
	img {
		vertical-align: middle;
		width: 51px;
		margin-right: 20px;
	}
}

.card-body {
	flex: 1 1 auto;
	padding: 20px 15px;
	color: $dark-gray;
}
