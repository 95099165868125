/* ======================= SVG WEB CUSTOMIZATION ===================== */
.svg-hero-container {
	display: inline-block;
	position: relative;
	width: 100%;
	vertical-align: middle;
}
.svg-viewBox {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
}
.svg-hero-buttoncolor {
	fill: #00AC5B;
}
.svg-hero-freedomcard-bottomcolor {
	fill: #00AC5B;
}
.svg-hero-freedomcard-topcolor {
	fill: #028945;
}
.svg-hero-travelcard-color {
	fill: #0C84CA;
}
.svg-hero-mobileapp-headercolor {
	fill: #2A94D6;
}
.svg-hero-mobileapp-circlecolor {
	fill: #00AC5B;
}
.svg-is-image-primary-color {
	fill: #2A94D6;
}



/** NEED TO ADD TO VIRAL'S SITE **/

.svg-hero-studentcard-dotcolor {
	fill: #D36126;
}
.svg-hero-studentcard-gradientcolor {
	background-image: linear-gradient(to #D77400, #C0542E);
}
.svg-hero-pluscard-gradientcolor {
	background-image: linear-gradient(to #2A94D6, #1AB070);
}
