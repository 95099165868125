/* Inside Pages Template
-------------------------------------------------- */



/* ======================= JUMBOTRONs ===================== */


.retail-banking-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-banking-overview-hero.jpg);
	background-size:cover;
	background-position:center center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-borrowing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-borrowing-overview-hero.jpg);
	background-size:cover;
	background-position:center center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-investing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-investing-overview-hero.jpg);
	background-size:cover;
	background-position:top center;
	margin-bottom: 0px;
	margin-top: -65px;
}


.corporate-banking-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-banking-overview-hero.jpg);
	background-size:cover;
	background-position:right center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.corporate-borrowing-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-borrowing-overview-hero.jpg);
	background-size:cover;
	background-position:right center;
	margin-bottom: 0px;
	margin-top: -65px;
}

.featured-article-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/corporate-research-and-insights-hero.jpg);
	background-size:cover;
	background-position:50% 60%;
	margin-bottom: 0px;
	margin-top: -65px;
}

.retail-news-jumbotron {
	height:500px;
	background-image:url(https://cumulus-fs.s3.amazonaws.com/images/retail-news-hero.jpg);
	background-size:cover;
	background-position:50% 60%;
	margin-bottom: 0px;
	margin-top: -65px;
}



/* ======================= STRUCTURE ===================== */
.inside-intro-content {
	background:url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond.png");
	text-align:center;
	padding: 50px 0;
		.offerings {
			width: 85px;
			height: 85px;
			margin: 40px auto 0px auto;
		}
		h1 {
			padding: 10px 0;
		}
		h3 {
			margin-top:0px;
		}
		h4 {
			margin:20px 0;
		}
}

.product-intro {
	margin:50px auto 25px auto;
		h1 {
			margin-top: 0;
			padding: 10px 0 0 0;
		}
		h3 {
			margin-top:0px;
			margin-bottom: 20px;
		}
		h4 {
			margin:20px 0;
		}
}


.product-details{
	li{
		padding-bottom: 10px;
	}
}

.sidebar {
	background-color: $extra-light-gray;
	padding: 15px 25px 25px 25px;
	border: 1px solid $light-gray;
	margin-top:25px;
}

.overview-products {
	text-align:center;
	img{
		max-width: 82px;
	}
}

.gray-background-content {
	background:$extra-light-gray;
	padding:50px 0;
}

.white-background-content {
	background:$white;
	padding:50px 0;
}

.research-insights-article {
	padding:75px 25px;
	.row {
		margin-bottom:25px;
	}
}
.coverage {
	border: 1px solid #DBDBDB; 
	padding: 10px 20px;
}

