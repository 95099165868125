/* TOP BLUE NAVIGATION BAR */
.category-menu {
	background-color:$primary-blue;
	color:$white;
	height:50px;
	.navbar-left {
		text-transform: uppercase;
		@extend %flexbox;
		@include justify-content(flex-start);
	}
  .navbar-right {
    margin-right: 0;
    font-size: 12px;
  }
	a {
    color:$white;
	}
  .nav > li {
    &.current {
      background-color:$light-blue;
    }
  }
	.nav > li > a {
    	@include transition(background-color, 0.2s, ease-in-out);
    	&:hover,
    	&:focus {
    		text-decoration: none;
  			background-color: $light-blue;
  			color:$white;
    	}
	}
}

/* MAIN NAV WITH LOGO */
.navbar-inverse {
	background-color: rgba(255,255,255, 0.9);
}
.dropdown:hover .dropdown-menu {
    display: block;
 }
.navbar {
	border: none;
	margin:0;
	height:65px;
	.container {
		height:65px;
	}
	.navContainer {
		min-height: 100%;
		@extend %flexbox;
    @include flex-direction(row);
    @include align-items(center);
    //@include justify-content(center);
    width:100%;
	}
	.navbar-brand {
		height:28px;
		width:160px;
		background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo.png') no-repeat;
		margin: -5px 30px 0 0;
	}

	.navbar-nav > li > a {
		color:$primary-blue;
		text-transform: uppercase;
		@include transition(color, 0.2s, ease-in-out);
		&:hover, &:focus {
    	color: $light-blue;
    }
    &.current {
      color: $light-blue;
    }
	}
	.login {
		 margin-left: auto;
	}
}


/* MOBILE FLY OUT MENU HOLLA ! */
/* Navigation Button */
a.nav-expander {
	background: none repeat scroll 0 0 $primary-blue;
	color: #FFFFFF;
	display: block;
	font-size: 20px;
	font-weight: 400;
	height: 65px;
	margin: 0;
	padding: 1em 1em 2em 2em;
	position: absolute;
	right: 0;
	text-decoration: none;
	text-transform: uppercase;
	top: 0;
	transition: right 0.3s ease-in-out 0s;
	width: 80px;
	z-index: 12;
	transition: right 0.3s ease-in-out 0s;
	-webkit-transition: right 0.3s ease-in-out 0s;
	-moz-transition: right 0.3s ease-in-out 0s;
	-o-transition: right 0.3s ease-in-out 0s;
}

a.nav-expander:hover {
  cursor: pointer;
}

a.nav-expander.fixed {
  position: fixed;
}

.nav-expanded a.nav-expander.fixed {
    right: 15em;
}

/*Off Canvas Menu Styling */
.mobile-nav {
  background: #2d2f33;
  display: block;
  height: 100%;
  overflow: auto;
  position: fixed;
  right: -20em;
  font-size: 15px;
  top: 0;
  width: 20em;
  z-index: 2000;

  transition: right 0.3s ease-in-out 0s;
  -webkit-transition: right 0.3s ease-in-out 0s;
  -moz-transition: right 0.3s ease-in-out 0s;
  -o-transition: right 0.3s ease-in-out 0s;
  .navbar-brand {
	height: 28px;
	background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-gray.png") no-repeat;
	width: 160px;
	margin: 20px;
	&:hover {
		background: url("https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white.png") no-repeat;
		}
	}
}
.nav-expanded .mobile-nav {
  right: 0;
}

body.nav-expanded {
  margin-left: 0em;
  transition: right 0.4s ease-in-out 0s;
  -webkit-transition: right 0.4s ease-in-out 0s;
  -moz-transition: right 0.4s ease-in-out 0s;
  -o-transition: right 0.4s ease-in-out 0s;
}

#nav-close {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding-right: 10px;
  .glyphicon {
	opacity: 1;
	margin: 0 0.5em 0 0;
	&:hover {
		margin: 0 0.5em 0 0;
	}
  }
}



/* Mobile Menu and animations on hover */
.main-menu {
  padding-top: 12px;
}

.main-menu li {
  border-bottom: 1px solid #323949;
  margin-left: 20px;
  font-size: 18px;
  padding: 12px;
}

.main-menu li a {
  color: #6F7D8C;
  text-decoration: none;
}

.main-menu li a:hover {
  color: #fff;
  text-decoration: none;
}

.main-menu li .sub-nav {
  border-bottom: 0px;
  padding: 4px;
  font-size: 14px;
  margin-left: 10px;
}

.main-menu a .caret {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: top;
  border-top: 4px solid #4f5963;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
    margin-top: 8px;
}

.main-menu a:hover .caret {
  border-top-color: #4f5963;
}

.main-menu li.open > a > .caret {
  border-top: none;
  border-bottom: 4px solid #4f5963;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.main-menu li.open > a:hover > .caret {
  border-bottom-color: #4f5963;
}

.icon:before {
  font-family: 'FontAwesome';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  content: '\f105';
}

.main-menu li > a > span.glyphicon {
  float: right;
  margin: 0.1em 1.7em -0.1em 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

}

.main-menu li > a:hover > span.glyphicon {
  float: right;
  margin: 0.1em 0.8em -0.1em 0;
  opacity: 1;
}


.dropdown-wide{
  width:100%;
}



.dropdown-menu a,
.dropdown-menu span{
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857;
  color: #333;
  white-space: nowrap;

}

.dropdown-menu a.parent,
.dropdown-menu span.parent{
  font-weight:600;
}



