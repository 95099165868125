/* Sticky footer styles -----
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;

    .newsletter {
        background-color: $light-gray;

        .container {
            @extend %flexbox;
            @include align-items(center);
            @include flex-direction(row);
            @include justify-content(center);


            height: 60px;

            .newsletter-icon {
                width: 34px;
                height: 23px;
                margin-right: 15px;
                background: url('https://cumulus-fs.s3.amazonaws.com/images/newsletter-signup.png') no-repeat;
            }

            p {
                margin: 0 15px 0 0;
            }

            .form-group {
                margin-bottom: 0px;
                margin-right: 10px;
            }
        }

    }

    .footer-branding {
        .container {
            min-height: 100%;
            width: 100%;
            background-color: $primary-blue;
            @extend %flexbox;
            @include align-items(center);
            @include justify-content(center);
            @include flex-direction(column);

            .footer-logo {
                height: 86px;
                width: 105px;
                background: url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-footer.png') no-repeat;
                margin: 50px 0 30px 0;
            }

            .social-links {
                @extend %flexbox;
                @include flex-direction(row);

                a {
                    opacity: 0.7;
                    padding: 0;
                    @include transition(opacity, 0.2s, ease-in-out);

                    &:hover,
                    &:focus {
                        opacity: 1;
                        background-color: transparent;
                    }
                }

                li {
                    padding: 0 10px;
                }

                .facebook {
                    background: url('https://cumulus-fs.s3.amazonaws.com/images/social-facebook.png') no-repeat;
                    width: 14px;
                    height: 30px;
                }

                .twitter {
                    background: url('https://cumulus-fs.s3.amazonaws.com/images/social-twitter.png') no-repeat;
                    width: 30px;
                    height: 30px;
                }

                .mail {
                    background: url('https://cumulus-fs.s3.amazonaws.com/images/contact-mail.png') no-repeat;
                    width: 30px;
                    height: 30px;
                }
            }

            .text-links {
                padding: 25px 0 25px 0;
                @extend %flexbox;
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include justify-content(center);

                a {
                    color: $white;
                    border-radius: 25px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin: 0 5px;
                    @include transition(all, 0.2s, ease-in-out);

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        background-color: $light-blue;
                        color: $white;

                    }
                }
            }
        }
    }

    .disclaimer {
        min-height: 100%;
        @include align-items(center);
        @include justify-content(center);
        width: 100%;
        background-color: $light-blue;
        padding: 25px 20px;
        text-align: center;

        .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            width: 100%
        }

        .demo-site {
            padding-bottom: 5px;
            color: #ffffff;

            a {
                color: #ffffff;
            }

        }
    }

    .list-inline {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;

        .list-inline-item:not(:last-child) {
            margin-right: 0.5rem;
        }

        .list-inline-item {
            display: inline-block;
            color: $white;
            text-decoration: none;
            font-weight: 100;
        }

        .list-inline-item > a {
            color: #ffffff;
            ;
            text-decoration: none;
            font-weight: 100;
            font-size: 11px;

            &:hover {
                color: #eeeeee;
            }
        }
    }
}

.consent-tracking {
    background-color: #000000;
    bottom: 20px;
    left: 20px;
    color: $white;
    font-size: 1.15rem;
    position: fixed;
    width: 300px;
    z-index: 2000;
    padding: 1rem;
    display: flex !important;
    opacity: .8;

    .consent-tracking_close {
        color: $white;
        margin-left: 3rem;
    }

    .privacy-consent-title {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    .btn {
        padding: 0px;
    }
}

#onetrust-pc-sdk {
    max-width: 960px !important;
    width: 100% !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc {
    background-color: #fff;
}

.ot-sdk-container {
    .ot-sdk-four {}

    a,
    .ot-link-btn,
    .ot-always-active {
        color: $primary-blue !important;
    }
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header {
    color: #4f4f4f !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc #ot-pvcy-hdr,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tab-desc .ot-cat-header {
    color: #000 !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
    background-color: $primary-blue !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button.save-preference-btn-handler.onetrust-close-btn-handler,
#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler,
div#onetrust-banner-sdk .has-reject-all-button #onetrust-button-group #onetrust-reject-all-handler,
div#onetrust-banner-sdk .ot-sdk-container #onetrust-button-group-parent #onetrust-accept-btn-handler {
    background-color: $primary-blue !important;
    border-color: $primary-blue !important;
}

#onetrust-consent-sdk div#onetrust-pc-sdk .ot-pc-footer .ot-btn-container button#accept-recommended-btn-handler.optanon-ghost-button,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    color: $primary-blue !important;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
    border-color: $primary-blue !important;

}


.newsletter-message {
    margin: 0 auto;
    width: 100%;
    padding: 10px 0;
    color: #ffffff;

    &.success {
        background-color: #70C28D;
    }

    &.error {
        background-color: #D8A0A0;
    }
}

#newsletterform {
    display: flex;
    margin-block-end: 0;
}

.icon-cpra{
    width: 30px;
    height: 15px;
}


@include media-breakpoint-up(md) {
    .site-footer__menu {
        .collapse {
            display: block;
            height: auto !important;
            visibility: visible;
        }
    }

    .site-footer__menu-item {
        line-height: 1.25rem;
    }
}

@include media-breakpoint-down(md) {
    .site-footer__menu-header {
        cursor: pointer;
    }

    .site-footer__menu-header,
    .site-footer__menu-item {
        color: $dark-gray;
        padding: 0.25rem 0;
    }

    .consent-tracking {
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
    }
}

@include media-breakpoint-down(sm) {
    .disclaimer .justify-content-between {
        justify-content: none;

        .demo-site {
            width: 100%;
        }

        .disclaimer-spacing {
            display: none;
        }

        .disclaimer-nav {
            width: 100%;
        }
    }
}
