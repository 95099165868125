.modal-backdrop {
  background-color: #c5c5c5;
}
.modal-backdrop.in {
  opacity: 0.8;
}
.modal-content {
  background-clip: padding-box;
  background-color: #f7f7f7;
  border: 0 solid transparent;
  border-radius: 0;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  box-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}
.modal-header {
  align-items: flex-start;
  border-bottom: 0 solid #e6e6e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-align: start;
  -webkit-box-pack: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 2rem 2rem;

  .close {
    color: #000;
    float: right;
    font-size: 3rem;
    font-weight: 100;
    line-height: 1;
    margin: -1rem -1rem -1rem auto;
    opacity: 0.5;
    padding: 1rem 1rem;
    text-shadow: 0 1px 0 #fff;
  }
  .close:hover {
    opacity: 1;
  }

  button.close {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
  }
}
.modal-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 200px;
  padding: 1rem;

  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;

    .col-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 15px;
      padding-right: 0px;
      position: relative;
      width: 100%;

      .flex-column {
        -webkit-box-direction: normal !important;
        -webkit-box-orient: vertical !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
      }
      .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        .fad {
          font-family: 'Font Awesome 5 Duotone';
          font-weight: 900;
          position: relative;
        }
      }
    }
    .col-9 {
      background-color: $white;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 73%;
      flex: 0 0 73%;
      max-width: 73%;
      padding: 25px 25px 60px;
      position: relative;
      width: 100%;

      .tab-pane h4 {
        margin-top: 0 !important;
      }
      p {
        font-size: 16px;
        margin: 10px 0;
      }
    }
  }
}
.nav-pills .nav-link.active {
  background-color: $primary-blue;
  color: #fff;
  text-decoration: none;
}
.nav-pills .nav-link {
  border-radius: 0;
  color: $primary-blue;
}
.nav-link {
  display: block;
  padding: 1rem 1rem;
}
.align-items-center {
  align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.custom-control {
  min-height: 1.5rem;
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-control {
  display: block;
  min-height: 1.225rem;
  padding-left: 1.5rem;
  position: relative;
}
label {
  font-weight: 100;
}
.custom-control-input {
  opacity: 0;
  position: absolute;
}
button,
input {
  overflow: visible;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.custom-control-label {
  margin-bottom: 0;
  position: relative;
  vertical-align: top;
}
.custom-switch .custom-control-label::before {
  background: gray;
  border: none;
  pointer-events: all;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background: $green;
}

.custom-switch .custom-control-label::before {
  border-radius: 1.5rem;
  height: 1.5rem;
  left: -3.25rem;
  pointer-events: all;
  width: 2.5rem;
}
.custom-control-label::before {
  border: 2px solid $green;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.custom-control-label::before {
  background-color: transparent;
  border: #adb5bd solid 1px;
  content: '';
  display: block;
  height: 1rem;
  left: -1.5rem;
  pointer-events: none;
  position: absolute;
  top: 0.1125rem;
  width: 2.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label .inactive {
  display: none;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label .active {
  display: inline;
}

.custom-switch .custom-control-label .active {
  display: none;
}
.custom-switch .custom-control-label .inactive {
  display: inline;
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-2.25rem + 2px);
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
}
.custom-switch .custom-control-label::after {
  background-color: #fff;
  border-radius: 0.5rem;
  height: 1.1rem;
  left: calc(-3.25rem + 2px);
  top: calc(0.1125rem + 2px);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  width: 1.1rem;
}
.custom-control-label::after {
  background: no-repeat 50% / 50% 50%;
  content: '';
  display: block;
  height: 1rem;
  left: -1.5rem;
  position: absolute;
  top: 0.1125rem;
  width: 1rem;
}
.modal-footer {
  align-items: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top: 0 solid #e6e6e6;
  -webkit-box-align: center;
  -webkit-box-pack: end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem 2rem 2rem;

  button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled) {
    cursor: pointer;
  }
  .btn {
    text-transform: uppercase;
  }
  .btn-primary {
    background-color: $green;
    border-color: $green;
    color: #fff;
  }
}
.privacy-consent-title{
  display: block;
}
.flex-grow-1 {
  -ms-flex-positive: 1!important;
  flex-grow: 1!important;
}




// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
 // margin: $modal-dialog-margin;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    //@include transition($modal-transition);
    transform: translate(0, -25%);
  }
  .modal.show & {
    transform: translate(0, 0);
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
 // min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

#advisor-popup .modal-content{
  background-color: rgba(0, 0, 0, 0.5);
  max-width: 360px;
  border: 1px solid #000000;
  margin: 10% auto;

  .modal-body{
    background-color: rgba(0, 0, 0, 0.9);;
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $light-gray;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-color: rgba(0,0,0,.2);

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: 1; }
}


// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

#advisor-popup .close{
    float:none;
    font-size: 11px;
    text-shadow: 0px 0px 0px rgba(0,0,0,1);
    color: #efefef;
    font-weight:400;
}


#advisor-popup .modal-content:after {
  content:'';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -20px;
  width: 0;
  height: 0;
  border-top: solid 20px rgba(0,0,0,.9);;
  border-left: solid 20px transparent;
  border-right: solid 20px transparent;
}




