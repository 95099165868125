
/* ======================= MEDIA QUERIES ===================== */
@media  (min-width: 768px) and (max-width:991px) {
  .navbar-header {
      .nav > li > a {
        position: relative;
        display: block;
        padding: 10px 6px;
        font-size: 95%;
      }
    }
    .navbar .navbar-brand {
      margin: -5px 20px 0 0;
    }
  }
@media  (min-width: 768px) {

    div.col-sm-7.five-three {
    width: 60% !important;
    }

    div.col-sm-5.five-two {
    width: 40% !important;
    }

  #nav-expander {
    display:none;
  }
  .navbar-brand-mobile {
    display:none;
  }
  .navbar {
    border-radius: 0;
  }

  .dropdown-wide{
    width:420px;
    /***this makes it float in the middle of the page***/
    /*transform: translate(-50%);
    left: 50%;*/
  }

  #advisor-popup .modal-content{
    float: right;
    margin-right: 65px;
  }

  .text-align_right{
   text-align: right;     
  }

  .gold{
    background-image: url('https://image.s4.sfmc-content.com/lib/fe9615737265077870/m/1/19f63a72-d2f0-4bea-9a04-5a49cd637139.png'), 
    url('https://image.s4.sfmc-content.com/lib/fe9615737265077870/m/1/8f0dead1-f56b-47e8-9de1-b4910c4d4586.png');
    background-position: top left, top right;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #ffffff;
  }
}

@media  (max-width: 1100px) {
  #msform {
      width: 80%;
    }
  }

@media  (max-width: 767px) {

    .mobile-hide{
      display:none !important;
    }
    .category-menu {
      display:none;
    }
    .navbar {
      .navbar-brand {
        display:none;
      }
      .navbar-brand-mobile {
        height:28px;
        width:160px;
        background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white.png') no-repeat;
        margin: 0 0 0 20px;
        display:block;
      }
      .login {
        display:none;
      }
    }
    .navbar-inverse {
      background-color:$primary-blue;
      opacity:1;
      .navbar-left {
        display:none;
      }
    }
    .moveOver {
      margin-left:0px;
    }
    #msform {
      width: 95%;
    }
    #msform input[type="text"], #msform input[type="password"], #msform select  {

      width: 100%;
      margin-left: auto;
      margin-right: auto;

    }
    #progressbar {
      margin-bottom: 25px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      /*CSS counters to number the steps*/
      counter-reset: step;
      -webkit-padding-start: 0px;
      width: 90%;
    }
    #progressbar li {
      list-style-type: none;
      color: $dark-gray;
      font-size: 9px;
      width: 25%;
      float: left;
      position: relative;
    }

    .rewards{
      .col-md-4, .col-md-8{
        width: 100%;
      }
    }
    .profile-points__regular {
			padding: 0px 0px 0px 15px !important;
      .titles{
        .col{
          width: 100%;
          flex-basis: 100% !important;
        }
      }
    }
    .gold{
			background-image: url('https://cumulus-fs.s3.amazonaws.com/images/CumulusWebsite/Loyalty/loyaltyBG_bottom_gold.png');
			background-position: bottom;
			background-size: 100%;
			background-repeat: no-repeat;
			background-color: #ffffff;
      padding-bottom: 50px;
		}
}


@media  (max-width: 767px),(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
    .navbar {
      .navbar-brand-mobile {
        background:url('https://cumulus-fs.s3.amazonaws.com/images/cumulus-logo-white@2x.png') no-repeat;
        background-size: 160px 28px;
      }
    }
}

@media (max-width: 980px) {
  .banking-categories, .overview-products{
    padding: 50px 0 0 0;
    .col-sm-4, .col-sm-6, .col-md-4{
      margin-bottom:45px;
    }
  	p {
  		min-height:0;
  	}

  }

  .col-md-6, .col-md-4, .col-md-3{
    width: 100%;
  }

  .trending-topics {
    padding: 50px 0 25px 0;
    .col-md-4 {
      margin-bottom:45px;
    }
    p {
      min-height: 0;
    }
  }

  .inside-intro-content {
    p {
      min-height: 0;
    }
  }

  .inside-intro-content, .overview-products {
    .col-md-4, .col-md-3, .col-md-6, .p-bottom-50 {
      margin:0 auto 45px;
    }
    h4 {
      min-height: 0;
    }
  }

  .jumbotron-content {
    margin-top:95px;
    h1 {
      font-size:27pt;
    }
  }

  .app-btns {
    .col-md-4 {
      padding-top:15px;
    }
  }

  .footer  {
    .newsletter {
      .container {
        //@extend %flexbox;
        @include align-items(center);
        @include flex-direction(row);
        @include justify-content(center);
        @include flex-wrap(wrap);
        height: auto;
        padding: 25px;
        .newsletter-icon {
          display:none;
        }
        p {
          margin-bottom:20px;
          text-align: center;
        }
      }
    }
  }


}


@media (max-width: 620px) {

    /* PI Content Recs */
    .igo_boxbody {
        overflow: hidden;
        padding: 15px;
        text-align: center;
        .igo_content {
      float:none;
      width:100%;
        img {
          width:75%;
        }
      }
      .igo_content_content_title_label,
      .igo_content_summary_label {
        display:none;
      }
      .igo_content_content_title_value {
        display:block;
        font-size: 18px;
          font-weight: 600;
          padding:15px 0 5px 0;
      }
    }

  }
