.company {
	background:$extra-light-gray;
}
.company {
	.body-content {
		padding-bottom:75px;
	}
}

#map-canvas {
	width: 100%;
	height: 600px;
	margin-top:25px;
}
.privacy-form{
	margin: 10px 0;
}
.privacy-button{
	margin: 20px 0;
}
.privacy-form-title{
	margin-bottom: 20px;
}