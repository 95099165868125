.accounts {
	padding-bottom: 36px;
	.card{
			&.checking-acct {
				.card-header{
					background-color: #DAF0FB;
					color: $primary-blue ;
						
					a{
						color: $primary-blue;
						padding: 0 20px;
					}
				}
				
			}
			&.savings-acct{
				.card-header{
					background-color: #E5F7EF;
					color: $green ;
					img{
						width: 51px;
					}
					a{
						color: $green;
					}
				}
			}
			.card-body{
				display: flex;
				padding: 20px;
				h3{
					margin-top: 8px;
				}
				.visa,.mc{
					img{
						width: 60px;
					}
				}
			}
	}
	 .banking, .credit {
		background-color: $secondary-blue;
		color:$white;
	}
	.side-info {
		background-color: $light-gray;
	}
	table {
			tr{
				th {
					padding: 8px 8px 8px 20px;
				  	vertical-align: top;
				  	height: 40px;
					}
					td.table-no-border{
						border-top: none;
						padding:0px 15px 20px;
						font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
					}

					td.table-no-border-middle{
						border-top: none;
						padding:0px 15px;
						font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
						height: 30px;
					}

					.small-account-text{
						font-size: 10px;
					}

				td {
					padding: 8px 8px 8px 20px;
					line-height: 1.42857;
					vertical-align: top;
					border-top: 1px solid #dddddd;
					height: 50px;
				}
			}
		}
}


.account-subnav {
	background:$extra-light-gray;
	position:relative;
	&:after {
		background: linear-gradient(-45deg, $white 10px, transparent 0), linear-gradient(45deg, $white 10px, transparent 0);
        background-position: left-bottom;
        background-repeat: repeat-x;
        background-size: 20px 20px;
        content: " ";
        display: block;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 20px;
	}
	.container{
		padding: 20px 0 30px;
	}
	.nav-item{
		background: $white;
		border: 1px solid $light-gray;
		padding: 20px 35px;
		display: block;
		border-radius: 10px;
		color: $primary-blue;
		text-align: center;
		margin: 10px 18px;
		text-transform: uppercase;
		&.active{
			color: $green;
			font-weight: 800;
		}
		&:hover{
			color: $light-blue;
		}
	}
}

.rewards{
	.activity{
		width: 100%;
		max-width: 100%;
		margin-top: 20px;
		margin-bottom: 96px;
		thead>tr>th{
			vertical-align:middle;
			color: $med-gray;
			padding: 16px 30px;
			font-weight: 400;
		}
		tbody>tr{
			border-bottom: 1px solid $extra-light-gray;
			&:nth-child(odd){
				background-color: $extra-light-gray;
			}
			td{
				padding: 30px;
				font-size: 16px;
				&:last-child(){
					color: $green;
					font-weight: bold;
					width: 170px;
				}
			}
		
		}
	}	
	#loyalty-cmp{
		box-shadow: 4px 0 12px 0 rgba(0,0,0,.1);
		margin:0 0 96px;
		h1{
			padding: 0;
			margin: 0;
		}
		.gold{
			h1{
				color: $gold;
				font-weight: bold;
			}
		}
		.col-md-4{
			padding-right: 0;
		}
		.col-md-8{
			//background: #EDF7FD;
			padding: 0;
		}
		.profile-points__regular {
			background: #EDF7FD;
			padding: 0 30px;
			h1{
				color: $primary-blue;
			}
			.titles{
				margin: 0;
			}
		}
	}
}

.slds-p-around_large{
	padding: 30px;
}


.tier-progress__bar {
    //position: absolute;
    width: 100%;
    //top: 50%;
    //margin-top: -4px;
    padding: 10px 0 0;
	.tier-progress__bar .slds-progress-bar {
		background: #ffffff;
	}
	.slds-progress-bar {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: block;
		width: 100%;
		height: 15px;
		background: var(--slds-g-color-neutral-base-80, var(--lwc-progressBarColorBackground,rgb(201, 201, 201)));
		border: 0;
		position: relative;
		border-radius: 25px;
	}
	.slds-progress-bar__value {
		display: inline-block;
		background: $primary-blue;
		border-radius: 25px;
		height: 100%;
	}
	.dot{
		display: inline-block;
		width: 20px;
		height: 20px;
		background: $white;
		border: 5px solid $primary-blue;
		border-radius: 25px;
		position: absolute;
		top: -2px;
		margin-left: -9px;
	}
	.slds-assistive-text{
		display: none;
	}
}

.tier-progress__steps{
	margin: 0;

	.points{
	padding: 8px;
	&:nth-last-of-type(){
		text-align: right;
	}
	}
}
