.application-template {
	background:$extra-light-gray;
}

.application-wrapper {
	margin: 75px 0 50px 0;
	padding: 0 30px;
	background:$white;
}

.application-content {
	padding:30px;
		h2, p.complete {
			padding:15px 0;
			text-align:center;
		}
}

.gray-background-app {
	background:$extra-light-gray;
	padding:18px;
	height:70px;
}

.white-background-app {
	background:$white;
	padding:18px;
	height:70px;
}

.app-btns {
	padding:35px 18px;
}

.control-label {
	text-align:right;
}

.form-app {
	display: flex;
	align-items: center;
	flex-direction: row;
		label {
			margin-bottom: 0px;
		}
}