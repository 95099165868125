/* This is the homepage*/
.homepage-corporate-jumbotron {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/banners/hero-01.jpg");
  height: 500px;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
  margin-top: -65px;
}
.homepage-retail-jumbotron {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/banners/hero-02.jpg");
  height: 500px;
  background-size: cover;
  background-position: center;
  margin-bottom: 0px;
  margin-top: -65px;
}

.intro-content {
  background: $extra-light-gray;
  text-align: center;
  padding: 70px 0 75px 0;
  width: 100%;
  margin: 0;
  h3 {
    margin-bottom: 20px;
  }
}
.banking-categories {
  text-align: center;
  padding: 50px 0;
  background: url("https://cumulus-fs.s3.amazonaws.com/images/bg-diamond.png");
  background-size: 154px 64px;
  p {
    min-height: 80px;
  }
}
.trending-topics {
  background: $extra-light-gray;
  padding: 50px 0 75px 0;
  h2 {
    text-align: center;
    margin-bottom: 35px;
  }
  p {
    min-height: 65px;
  }
}

/* PI Content Recs */
.hero-inner {
  overflow: visible;
  padding: 15px;
  text-align: center;
}

/* Offerings Backgrounds */
.offerings {
  width: 85px;
  height: 85px;
  margin: 0 auto 15px auto;
}
.checking {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-checking.png");
}
.savings {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-savings.png");
}
.credit-cards {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-credit-cards.png");
}
.loans {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-loans.png");
}
.retirement {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-retirement.png");
}
.insurance {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-insurance.png");
}
.business-funding {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-business-funding.png");
}
.industry-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-industry-solutions.png");
}
.cash-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-cash-management.png");
}
.growth-management {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-growth-management.png");
}
.global-solutions {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-global-solutions.png");
}
.research {
  background: url("https://cumulus-fs.s3.amazonaws.com/images/offerings-research.png");
}
